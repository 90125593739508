import { useQuery } from "react-query";
import { AUTH_USER, GUEST_USER } from "../constants";
import { customerBusinessService } from "../services";

const getRewards = async (customer_id: number,location_id:number, my_rewards: number, past_rewards: number, discount_applied: number) => {
    const payload : any = { customer_id }
    if( my_rewards ) payload.my_rewards = my_rewards
    if( past_rewards ) payload.past_rewards = past_rewards
    if( discount_applied ) payload.discount_applied = discount_applied
    if(location_id) payload.location_id=location_id
    const response = await customerBusinessService.getMyRewards(payload)
    return response.data.data;
};

export default function useGetRewards(customer_id: number,location_id:number, my_rewards: number, past_rewards: number, discount_applied: number, userType: string = AUTH_USER) {

    return useQuery<any>(
        ["myRewards", customer_id],
        () => getRewards(customer_id,location_id, my_rewards, past_rewards, discount_applied),
        {
            retry: false,
            enabled: userType !== GUEST_USER && !!customer_id
        }
    );
}
