import { useQuery } from "react-query";
import { AUTH_USER, GUEST_USER } from "../constants";
import { shouldRelease } from "../helpers/helperMethods";
import { customerBusinessService } from "../services";

const getCustomerRewards = async (authentication_token: string, id: string) => {
    const response = await customerBusinessService.getCustomerRewards(id)
    return response.data.data;
};

export default function useCustomerRewardPoint(authentication_token: string, id: string, type: string = AUTH_USER) {

    return useQuery<any>(
        ["customerReward", authentication_token],
        () => getCustomerRewards(authentication_token, id),
        {
            enabled: !!id && type !== GUEST_USER && shouldRelease("Features","redeem"),
          }
    );
}
