import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styleClasses from "../../Header/Header.module.scss";
import QuantityCounter from "./QuantityCounter";
import ActionButtons from "./ActionButtons";
import ItemPrice from "./ItemPrice";
import { IOfferItem, quantityCounterOperations } from "../../../models/cart.model";
import { FULL_SIZE, ROUTE_ORDER, GUEST_USER } from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../redux/store/store";
import { useAuthState } from "../../../context/UserAuthentication";
import { cartItem } from "../../../models/cart.model";
import { setShowCart } from "../../../redux/slices/cartSlice";
import ItemRemoveModal from "./itemRemoveModal";
import ItemModifiers from "./ItemModifiers";
import { substitutedAddedModifiers } from "../../../helpers/helperMethods";
import { getSizeName, getSizeNameInCaseOfHalf } from "../../utils";
import useRedeemOffer from "../../../hooks/useRedeemOffer";
interface ICartItemCard {
  item: cartItem;
  selectedOfferItem: IOfferItem;
  rewardsDiscountedSubTotal: number;
  handleQuantityChange: (item_id: string | number, iteratingIndex: number,  itemQuantity: number) => void
  iteratingIndex: number;
  isActive: boolean;
  redeemable: any
  setIsRedeeming:Dispatch<SetStateAction<boolean>>
  isRedeeming:boolean
  isAutoRedeeming: boolean;
  loading: boolean;
  handleItemRemove: (item_id: string | number, iteratingIndex: number,closeModal: Function) => void;
  redeemForPoints: () => any;
  redeemfromMyRewards: () => any;
  togglingPoints: any;
  togglingReward: any;
}

const CartItemCard: React.FC<ICartItemCard> = ({
  item,
  selectedOfferItem,
  rewardsDiscountedSubTotal,
  redeemable,
  handleItemRemove,
  loading,
  handleQuantityChange,
  iteratingIndex,
  isRedeeming,
  isAutoRedeeming,
  redeemForPoints,
  redeemfromMyRewards, 
  togglingPoints, 
  togglingReward,
}) => {
  const history = useHistory();
  const [removeModal, setRemoveModal] = useState(false);
  const dispatch = useAppDispatch();
  const { item_size, name,id, item_id, category_id, free, quantity, index, recipient_name } = item;
  const { authInfo } = useAuthState();
  const { tempLoyalityPoints, loyalityPoints }=useAppSelector(state=>state.Items.item)
  const isAutoRedeem = useAppSelector(state=> state.cart.auto_redeem_rewards)
  const [itemQuantity, setItemQuantity] = useState<number>(quantity ?? 1);
  const handleQuantityChangeWrapper = async (operation: quantityCounterOperations) => {
    if (isAutoRedeeming) return;
    let quantity = itemQuantity
    if (operation === quantityCounterOperations.increase) {
      quantity = itemQuantity + 1
    } else {
      quantity = itemQuantity > 1 ? itemQuantity - 1 : 1;
    }
    setItemQuantity(quantity);
    handleQuantityChange(id, iteratingIndex, quantity)
  };

  const { reVerifyOfferEligibility } = useRedeemOffer();
  useEffect(() => {
    reVerifyOfferEligibility(selectedOfferItem, item, rewardsDiscountedSubTotal);
  }, [rewardsDiscountedSubTotal])

  useEffect(()=>{
    if(quantity ) setItemQuantity(quantity)
  },[quantity])

  const handleItemEdit = () => {
    history.push({
      pathname: `${ROUTE_ORDER}/${item_id}`,
      search: `?category_id=${category_id}&id=${id}`,
      state: {
        showCustomization: true, category_id,
        reward_size: item?.reward_redeemed ? item.item_size : null,
        isItemRedeemed: item?.reward_redeemed || item?.redemption_by_points
      },
    });
    dispatch(setShowCart(false));
  };

  const discountedPrice = () => {
    return parseFloat(((item.gross_price * itemQuantity) - (item.discount_amount || item.discount || item.base_price)).toFixed(2))
  };

  const displayPrice = () => {
    const price = item.gross_price ?? item.display_price;
    return parseFloat((price * itemQuantity).toFixed(2))
  };

  const showItemRemoveModal = () => {
    setRemoveModal(true);
  };
  
  const hideItemRemoveModal = () => {
    setRemoveModal(false);
  };

  const unavailableModifiers : Array<string> = []
  
  item?.modifiers?.filter((e: any) => {
    if(item?.unavailable_modifiers?.includes(e?.modifier_name)){
      unavailableModifiers.push(e.modifier_name)
    }
  });

  const unavailableModifiersExist = unavailableModifiers?.length > 0 
  const showSize = item_size && !item.single_size 
  const size : string = item_size === FULL_SIZE.id ? getSizeName(item?.category_name).upper_case :  getSizeNameInCaseOfHalf(item?.category_name,item_size)

  return (
    <>
      <li className="text-center mb-3">
        <div className={`${styleClasses.list_item} text-center mb-0 w-100 ${(!item.available || unavailableModifiersExist) && styleClasses.error_state}`}>
          <h1 className="d-flex justify-content-between font-Cls f-s16 ls-normal lh-normal m-0 text-start align-items-start">
            <span>{name}
              <span className="d-block f-s14 font-Visby-cf text-start mt-2">{showSize && size}</span>
              
            </span>
            <ActionButtons onEdit={handleItemEdit} onRemove={showItemRemoveModal} isFree={item.free} notEditable={item.cart_directly || !item.available} />
            <ItemRemoveModal 
                item={item} 
                showModal={removeModal} 
                handleClose={hideItemRemoveModal}
                removeItem={()=>handleItemRemove(id,iteratingIndex,hideItemRemoveModal )} 
                loading={loading}
                userId={authInfo?.userId}
                />
          </h1>
          <ItemModifiers
            modifiers={substitutedAddedModifiers(item)}
            paragraphClassName={"f-s14 text-start mb-0"}
          />
          {authInfo.type !== GUEST_USER ? (
            <>
              {item.reward_id ? (
                <button
                  disabled={itemQuantity > 1}
                  type="button"
                  className={`btn btn-large  ${
                    !item.reward_redeemed
                      ? "outline"
                      : `${styleClasses.loyalty_reward_btn}  primary`
                  } my-3 active`}
                  onClick={() => {
                    if (!isAutoRedeem) redeemfromMyRewards();
                  }}
                >
                  {isRedeeming && !item.reward_redeemed
                    ? "Please wait"
                    : item.reward_redeemed
                    ? "Reward Redeemed"
                    : "Redeem Reward"}
                </button>
              ) : (
                redeemable && redeemable.redeem_points < loyalityPoints && (
                  <button
                    disabled={
                      itemQuantity > 1 ||
                      (tempLoyalityPoints < redeemable?.redeem_points &&
                        !redeemable?.redemption_by_points)
                    }
                    type="button"
                    className={`btn btn-large my-3 ${styleClasses.radeemPointsBtn} ${
                      !item.redemption_by_points ? "outline" : "primary"
                    }`}
                    onClick={() => redeemForPoints()}
                  >
                    {isRedeeming && !item.free
                      ? "Please wait"
                      : item.redemption_by_points
                      ? `Redeemed For ${redeemable.redeem_points} Points`
                      : `Redeem For ${redeemable.redeem_points} Points`}
                  </button>
                )
              )}
            </>
          ) : null}
          <span className="d-block f-s14 font-Visby-cf f-w6 mt-1 text-start text-capitalize">{recipient_name}</span>
          <span className={styleClasses.card_increments_wrapper + " d-flex justify-content-between mt-1"}>
            <QuantityCounter quantity={itemQuantity} editQuantity={handleQuantityChangeWrapper}
                             disabled={!item.available || unavailableModifiersExist || !!item.redemption_by_points || !!item.reward_redeemed || togglingReward || togglingPoints}/>
            <ItemPrice price={displayPrice()} isRedeemed={!!item.reward_redeemed ||!!item.redemption_by_points } discountPrice={discountedPrice()}/>
          </span>
        </div>
        {
          !item.available ? (
            <span className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer" onClick={showItemRemoveModal}>
              This item is no longer available. Please remove from your order.
            </span>
          ) :
          unavailableModifiersExist && (
            <span className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer" onClick={showItemRemoveModal}>
              Unfortunately, we are currently out of {unavailableModifiers?.join(', ')} Today.
            </span>
          )
        }
      </li>
    </>
  );
};

export default CartItemCard;
