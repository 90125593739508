import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useAuthState } from "../../context/UserAuthentication";
import styleClasses from "../../containers/account/accounts.module.scss";
import { Field, Formik } from "formik";
import { FormField } from "../../components/FormFields/FormField";
import { IPersonalInfoFormFields } from "../../models/forms.model";
import { personalInfoSchema } from "../../validationSchemas/personalInfoSchema";
import { Toast_Func } from "../../helpers/toast.helper";
import useUpdateProfile from "../../react-query-hooks/useUpdateProfile";
import { useQueryClient } from "react-query";
import { API_ERROR_MESSAGE } from "../../models/error.model";
import useMarketLocations from "../../react-query-hooks/useLocations";
import MaskedInput from 'react-text-mask'
import { CURRENT_USER } from "../../constants";
import { getUser } from "../../helpers/helperMethods";
import Button from "../Buttons/Button";
import InputMask from 'react-input-mask';
import SmsCheckbox from "../Forms/SmsCheckbox";
import { brazeLogEventSubscribedSMSMarketing } from "../../helpers/brazeHelper";

interface IPersonalInfoTabProps {
  eventKey: string;
  showEditInfoModal: () => void;
  data: any
}
export const PHONE_NUMBER_MASK = ['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const PersonalInfoTab: React.FunctionComponent<
  IPersonalInfoTabProps
> = ({ data }) => {

  // const [value, setValue] = useState('');
  // const [isValid, setIsValid] = useState(false);

  const { authInfo } = useAuthState();
  const { mutateAsync } = useUpdateProfile();
  const queryClient = useQueryClient();
  const { data: markets } = useMarketLocations();
  const locations = markets?.map((market) => ({ value: market.name, label: market.name, id: market.id }));
  const [isMessageSubscription, setIsMessageSubscription] = useState<boolean>(true)

  const initialFormState: IPersonalInfoFormFields = {
    first_name: data ? data.first_name : "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    phone: data?.phone || "",
    birthday: data?.birthday?.replace("-", "/") || "",
    favorite_location: data?.locations?.map((loc) => {
      return {
        label: loc.name,
        value: loc.name,
        id: loc.id,
      };
    }),
  };
  const currentUserInfo = getUser();
  const isBirthdaySelected: boolean = !!data?.birthday
  const handleFormSubmission = async (
    values: IPersonalInfoFormFields,
    { setSubmitting }: any
  ) => {
    const fvrtLocations = values.favorite_location?.map((loc) => loc.id);
    const finalValues:any = {
      id: authInfo.userId,
      ...(values.first_name != initialFormState.first_name && {
        first_name: values.first_name,
      }),
      ...(values.last_name != initialFormState.last_name && {
        last_name: values.last_name,
      }),
      ...(values.email != initialFormState.email && { email: values.email }),
      ...(values.phone != initialFormState.phone && { phone: values.phone }),
      ...(values.birthday != initialFormState.birthday && {
        birthday: values.birthday?.replace('/', '-'),
      }),
      ...(values.favorite_location != initialFormState.favorite_location && {
        location: fvrtLocations ?? [],
      }),
      subscribe_to_sms_marketing: isMessageSubscription,
    };
    finalValues.first_name = finalValues?.first_name?.trim();
    finalValues.last_name = finalValues?.last_name?.trim();
    setSubmitting(true);
    return mutateAsync(
      {
        editedProfile: finalValues,
        authentication_token: authInfo.authentication_token,
      },
      {
        onSuccess: (data: any) => {
          queryClient.invalidateQueries("profile");
          Toast_Func({
            status: true,
            message: "Profile successfully updated.",
          });
          if(isMessageSubscription && !currentUserInfo?.subscribe_to_sms_marketing) {
            brazeLogEventSubscribedSMSMarketing(data);
          }
          localStorage.setItem(
            CURRENT_USER,
            JSON.stringify({ ...currentUserInfo, ...finalValues, full_name: `${data?.first_name} ${data?.last_name}`, first_name: `${data?.first_name}`, last_name: `${data?.last_name}` })
          );
        },
        onError: (error, variables, context) => {
          const {
            data: {
              message,
            },
          } = (error as API_ERROR_MESSAGE).response;
          Toast_Func({ status: false, message });
        },
      }
    );
  };
  return (
    <div className={`${styleClasses.user_info_wrap} text-start w-100`}>
      <div className="mb-0 ">
        <h4 className="font-Cls f-sm-s22 f-s24 text-start h-24 medium_text mb-4">
          Profile & Preferences
        </h4>
      </div>
      <div className="new_form_design gap-40">
        <Formik
          initialValues={initialFormState}
          validationSchema={personalInfoSchema(isMessageSubscription)}
          onSubmit={handleFormSubmission}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            submitForm,
          }) => (
            <form className="new_form_design profile-info-read-only-field" onSubmit={handleSubmit}>
              <Row>
                <Col md={6} sm={12}>
                  <FormField
                    labelText={"FIRST NAME"}
                    placeholder={"First Name"}
                    name="first_name"
                    value={values.first_name}
                    type="text"
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    readonly={isSubmitting}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <FormField
                    labelText={"LAST NAME"}
                    placeholder={"Last Name"}
                    name="last_name"
                    value={values.last_name}
                    type="text"
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    readonly={isSubmitting}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <FormField
                    labelText={"EMAIL ADDRESS"}
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={null}
                    errors={errors}
                    touched={touched}
                    readonly
                  />
                </Col>
                <Col sm={12} md={6} className="mb-3 mb-md-0">
                  <label htmlFor="phone">
                    Phone Number
                  </label>
                  <Field
                    name="phone"
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={PHONE_NUMBER_MASK}
                        id="phone"
                        placeholder="(223) 123-1234"
                        type="text"
                        value={values.phone.replace(/[() -]/g, '')}
                        onChange={(e) => setFieldValue('phone', e.target.value.replace(/[() -]/g, ''))}
                        //setFieldValue('phone',value.replace(/[() -]/g,''))}
                        guide={true}
                        className={`
                          ${errors.phone && touched.phone
                            ? "input_error"
                            : "text-input"}
                            form-control`}
                      />
                    )}
                  />

                  {errors.phone && touched.phone && (
                    <div className="input-feedback clr-dark-red f-s14 font-rale mt-1 d-inline-block">{errors.phone}</div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <div className="form-group">
                    <label className="d-flex justify-content-between">
                      Birthday(MM/DD)
                      {!isBirthdaySelected && <span className="f-s14 text-transform-none font-rale f-w5 ls-normal">
                        (Optional)
                      </span>}
                    </label>
                    <Field name="birthday"
                    readOnly
                      render={({ field }) => (
                        <InputMask mask="99/99" maskPlaceholder="MM/DD"
                          id="birthday"
                          {...field}
                          value={values.birthday}
                          readOnly={isBirthdaySelected ? true: false}
                        >
                          {({ inputRef, ...rest }) => <input {...rest} ref={inputRef} className={` ${errors.birthday && touched.birthday && !isBirthdaySelected
                            ? "input_error"
                            : "text-input"} form-control`} placeholder="MM/DD" 
                            />}
                        </InputMask>
                      )}>

                    </Field>

                    {errors.birthday && touched.birthday && !isBirthdaySelected &&  <div className="input-feedback clr-dark-red f-s14 font-rale mt-1 d-inline-block">
                      {errors.birthday}
                    </div>}
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="form-group custom_select">
                    <FormField
                      options={locations}
                      labelText={"favorite locations"}
                      name="favorite_location"
                      type={"multiselect"}
                      placeholder={"Make a selection"}
                      errors={errors}
                      touched={touched}
                      isSearchable={true}
                      value={values.favorite_location}
                      onChange={(location) => {
                        setFieldValue("favorite_location", location);
                      }}
                      inputFieldClass={"custom_select mb-0"}
                    />
                  </div>
                </Col>
              </Row>
              <SmsCheckbox setIsMessageSubscription={setIsMessageSubscription}/>

              <hr className="custom_hr_sty mb-4 mt-5 d-md-none" />
              <div className="d-flex justify-content-center mt-4">
                <Button
                  type="button"
                  className="btn btn-large py-2 f-s16"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  SAVE PROFILE UPDATE
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <hr className="custom_hr_sty mb-4 mt-5 d-none d-md-block" />
    </div>)
};

export default PersonalInfoTab;