import React from "react";
import {
    ROUTE_HOME,
    ROUTE_OUR_KITCHEN,
    ROUTE_CATERING_REQUEST_FORM,
    ROUTE_CATERING,
    ROUTE_FUNDRAISING,
    ROUTE_ORDER_ONLINE,
    ROUTE_LOCATIONS,
    ROUTE_CAREERS,
    ROUTE_TALK_TO_US,
    ROUTE_MENU,
    TERMS_ROUTE_HTML,
    TERMS_ROUTE,
    PRIVACY_POLICY_ROUTE,
    ROUTE_MENU_CATERING,
    CATERIND_REWARD,
    ROUTE_ORDER_LANDING,
    ROUTE_LOGIN,
    ROUTE_CREATE_ACCOUNT,
    ROUTE_FAQ,
    ROUTE_DELIVERY_ORDER_FORM,
    ROUTE_PICKUP_ORDER_FORM, ROUTE_FORGOT_PASSWORD,
} from "../../constants";
/**
 * TODO: Add meta tags for each page for SEO optimization
 * META_TAGS["KEY NAME"] KEY NAME should match the exact pathname
 */
export let META_TAGS: { [key: string]: JSX.Element[] } = {
    "/menu/Sandwiches": [<title>Sandwiches & Paninis | Café Zupas</title>,
                            <meta name="keywords" content="gourmet sandwich"></meta>],
    "/menu/Bowls": [<title>Protein Bowls & Quinoa Bowls [Keto Friendly] | Café Zupas</title>,
                        <meta name="keywords" content="protein bowls"></meta>],
    "/menu/Kid's+Combo": [<title>Kid's Combo Meal | Café Zupas</title>,
                        <meta name="keywords" content="cafe zupas kids menu"></meta>],
    "/menu/Seasonal": [<title>Featured Seasonal Dishes: Flavors For A Limited Time | Café Zupas</title>,
                        <meta name="keywords" content="seasonal menu"></meta>],
    "/menu/Soups": [<title>Soups | Café Zupas</title>,
                        <meta name="keywords" content="zupas soup"></meta>],
    "/menu/Salads": [<title>Salads: Chef-Crafted Fresh Salads - Takeout & Delivery | Café Zupas</title>,
                        <meta name="keywords" content="fresh salads"></meta>],
    "/menu/Desserts+&+Sides": [<title>Desserts & Treats | Café Zupas</title>,
                        <meta name="keywords" content="dessert cafe"></meta>],
    "/nutrition":[<title>Café Zupas Nutrition Facts 2019 | Café Zupas</title>],
};

META_TAGS[ROUTE_OUR_KITCHEN] = [<title>About Us & Our Kitchen: House-Made, Quality Ingredients | Café Zupas</title>,
                                <meta name="keywords" content="cafe ingredients"></meta>];
META_TAGS[ROUTE_MENU] = [<title>Menu — Salads, Soups, Sandwiches, Protein Bowls — Café Zupas</title>,
                            <meta name="keywords" content="cafe zupas menu"></meta>];
META_TAGS[ROUTE_LOCATIONS] = [<title>Café Zupas Near Me</title>,
                                <meta name="keywords" content="zupas near me"></meta>];
META_TAGS[ROUTE_ORDER_ONLINE] = [<title>Order Online — Café Zupas</title>,
                                    <meta name="keywords" content="zupas order online"></meta>];
META_TAGS[ROUTE_ORDER_LANDING] = [<title>Start Your Online Order | Café Zupas</title>,
                                    <meta name="keywords" content="zupas order online"></meta>];
META_TAGS[ROUTE_CAREERS] = [<title>Café Zupas Careers & Open Positions: Come Work With Us | Café Zupas</title>,
                                <meta name="keywords" content="zupas hiring"></meta>];
META_TAGS[ROUTE_MENU_CATERING] = [<title>Catering Menu: Boxed Lunches & Party Salads | Café Zupas</title>,
                                    <meta name="keywords" content="zupas catering menu"></meta>];
META_TAGS[ROUTE_CATERING] = [<title>Catering & Catering Delivery | Café Zupas</title>,
                                <meta name="keywords" content="zupas catering"></meta>];
META_TAGS[ROUTE_HOME] = [<title>Café Zupas - Soup, Salad and Sandwich Kitchen & Catering</title>,
                            <meta name="keywords" content="soup and salad"></meta>];
META_TAGS[ROUTE_FUNDRAISING] = [<title>Fundraiser & Events | Café Zupas</title>,
                                    <meta name="keywords" content="fundraiser events"></meta>];
META_TAGS[ROUTE_TALK_TO_US] = [<title>Contact Us | Café Zupas</title>];
META_TAGS[ROUTE_CATERING_REQUEST_FORM] = [<title>Catering: Contact Us | Café Zupas</title>,
    <meta name="keywords" content="Café Zupas Catering Contact Us"></meta>];
META_TAGS[TERMS_ROUTE_HTML] = [<title>Terms & Conditions — Café Zupas</title>,
    <meta name="keywords" content="Café Zupas Terms & Conditions"></meta>];
META_TAGS[TERMS_ROUTE] = [<title>Terms & Conditions — Café Zupas</title>,
    <meta name="keywords" content="Café Zupas Terms & Conditions"></meta>];
META_TAGS[PRIVACY_POLICY_ROUTE] = [<title>Privacy Policy | Café Zupas</title>,
    <meta name="keywords" content="Café Zupas Privacy Policy"></meta>];
META_TAGS[ROUTE_LOGIN] = [<title>Login & Account Sign-Up | Café Zupas</title>,
    <meta name="keywords" content="Café Zupas Login & Account Sign-Up"></meta>];
META_TAGS[ROUTE_CREATE_ACCOUNT] = [<title>Create A New Account | Café Zupas</title>,
    <meta name="keywords" content="Café Zupas Create A New Account"></meta>]
META_TAGS[ROUTE_FAQ] = [<title>Café Zupas Loyalty Rewards | Café Zupas</title>,
    <meta name="keywords" content="Café Zupas Loyalty Rewards"></meta>]
META_TAGS[ROUTE_DELIVERY_ORDER_FORM] = [<title>Start Your Online Delivery Order | Café Zupas</title>,
    <meta name="keywords" content="Café Zupas Delivery Order"></meta>]
META_TAGS[ROUTE_PICKUP_ORDER_FORM] = [<title>Start An Online Pick-Up Order | Café Zupas</title>,
    <meta name="keywords" content=" Café Zupas Pick-Up Order"></meta>]
META_TAGS[ROUTE_FORGOT_PASSWORD] = [<title>Login Forgot Password | Café Zupas</title>,
    <meta name="keywords" content=" Café Zupas Forgot Password"></meta>]
