import React, { useEffect, useState } from "react";
import styleClasses from "../../Header/Header.module.scss";
import {setLocation, useStoreLocation} from "../../../context/StoreLocation";
import {
  DELIVERY_FEE,
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  OFFER_TYPE_FIXED_DISCOUNT,
  PICK_UP_ORDER_TYPE,
  SERVICE_FEE
} from "../../../constants";
import ItemPickupDeliveryModal from "../../ItemGrid/ItemPickupDeliveryModal";
import GuestCheckoutModal from "../GuestCheckoutModal/GuestCheckoutModal";
import {persistZero, getUser, isCartItemsUnavailable, isCartModifiersUnavailable, roundNumber, roundNumberHalfUpStrategy} from "../../../helpers/helperMethods";
import {roundToTwo, toFixedNumber} from "../../../priceCalculation/helper";
import {Modal} from "react-bootstrap";
import OrderType from "../../ItemGrid/OrderType";
import { locationMenuService} from "../../../services";
import { useAppSelector } from "../../../redux/store/store";
import useGetProfileStatus from "../../../react-query-hooks/useGetProfileStatus";
import GuestLoginModal from "../GuestCheckoutModal/GuestCheckout/GuestLoginModal";
import { useDispatch } from "react-redux";
import { updateRewardDiscountedSubTotal } from "../../../redux/slices/cartSlice";

/*
 * TODO: use context to get list of items added in cart and calculate the bill
 */
interface IBillingProps {
  StyleBtn?: any,
  CartBtnCheckout?: any,
  TotalList?: any,
  StyleCheckout?: any,
  handleShowPickupMethodModal?: boolean,
  handleClosePickupMethodModal?: boolean,
  handleShowDeliveryMethodModal?: boolean,
  handleCloseDeliveryMethodModal?: boolean ,
  CheckoutBtnHide?: any,
  OrderTotal?: any,
  listspace?: any,
  isOrderTypeSelected?: boolean;
  orderType?: string;
  cartItems: any;
  isOrdered?: boolean;
  titleStyle?: string;
  authInfo?: any;
  orderTippingAmount?: number;
  isCheckoutBilling?: boolean;
  order?: any;
  tax?: number;
  titlespace?:string;
  setTotal?: (number) => void;
  setRewardsDiscountedSubTotal?: (value: number) => void // Used for offer redemption calculation
  setDiscountedSubTotal?: (value: number) => void; 
  handleTax?:boolean
  offerDiscount?:number;
  disableCheckout?:boolean;
  offerDiscountType?: string;
}

const Billing = (props: IBillingProps) => {
  const {
    StyleBtn, 
    CartBtnCheckout,
    TotalList,
    StyleCheckout , 
    handleShowPickupMethodModal,
    handleShowDeliveryMethodModal ,
    CheckoutBtnHide,
    OrderTotal,
    listspace,
    isOrderTypeSelected,
    orderType,
    cartItems = [],
    isOrdered,
    titleStyle,
    authInfo,
    orderTippingAmount,
    isCheckoutBilling,
    tax,
    order,
    titlespace,
    setTotal,
    setRewardsDiscountedSubTotal,
    setDiscountedSubTotal,
    offerDiscount,
    disableCheckout,
    handleTax = true,
    offerDiscountType
  } = props;
  const [currentUser , setCurrentUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  })
  const [openPickupMethodModal, setOpenPickupMethodModal] = useState(false);
  const [openDeliveryMethodModal, setOpenDeliveryMethodModal] = useState(false);
  const [ handleChangeOrderType ,  setHandleChangeOrderType] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [changeOrderType, setChangeOrderType] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const handleShowContactForm = () => setShowContactForm(true);
  const handleCloseContactForm = () => setShowContactForm(false);
  const { locationInfo } = useStoreLocation();
  const [displaySubTotal, setDisplaySubTotal] = useState<number>(0);
  const [displayDiscount, setDisplayDiscount] = useState<number>(0);
  const [displayTotal, setDisplayTotal] = useState<number>(0);
  const CartBilling = useAppSelector((state) => state.cart);
  let authUser = getUser();
  const dispatch = useDispatch();
  const {data: userProfileData} = useGetProfileStatus(authUser?.id, authUser?.type)
  const savedOrderType = orderType
  let cart = cartItems
  useEffect(() => {
    calculateSubTotal();
  }, [cart, offerDiscount]);

  useEffect(() => {
    if (handleShowPickupMethodModal) { setOpenPickupMethodModal(true) }
    if (handleShowDeliveryMethodModal) { setOpenDeliveryMethodModal(true)}
  }, [ 
    handleShowPickupMethodModal, 
    handleShowDeliveryMethodModal, 

  ]);
  function calculateTax(): number {
    if(handleTax){
      return CartBilling?.tax
    }else{
      return 0
    }
  }

  const calculateOfferDiscount = (rewardDiscountTotal: number, totalAmount: number) => {
    if (!offerDiscount) return 0;
    if(offerDiscountType === OFFER_TYPE_FIXED_DISCOUNT)
    {
      return offerDiscount
    }
    else {
      const discount = (totalAmount - rewardDiscountTotal) * offerDiscount / 100;
      return roundNumberHalfUpStrategy(discount, 2);
    }
  }

  function calculateSubTotal() {
    if (cart.length > 0) {
      let displayTotal= 0;
      let rewardDiscountTotal = 0
      cart.forEach((item: any) => {
        const price = item.gross_price ?? item.display_price
        displayTotal += toFixedNumber(price * item.quantity)
        if (item?.reward_redeemed || item.redemption_by_points) {
          rewardDiscountTotal +=  (item.discount_amount || item.discount || item.base_price)
        }
      });
      setDisplaySubTotal(displayTotal)
      const discountByOffer = calculateOfferDiscount(rewardDiscountTotal, displayTotal)
      const overAllDiscount = rewardDiscountTotal + discountByOffer;
      setRewardsDiscountedSubTotal?.(toFixedNumber(displayTotal - rewardDiscountTotal));
      dispatch(updateRewardDiscountedSubTotal(toFixedNumber(displayTotal - rewardDiscountTotal)));
      setDisplaySubTotal(displayTotal)
      if (overAllDiscount > displayTotal) {
        setDiscountedSubTotal?.(0);
        setDisplayDiscount(toFixedNumber(displayTotal)); 
      } else {
        setDisplayDiscount(toFixedNumber(overAllDiscount))
        setDiscountedSubTotal?.(toFixedNumber(displayTotal - overAllDiscount));
      }
    } else {
      setDisplaySubTotal(0)
      setDisplayDiscount(0)
    }
  }

  useEffect(() => {
    calculateTotal();
  },[CartBilling?.tax])

  const calculateDiscountedTotal = () => {
    let grandTotal = orderTippingAmount ? displaySubTotal - displayDiscount + orderTippingAmount : displaySubTotal - displayDiscount;
    if(savedOrderType === DELIVERY_ORDER_TYPE && cart?.length){
      grandTotal = grandTotal + DELIVERY_FEE + SERVICE_FEE
    }
    return grandTotal >= 0 ? grandTotal : 0
  }

  function calculateTotal(): number {
    const total = locationInfo?.selectedStore?.tax_rate
    ? calculateDiscountedTotal() + calculateTax()
    : calculateDiscountedTotal();
    setTotal?.(Number(roundNumber(total,2)))
    return total
  }
  
   const handleClosePickupMethodModal = () => { setOpenPickupMethodModal(false) }
   const handleCloseDeliveryMethodModal =  () => { 
    setOpenDeliveryMethodModal(false)
    setOpenPickupMethodModal(false)
  }

  useEffect(()=>{
    let authUser = getUser()
    if( authUser.isInfo ){
       setCurrentUser({ ...authUser})
      //  setShowContactInfo(true)
    }
  } , [showContactForm])

  const onCheckout = async () => {
    //check if user is guest
    if( authUser.type === GUEST_USER || userProfileData?.update_required){
        if(authUser.isInfo || userProfileData?.update_required)  setShowContactInfo(true)
        else setShowContactInfo(false)
      handleShowContactForm()
    } else {
      showOrderTypeModal()
    }
  }

  const showOrderTypeModal = () => {
    // setHandleChangeOrderType(true)
    if(isOrderTypeSelected && savedOrderType == PICK_UP_ORDER_TYPE){
      setOpenPickupMethodModal(true)
      setOpenDeliveryMethodModal(false)
    }else{
      setOpenDeliveryMethodModal(true);
      setOpenPickupMethodModal(false)
    }
  }

  const closeOrderTypeModal = () => {
    setChangeOrderType(false)
    showOrderTypeModal()
  }

  const closeChangeOrderTypeModal = () => {
    setChangeOrderType(false)
  }

  const showChangeOrderType = (changeType) => {
    setChangeOrderType(changeType)
  }

  const totalForPlacedOrder = () => {
    let grandTotal = orderTippingAmount ? order?.total_price + orderTippingAmount : order?.total_price;
    return roundToTwo(Number(grandTotal))
  }

  const taxShow = () => {
    if(handleTax){
      return         <li>
      Tax
      <span className="d-inline-block ms-3">{isOrdered ? `$${roundNumber(tax,2)}` : `\$${toFixedNumber(CartBilling?.tax)}`}</span>
    </li>
    }else return null
  }

  return (
    <>
    <div className={`${StyleCheckout}`}>
     <h4 className={`pt-3 text-start h-22 ls-normal lh-normal font-Cls fw-normal f-sm-s16 f-s20 px-4 mb-0 ${titleStyle} ${titlespace}`}>
        Order Total
      </h4>
      <ul className={`${styleClasses.total_count_list} ${listspace} ${TotalList} list-inline`}>
        <li>
          Subtotal
          <span className="d-inline-block ms-3">{isOrdered ? `$${order?.gross_sales?.toFixed(2)}` :`\$${persistZero(toFixedNumber(displaySubTotal))}`}</span>
        </li>
              {order?.discount || displayDiscount > 0 ?
                  <li className={`text-danger`}>
                    Discount
                    <span className="d-inline-block ms-3">{`\$${ order?.discount || displayDiscount }`}</span>
                  </li>
                  :
                  null
              }
              {taxShow()}
        {orderTippingAmount ? <li>
          Tip
          <span className="d-inline-block ms-3">${`${roundNumber(orderTippingAmount,2)}`}</span>
        </li> : null}
        {orderType == DELIVERY_ORDER_TYPE && cart?.length && !isOrdered ?
            <>
              <li>
                Delivery Fee
                <span className="d-inline-block ms-3">${DELIVERY_FEE}</span>
              </li>
              <li>
                Service Fee
                <span className="d-inline-block ms-3">${SERVICE_FEE}</span>
              </li>
            </>
            : null}
        {orderType == DELIVERY_ORDER_TYPE && isOrdered ?
            <>
            { order?.delivery_fee ? <li>Delivery Fee <span className={`d-inline-block ms-3`}>${order?.delivery_fee?.toFixed(2)}</span> </li> : null}
            { order?.service_fee ? <li>Service Fee <span className={`d-inline-block ms-3`}>${order?.service_fee?.toFixed(2)}</span> </li> : null}
            </>
            : null}
        <li>
          Total
          {isOrdered
            ?<a className="d-inline-block ms-3 text-decoration-none clr-dark-grey" href="javascript:void(0);">{isOrdered ? `$${persistZero(totalForPlacedOrder())}` : `$${persistZero(roundNumber(calculateTotal(),2))}`}</a>
            :<span className="d-inline-block ms-3">{isOrdered ? `$${persistZero(totalForPlacedOrder())}` : `$${persistZero(roundNumber(calculateTotal(),2))}`}</span>}
        </li>
      </ul>
      {
          !isCheckoutBilling && !isOrdered ? 
      <div className={`${styleClasses.checkout_btn_wrap} ${CartBtnCheckout}  ${StyleBtn}`}>
       
          <button
          type="button"
          className={`btn btn-large w-100 text-uppercase mb-3 d-flex justify-content-between `}
          disabled={cart.length <= 0 || isCartItemsUnavailable(cart) || isCartModifiersUnavailable(cart) || disableCheckout}
          onClick={onCheckout}
          id={'checkout-button'}
        >
          Checkout
            <span className="d-inline-block ms-3">${persistZero(roundNumber(calculateTotal(),2))}</span>
        </button> 
      
      </div>: null
        }
     </div>
     <ItemPickupDeliveryModal 
        subTotal={toFixedNumber(displaySubTotal)}
        tax={toFixedNumber(calculateTax())}
        total={toFixedNumber(calculateTotal())}
        openPickupMethodModal={openPickupMethodModal} 
        handleClosePickupMethodModal={handleClosePickupMethodModal}
        editPickupType={showChangeOrderType}
        editDeliveryType={showChangeOrderType}
        handleCloseDeliveryMethodModal={handleCloseDeliveryMethodModal}
        openDeliveryMethodModal={openDeliveryMethodModal}  
        handleChangeOrderType={handleChangeOrderType} 
        isCartBilling={true}
        locationId={locationInfo?.selectedStore?.id}
        cartItems={cartItems}
        userId={authInfo?.userId}
      />
     {/* <GuestCheckoutModal 
        showContactForm={showContactForm}
        handleCloseContactForm={handleCloseContactForm}
        currentGuestUserInfo = {currentUser}
        showContactInfo={showContactInfo}
        setShowContactInfo={setShowContactInfo}
        handleSubmitAsGuest={showOrderTypeModal}
        userStatusData={userProfileData}
     /> */}
     <GuestLoginModal 
        showContactForm={showContactForm}
        handleCloseContactForm={handleCloseContactForm}
        subTotal={displaySubTotal}
     />
      <Modal
          show={changeOrderType}
          centered
          className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
          onHide={closeChangeOrderTypeModal}
          size="sm"
      >
        <Modal.Header className="x-4 justify-content-center">
          <button
              type="button"
              className="btn modal-close-button pe-0 sm-blue-icon"
              onClick={closeChangeOrderTypeModal}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body>
          <OrderType
              handleShowDeliveryMethodModal={closeOrderTypeModal}
              handleShowPickupMethodModal={closeOrderTypeModal}
              handleOrderTypeModal={closeChangeOrderTypeModal}
              changeToDelivery={orderType == PICK_UP_ORDER_TYPE}
              changeToPickup={orderType == DELIVERY_ORDER_TYPE}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Billing;