import { is } from 'date-fns/locale';
import * as Constants from '../constants';
import { itemIngredientSelectionHelper } from '../redux/utility';
import {
  ADDITIONALPRICE,
  ADDITIONAL_ADDED,
  ADDITIONAL_REMOVED,
  BY_DEFAULT_ADDED,
  COMPLIMENTARY_MODIFIERS,
  ORIGINALPRICE,
  BY_DEFAULT_ADDED_V2,
  REQUIRED_MODIFIERS,
  CORE_RELATED,
} from './constants';
import {
  addAdditionalPriceBasedOnActionType,
  addComplementaryPrice,
  basePrice,
  createBucketsObjects,
  findModifierIndexBasedOnId,
  getSelectedBucketModifiers,
  isExistAsByDefault,
  isModifierGroupExistInMemoryChip,
  lastIndexOf,
  resetAdditionalPrice,
  temporarilyAddedPrice,
  toFixedNumber,
  updatingItemBucketModifiers,
  updatingItemBucketTypeSelectedModifiers,
} from './helper';
import { AddAddiotionalPriceToCoreAndItsRelated } from './priceAdjustments';
import { IAddAddiotionalPriceToComplementaryModifier, IAddAddiotionalPriceToModifier, IAddAddiotionalPriceToRequiredModifier, IAddAdditionalPriceForComplementaryItem, IAddAdditionalPriceForRequiredBucketFunction, IAddExisitngItemToBucket, IBucket, ICreateBucket, ICurrentModifier, IGetSingleBucket, IgetSingleBucketKeyValue, IGetSingleItem, IRemoveModifierAndAddAdditionalPrice, IUpdateSpecificBucketPrices, IUpdateSpecificItemBucket, IUpdateSpecificItemBucketModifiers } from './priceCalculation';
import _clonedeep from 'lodash.clonedeep'

export const itemBuckets = (function () {
  // private members
  let itemBucket: any = [];
  let copyOfItemBucket = [];

  // public members
  function createBucket({keys, items}: ICreateBucket) {
    if (itemBucket.length > 0) {
      resetBuckets();
    }
    for (let index = 0; index < items; index++) {
      itemBucket.push({
        item: index + 1,
        bucket: createBucketsObjects(keys),
      });
    }
    addComplementaryItem(items + 1);
  }

  function addExisitngItemToBucket({items}: IAddExisitngItemToBucket) {
    itemBucket[0] = _clonedeep(items[0])
  }

  function addComplementaryItem(item) {
    itemBucket.push({
      item,
      bucket: createBucketsObjects([COMPLIMENTARY_MODIFIERS]),
    });
  }

  function getBuckets() {
    return itemBucket;
  }

  function getSingleItem({itemType,itemNo, isItemNoComingAsAnIndex=false} : IGetSingleItem) {
    const indexOfItem = isItemNoComingAsAnIndex ? itemNo : itemNo - 1;
    return itemBucket[indexOfItem];
  }

  function getSingleBucket({name, fromItem, isFromItemComingAsAnIndex = false}: IGetSingleBucket ) {
    const indexOfBucket = isFromItemComingAsAnIndex ? fromItem : fromItem - 1;
    return itemBucket[indexOfBucket]?.bucket[name];
  }

  function getSingleBucketKeyValue({name, fromItem, modifierType, key}: IgetSingleBucketKeyValue) {
    return itemBucket[fromItem]?.bucket[name][modifierType][key];
  }

  function updateItemBucket(modifier: ICurrentModifier, fromItem: number) {
    const itemIndex = fromItem - 1;
    switch (modifier.type) {
      case Constants.INCREASE:
        updatingItemBucketModifiers(modifier,itemIndex,ADDITIONAL_ADDED);
        break;
      case Constants.DECREASE:
        updatingItemBucketModifiers(modifier,itemIndex,ADDITIONAL_REMOVED);
        break;
      case Constants.SELECTED:
        // skip complementary for now. [becasuse *selected* never come with complementary]
        updatingItemBucketTypeSelectedModifiers(modifier,itemIndex)
        break;
      default:
        updatingItemBucketModifiers(modifier,itemIndex,BY_DEFAULT_ADDED);
    }
  }

  function selectedModifierType(modifier_name, modifier, fromItem) {
    const selectedModifierBucket = getSingleBucket({name:modifier_name, fromItem, isFromItemComingAsAnIndex:true});
    const existingEntryIndex = selectedModifierBucket?.modifiers.findIndex(
      (mod) => mod.modifier_id === modifier?.modifier_id && mod.modifier_group_id === modifier?.modifier_group_id
    );
    if (existingEntryIndex === -1) {
      return Constants.INCREASE;
    } else {
      return Constants.DECREASE;
    }
  }

  function AddAddiotionalPriceToModifier(
    {name, modifier, fromItem, action} : IAddAddiotionalPriceToModifier
  ) {
    if (modifier?.treat_as === CORE_RELATED) {
      name = CORE_RELATED;
    }
    switch (name) {
      case COMPLIMENTARY_MODIFIERS:
        AddAddiotionalPriceToComplementaryModifier({
          modifier_type: COMPLIMENTARY_MODIFIERS,
          modifier,
          fromItem,
          action,
        });
        break;
      case REQUIRED_MODIFIERS:
        AddAddiotionalPriceToRequiredModifier({modifier_type: REQUIRED_MODIFIERS, fromItem});
        break;
      case Constants.ADD_ONS:
        AddAddiotionalPriceToRequiredModifier({modifier_type: Constants.ADD_ONS, fromItem}
        );
        break;
      case Constants.CORE_MODIFIERS:
        AddAddiotionalPriceToCoreAndItsRelated(
          Constants.CORE_MODIFIERS,
          modifier,
          fromItem,
          action
        );
        break;
      case Constants.CORE_RELATED:
        AddAddiotionalPriceToCoreAndItsRelated(
          Constants.CORE_MODIFIERS,
          modifier,
          fromItem,
          action
        );
        break;
      default:
    }
  }

  function handleSubstitutionBeforeUpdatingItemBucket(name:string,fromItem:number,modifier:ICurrentModifier,as:string){
    let previousPrice = 0;
    const selectedModifiersBucket = itemBucket[fromItem].bucket[name];
    const selectedModifiers = selectedModifiersBucket?.modifiers;
    const index = findModifierIndexBasedOnId(selectedModifiers, modifier);
    let substitute_with_id;
    if(index >= 0){
      selectedModifiers.splice(index,1);
    }
    const sameGroupModIndex = selectedModifiers.findIndex((mod:ICurrentModifier) => mod.modifier_group_id === modifier.modifier_group_id)
    const defaultModifiers = selectedModifiersBucket[BY_DEFAULT_ADDED].modifiers
    const defaultModifier = defaultModifiers.find((mod:ICurrentModifier) => mod.modifier_group_id === modifier.modifier_group_id)
    if(sameGroupModIndex >= 0){
      if( defaultModifier && defaultModifier.modifier_id ) substitute_with_id = defaultModifier.modifier_id;
      previousPrice = itemBucket[fromItem].bucket[name]?.modifiers[sameGroupModIndex].display_price;
      itemBucket[fromItem].bucket[name]?.modifiers.splice(sameGroupModIndex,1);
    }
    const sameGroupAdditionalModIndex = itemBucket[fromItem].bucket[name][as]?.modifiers.findIndex((mod:ICurrentModifier) => mod.modifier_group_id === modifier.modifier_group_id)
    if(sameGroupAdditionalModIndex >= 0){
      itemBucket[fromItem].bucket[name][as]?.modifiers.splice(sameGroupAdditionalModIndex,1);
    }
    if(as === ADDITIONAL_ADDED){
      modifier.basePrice = basePrice(modifier ,selectedModifiersBucket[BY_DEFAULT_ADDED_V2].modifiers);
      modifier.previousPrice = previousPrice;
      if(substitute_with_id && substitute_with_id !== modifier.modifier_id ) modifier.substitute_with_id = substitute_with_id
      itemBucket[fromItem].bucket[name]?.modifiers.push(modifier)
    }
  }

  function updateSpecificItemBucket({name, modifier, fromItem, as}: IUpdateSpecificItemBucket) {
    let selectedModifiers = null;
    let index = null;
    if (modifier?.extendableLimitValue === 1 && modifier?.modifier_group_min === 1) {
        handleSubstitutionBeforeUpdatingItemBucket(name, fromItem, modifier, as)
    }
    switch (as) {
        case ADDITIONAL_ADDED:
            // check if have to add to by_default
            if (modifier.quantity === 1) {
                selectedModifiers =
                    itemBucket[fromItem].bucket[name][BY_DEFAULT_ADDED]?.modifiers;
                index = lastIndexOf(selectedModifiers, modifier);
            }
            if (
                index === -1 &&
                isExistAsByDefault(
                    itemBucket[fromItem].bucket[name][BY_DEFAULT_ADDED_V2]?.modifiers,
                    modifier
                )
            ) {
                selectedModifiers = itemIngredientSelectionHelper({
                    selectedModifiers,
                    payload: modifier,
                });
                AddAddiotionalPriceToModifier(
                    {name,
                    modifier,
                    fromItem,
                    action:Constants.INCREASE}
                );
            } else {
                itemBucket[fromItem].bucket[name][as]?.modifiers.push(modifier);
                // ~ retrive a bucket old price
                const oldPrice = getSingleBucket({name, fromItem, isFromItemComingAsAnIndex:true})[ADDITIONALPRICE];
                // ! new price
                const newPrice = toFixedNumber(modifier.display_price + oldPrice);
                removeModifierAndAddAdditionalPrice({name, newPrice, fromItem, modifier, index, selectedModifiers, bucketKeyName: ADDITIONAL_REMOVED, action: Constants.INCREASE})
            }
            break;
        case ADDITIONAL_REMOVED:
            // ? check if have to remove from by_dafault
            if (modifier.quantity === 0) {
                selectedModifiers =
                    itemBucket[fromItem].bucket[name][BY_DEFAULT_ADDED]?.modifiers;
                index = lastIndexOf(selectedModifiers, modifier);
            }
            if (index !== -1 && index !== null) {
                selectedModifiers.splice(index, 1);
                AddAddiotionalPriceToModifier(
                    {name,
                    modifier,
                    fromItem,
                    action:Constants.DECREASE}
                );
            } else {
                itemBucket[fromItem].bucket[name][as]?.modifiers.push(modifier);
                // ~ retrive a bucket old price
                const oldPrice = getSingleBucket({name, fromItem, isFromItemComingAsAnIndex:true})[ADDITIONALPRICE];
                // ! new price
                const newPrice = toFixedNumber(oldPrice - modifier.display_price);
                removeModifierAndAddAdditionalPrice({name, newPrice, fromItem, modifier, index, selectedModifiers,bucketKeyName:ADDITIONAL_ADDED,action:Constants.DECREASE})
            }
            break;
        default:
            selectedModifiers = itemBucket[fromItem].bucket[name][as]?.modifiers;
            const newSelectedModifiers = itemIngredientSelectionHelper({
                selectedModifiers,
                payload: modifier,
            });
            selectedModifiers = newSelectedModifiers;
            // ? Make a copy of by_default Added Modifiers
            itemBucket[fromItem].bucket[name][BY_DEFAULT_ADDED_V2].modifiers = [
                ...newSelectedModifiers,
            ];
    }
  }

  function removeModifierAndAddAdditionalPrice({name,newPrice,fromItem,modifier,index,selectedModifiers,bucketKeyName,action}: IRemoveModifierAndAddAdditionalPrice){
    // ~ update additional price
    updateSpecificBucketPrices({bucketName:name, updatedValue:newPrice, fromItem:fromItem, modifier:{...modifier}, cardAction: action});
    // ~ remove from additional removed
    selectedModifiers = itemBucket[fromItem].bucket[name][bucketKeyName]?.modifiers;
    index = lastIndexOf(selectedModifiers, modifier);
    index !== -1 && selectedModifiers.splice(index, 1);
    AddAddiotionalPriceToModifier(
      {name,
      modifier,
      fromItem,
      action}
    );
  }

  function updateSpecificItemBucketModifiers({name, modifier, fromItem}:IUpdateSpecificItemBucketModifiers) {
    let selectedModifiers = itemBucket[fromItem].bucket[name]?.modifiers;
    selectedModifiers = itemIngredientSelectionHelper({
      selectedModifiers,
      payload: modifier,
    });
  }

  function AddAddiotionalPriceToComplementaryModifier({
    modifier_type,
    modifier,
    fromItem,
    action,
  }: IAddAddiotionalPriceToComplementaryModifier) {
    const bucket = getSingleBucket({name:modifier_type, fromItem, isFromItemComingAsAnIndex: true});
    bucket?.modifiers.map((currentModifier) => {
      if (currentModifier.modifier_id === modifier.modifier_id) {
        resetAdditionalPrice(currentModifier) &&
          (currentModifier.additionalPrice = 0);
        if (addComplementaryPrice(modifier)) {
          if (action === Constants.INCREASE) {
            currentModifier.additionalPrice = toFixedNumber(
              currentModifier.additionalPrice + modifier.display_price
            );
          }
          if (action === Constants.DECREASE) {
            currentModifier.additionalPrice = toFixedNumber(
              currentModifier.additionalPrice - modifier.display_price
            );
          }
        }
      }
    });
  }

  function removeNoDressingCard(bucket): boolean{
    let index = bucket.modifiers.findIndex((modifier:ICurrentModifier) => modifier.modifier_name.toLowerCase() === Constants.NO_DRESSING);
    return index !== -1
  }

  function trackedItems (bucket) {
    const track ={}
    // Will keep track of the total ( items added by user ) and remaining ( for detecting modifier_group_max ) 
    bucket?.modifiers.forEach(item=> { 
      item.additionalPrice = 0
      if(track[item.modifier_group_id] !== undefined){
        track[item.modifier_group_id] = {
          total: track[item.modifier_group_id].total + item.quantity,
          remaining: track[item.modifier_group_id].remaining + item.quantity,
          price: 0
        }       
      }else{
        track[item.modifier_group_id] = {
          total: item.quantity,
          remaining: item.quantity,
          price: 0
        }
      }
    })
    return track;
  }

  function AddAddiotionalPriceToRequiredModifier({modifier_type, fromItem} : IAddAddiotionalPriceToRequiredModifier ) {
      const bucket = getSingleBucket({name:modifier_type, fromItem, isFromItemComingAsAnIndex:true});
      const modifiers = bucket.modifiers
      const track = trackedItems(bucket)
      const tempAdditionalAddedModifiers = []
      // adding additional price for each item and updating modifiers as well
      bucket?.additionalAdded?.modifiers.map((i, index) => {
        const item = _clonedeep(i)
        if(!removeNoDressingCard(bucket)){
          index += 1
        }
        const modifiersIndex = modifiers.findIndex(e => e.modifier_id === item.modifier_id && e.modifier_group_id === item.modifier_group_id) 
        let quantity = track[item.modifier_group_id].total - track[item.modifier_group_id].remaining
        const previousPrice = track[item.modifier_group_id].price; 
        const groupPrice = previousPrice + item?.display_price;
        const itemPrice = item?.display_price;
        const basePrice = item?.modifier_group_base;
        if(quantity < item.modifier_group_max ){
          // FOR Group Price 
          if(groupPrice <= basePrice){
            item.additionalPrice = 0
          }
          else if ( groupPrice > basePrice && previousPrice <= basePrice){
            const priceDifference = groupPrice - basePrice
            item.additionalPrice = priceDifference;
            modifiers[modifiersIndex].additionalPrice = modifiers[modifiersIndex].additionalPrice ? modifiers[modifiersIndex].additionalPrice + priceDifference : priceDifference
          }
          else if ( groupPrice > basePrice && previousPrice >= basePrice){
            item.additionalPrice = itemPrice
            modifiers[modifiersIndex].additionalPrice = modifiers[modifiersIndex].additionalPrice ? modifiers[modifiersIndex].additionalPrice + itemPrice :itemPrice
          }
          track[item.modifier_group_id].remaining = track[item.modifier_group_id].remaining - 1
          track[item.modifier_group_id].price = groupPrice
        }
        else{
          item.additionalPrice = item?.display_price
          track[item.modifier_group_id].remaining = track[item.modifier_group_id].remaining - 1
          modifiers[modifiersIndex].additionalPrice += item?.display_price
          track[item.modifier_group_id].price = item?.display_price
        }
        tempAdditionalAddedModifiers.push(item)
      })
      bucket.additionalAdded.modifiers = tempAdditionalAddedModifiers;
  }

  function updateSpecificItemBucketKey(
    bucketName,
    keyName,
    updatedValue,
    fromItem
  ) {
    itemBucket[fromItem].bucket[bucketName][keyName] = updatedValue;
  }

  function addAdditionalPriceForComplementaryItemIfModifierIsNotByDefault(modifier, bucketName, updatedValue,fromItem){
    if(modifier.quantity > 1 ){
      updateSpecificItemBucketKey(
        bucketName,
        ADDITIONALPRICE,
        updatedValue,
        fromItem
      );
    }
    if(modifier.quantity >= 1 && modifier.type === Constants.DECREASE){
      updateSpecificItemBucketKey(
        bucketName,
        ADDITIONALPRICE,
        updatedValue,
        fromItem
      );
    }
  }

  function addAdditionalPriceForComplementaryItem({ modifier,bucketName,updatedValue,fromItem,byDefaultAddedModifiers}: IAddAdditionalPriceForComplementaryItem){
    let modifierMax = modifier.modifier_group_max - byDefaultAddedModifiers.length;
    const isModifierAddedAsByDefault = byDefaultAddedModifiers.find(mod => mod.modifier_id === modifier.modifier_id);
    if(isModifierAddedAsByDefault){
      modifierMax = modifier.modifier_group_max - modifierMax;  
    }else{
      modifierMax = modifier.modifier_group_max;  
    }
    if (modifier.quantity >= 1 && isModifierAddedAsByDefault) {
      updateSpecificItemBucketKey(
        bucketName,
        ADDITIONALPRICE,
        updatedValue,
        fromItem
      );
    }else{
      addAdditionalPriceForComplementaryItemIfModifierIsNotByDefault(modifier, bucketName,updatedValue,fromItem);
    }
  }

  function addAdditionalPriceForRequiredBucket({
    modifier,
    currentGroupSelectedModifiers,
    otherGroupSelectedModifier,
    bucketName,
    fromItem,
  }: IAddAdditionalPriceForRequiredBucketFunction) {
    const copyOfCurrentGroupSelectedModifiers = _clonedeep(currentGroupSelectedModifiers);
    const lastIndex = copyOfCurrentGroupSelectedModifiers.map((currentModifier: ICurrentModifier) => currentModifier.modifier_id === modifier.modifier_id).lastIndexOf(true);
    
    if (modifier?.type === Constants.DECREASE && lastIndex !== -1) {
      copyOfCurrentGroupSelectedModifiers.splice(lastIndex, 1);
    }
    
    let modifiersPrice = 0;
    let basePrice = null;
    
    copyOfCurrentGroupSelectedModifiers.forEach((modifier, index:number) => {
      if (basePrice === null) {
        basePrice = modifier.modifier_group_base;
      }
      
      if (index + 1 <= modifier.modifier_group_max && basePrice > 0) {
        const remainBasePrice = toFixedNumber(modifier.display_price - basePrice);
        basePrice = remainBasePrice >= 0 ? 0 : toFixedNumber(basePrice - modifier.display_price);
        modifiersPrice += remainBasePrice >= 0 ? remainBasePrice : 0;
      } else {
        modifiersPrice += modifier.display_price;
      }
    });
    
    const otherGroupConsumed = otherGroupSelectedModifier.reduce((accumulator, { additionalPrice }) => {
      return accumulator + (additionalPrice ? toFixedNumber(additionalPrice) : 0);
    }, 0);
    
    updateSpecificItemBucketKey(bucketName, ADDITIONALPRICE, modifier?.type === Constants.INCREASE ? modifiersPrice + otherGroupConsumed : otherGroupConsumed + modifiersPrice, fromItem);
  }
  

function updateSpecificBucketPrices({bucketName, updatedValue, fromItem, modifier, cardAction}: IUpdateSpecificBucketPrices) {
  if (modifier && modifier.type === Constants.SELECTED) {
    modifier.type = cardAction;
  }

  if (bucketName === Constants.CORE_MODIFIERS) {
    return;
  }

  const bucketWithOldPrice = getSingleBucket({name:bucketName, fromItem, isFromItemComingAsAnIndex:true});
  const byDefaultAddedModifiers = bucketWithOldPrice?.byDefaultAddedV2?.modifiers || [];
  const currentSelectedModifier = [...bucketWithOldPrice[ADDITIONAL_ADDED].modifiers, ...byDefaultAddedModifiers];
  const currentGroupSelectedModifiers = currentSelectedModifier.filter(mod => mod.modifier_group_id === modifier.modifier_group_id);
  const otherGroupSelectedModifier = currentSelectedModifier.filter(mod => mod.modifier_group_id !== modifier.modifier_group_id);
  let currentSelectedModifierLimit = currentGroupSelectedModifiers.length;

  if (bucketName === Constants.COMPLIMENTARY_MODIFIER) {
    addAdditionalPriceForComplementaryItem({modifier, bucketName, updatedValue, fromItem, byDefaultAddedModifiers});
  } else if (bucketName === REQUIRED_MODIFIERS && removeNoDressingCard(bucketWithOldPrice)) {
    currentSelectedModifierLimit -= 1;
  } else if (bucketName === REQUIRED_MODIFIERS || bucketName === Constants.ADD_ONS) {
    addAdditionalPriceForRequiredBucket({modifier,currentGroupSelectedModifiers,otherGroupSelectedModifier,bucketName,fromItem});
  } else if (currentSelectedModifierLimit > modifier.modifier_group_max) {
    updateSpecificItemBucketKey(bucketName, ADDITIONALPRICE, updatedValue, fromItem);
  }
}
  
  function specificItemBucketSelectedModifiers(name, fromItem) {
    return itemBucket[fromItem - 1]?.bucket[name]?.modifiers;
  }

  function addCoreModifierMemoryChip(fromItem, bucket, modifierGroups = []) {
    itemBucket[fromItem - 1].items_modifier_groups = modifierGroups
    itemBucket[fromItem - 1].bucket[Constants.CORE_MODIFIERS].memoryChip = _clonedeep(bucket);
  }

  function createBucketCopy() {
    copyOfItemBucket = _clonedeep(itemBucket);
  }

  function discardBucketChanges(item) {
    const oldBucket = _clonedeep(copyOfItemBucket);
    let newBucket = _clonedeep(itemBucket);
    if (copyOfItemBucket.length === 0) {
      itemBucket[parseInt(item) - 1].bucket = freshBornBucket(
        itemBucket[parseInt(item) - 1].bucket
      );
    } else {
      itemBucket = oldBucket;
    }
    const price = temporarilyAddedPrice(oldBucket, newBucket, item);
    resetCopyComboBucket();
    return price;
  }

  function resetCopyComboBucket() {
    copyOfItemBucket = [];
    consoleBuckets()
  }

  function resetBuckets() {
    itemBucket = [];
    copyOfItemBucket = [];
  }

  function resetSpecificComboBucket(itemNo) {
    const item = itemBucket[itemNo - 1];
    const newBucket = freshBornBucket(item?.bucket);
    item.bucket = newBucket;
    consoleBuckets();
  }

  function addNoDressingCard(itemNo:number,freshBornRequiredBucket:IBucket){
    const bucket = itemBucket[itemNo - 1].bucket[REQUIRED_MODIFIERS];
    const modifiers = getSelectedBucketModifiers(bucket);
    const noDressingModifier = bucket?.noDressingModifiers;
    if(noDressingModifier){
      for (let i = 0; i < modifiers.length; i++) {
        const modifier = modifiers[i]
        const index = findModifierIndexBasedOnId(noDressingModifier, modifier);
        if (index !== -1) {
            freshBornRequiredBucket[REQUIRED_MODIFIERS].noDressingModifiers = noDressingModifier;
        } else {
            noDressingModifier.push(modifier);
            freshBornRequiredBucket[REQUIRED_MODIFIERS].noDressingModifiers = noDressingModifier;
        }
      }
    }else{
      freshBornRequiredBucket[REQUIRED_MODIFIERS].noDressingModifiers = modifiers;
    }
  }

  function resetChangesOnNoDreesing(itemNo:number, modifier:any) {
    let requiredItemBucket = getSingleBucket({name:REQUIRED_MODIFIERS, fromItem: itemNo});
    let additionalPrice = 0
    const removedModifiersArray = requiredItemBucket.noDressingModifiers || []
    const modifiersArray = []
    const additionalModifierArray= []
    const noDressings = requiredItemBucket?.noDressings || []
    requiredItemBucket.modifiers.forEach(item=>{
      if(item.modifier_group_id === modifier.modifier_group_id){
        additionalPrice += item.additionalPrice
        removedModifiersArray.push(item)
      }else{
        modifiersArray.push(item)
      }
    })

    requiredItemBucket?.additionalAdded.modifiers.forEach(item=>{
      if(item.modifier_group_id !== modifier?.modifier_group_id){
        additionalModifierArray.push(item)
      }
    })

    requiredItemBucket.modifiers = modifiersArray
    requiredItemBucket.additionalAdded.modifiers = additionalModifierArray
      
    const setNoDressing = {
      id: modifier?.modifier_group_id,
      noDressing:  modifier.quantity ? true : false
    }
    
    const ifExists = noDressings.findIndex(e => e.id === modifier?.modifier_group_id) 
    if(ifExists === -1){
      noDressings.push(setNoDressing)
    }else{
      noDressings[ifExists].noDressing = modifier.quantity ? true : false
      if(!noDressings[ifExists].noDressing && removedModifiersArray ){
        requiredItemBucket['noDressingModifiers'] = removedModifiersArray
      }
    }

    if(requiredItemBucket?.noDressings){
      requiredItemBucket.noDressings=noDressings 
    }else{
      requiredItemBucket['noDressings'] = noDressings
      requiredItemBucket['noDressingModifiers'] = removedModifiersArray
    }

    if(modifier?.quantity){
      requiredItemBucket[ORIGINALPRICE] = toFixedNumber(requiredItemBucket[ORIGINALPRICE] - additionalPrice)
      requiredItemBucket[ADDITIONALPRICE] = toFixedNumber(requiredItemBucket[ADDITIONALPRICE] - additionalPrice)
    }
    return additionalPrice
  }

  function removeModifiersfromBucket(itemNo:number) {
    let requiredItemBucket = getSingleBucket({name:REQUIRED_MODIFIERS, fromItem: itemNo});
    requiredItemBucket.modifiers = []
    requiredItemBucket.additionalAdded.modifiers = []
    requiredItemBucket.originalPrice = requiredItemBucket.originalPrice - requiredItemBucket.additionalPrice
    requiredItemBucket.additionalPrice = 0
  }

  function freshBornBucket(bucket) {
    const itemModifierTypes = Object.keys(bucket);
    return createBucketsObjects(itemModifierTypes);
  }

  function consoleBuckets() {
    // console.log(
    //   '%cOriginal ⇛ ',
    //   'color:green;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold',
    //   itemBucket
    // );
    // console.log(
    //   '%cCopy ⇛ ',
    //   'color:yellow;font-family:system-ui;font-size:2rem;-webkit-text-stroke: 1px black;font-weight:bold',
    //   copyOfItemBucket
    // );
  }

  function updateSpecificBucketPrice(
    bucketName,
    keyName,
    updatedValue,
    fromItem
  ) {
    updateSpecificItemBucketKey(
      bucketName,
      keyName,
      updatedValue,
      fromItem - 1
    );
    const bucketWithOldPrice = {
      ...getSingleBucket({name:COMPLIMENTARY_MODIFIERS, fromItem}),
    };
    //? Save New Added Price As A Old Price
    updateSpecificItemBucketKey(
      bucketName,
      ORIGINALPRICE,
      updatedValue,
      fromItem - 1
    );
    return bucketWithOldPrice;
  }




  function removeAddedModifiersInCreateYourOwnSalad(itemNo:number) {
    let requiredComboBucket = getSingleBucket({name:REQUIRED_MODIFIERS,fromItem:itemNo} );
    const additionalPrice = requiredComboBucket.additionalPrice;
    // ? replace both required buckets with a freshborn required bucket.
    const freshBornRequiredBucket = createBucketsObjects([REQUIRED_MODIFIERS]);
    // ? Add No Dressing Card
    addNoDressingCard(itemNo,freshBornRequiredBucket)
    itemBucket[itemNo - 1].bucket[REQUIRED_MODIFIERS] = freshBornRequiredBucket[REQUIRED_MODIFIERS];
    return additionalPrice
  }

  function reintializeBuckets({bucketNames, itemNo }:{bucketNames:string[],itemNo:number}){
    let freshBornBucket = null;
    let additionalAddedPrice = 0;
    const itemIndexInBucket = itemNo - 1;
    bucketNames.map((name:string,index:number)=>{
      additionalAddedPrice += itemBucket[itemIndexInBucket].bucket[name][ADDITIONALPRICE]
      freshBornBucket = createBucketsObjects([name]);
      itemBucket[itemIndexInBucket].bucket[name]= freshBornBucket[name];
    })
    return additionalAddedPrice
  }


  return {
    removeAddedModifiersInCreateYourOwnSalad,
    createComboBucket: createBucket,
    getBuckets,
    updateItemBucket,
    updateSpecificBucketPrice,
    getSingleBucket,
    getSingleBucketKeyValue,
    specificItemBucketSelectedModifiers,
    createBucketCopy,
    discardBucketChanges,
    consoleBuckets,
    addCoreModifierMemoryChip,
    resetSpecificComboBucket,
    updateSpecificItemBucketKey,
    resetBuckets,
    resetCopyComboBucket,
    getSingleItem,
    resetChangesOnNoDreesing,
    updateSpecificItemBucket,
    updateSpecificItemBucketModifiers,
    selectedModifierType,
    addExisitngItemToBucket,
    removeModifiersfromBucket,
    reintializeBuckets
    
  };
})();
