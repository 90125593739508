import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useStoreLocation } from '../../../context/StoreLocation';
import { useAppSelector } from '../../../redux/store/store';
import styleClasses from '../../Header/Header.module.scss';
import UpsellItemCard from './UpsellItemCard';
import { FULL_SIZE, HALF_SIZE } from '../../../constants';
import Spinner from '../../Spinner/Spinner';
import styles from '../CartItems/CartItems.module.scss';
import SkeltonLoader from '../../SkeltonLoader/SkeltonLoader';
interface IUpselItemList {
  data: any;
  cart: any;
  fetchUpsell: () => void;
  isFetchingUpsell: boolean;
  syncCart: () => any;
}

const UpsellItemsLists: React.FC<IUpselItemList> = (props) => {
  const { data, cart, fetchUpsell, isFetchingUpsell, syncCart } = props;

  const [showUpsell, setShowUpsell] = useState(true);

  useEffect(() => {
    if (isFetchingUpsell) return;
    if (data?.length <= 0) {
      setShowUpsell(false);
    } else {
      setShowUpsell(true);
    }
  }, [isFetchingUpsell, data]);

  function containsObject(obj) {
    const upSellItemSize = obj?.is_half_item
      ? HALF_SIZE.upper_case
      : FULL_SIZE.upper_case;
    for (let i = 0; i < cart.length; i++) {
      if (
        cart[i]?.item_id === obj.id &&
        cart[i]?.item_size === upSellItemSize
      ) {
        return true;
      }
    }
    return false;
  }

  return (
    showUpsell && (
      <React.Fragment>
        <div className={styleClasses.other_products_wrap}>
          <h4 className="f-s22 f-sm-s16 font-Cls lh-normal ls-normal mb-3">
            You Might Also Like
          </h4>
          {isFetchingUpsell ? (
            <div className="d-flex justify-content-center">
              <SkeltonLoader />
              <SkeltonLoader />
              <SkeltonLoader />
            </div>
          ) : (
            <Row className={styleClasses.suggestion_row + ' flex-nowrap pt-1'}>
              <Scrollbars
                className={styleClasses.other_products_Scrollbar + ' mb-mb-4'}
                renderThumbHorizontal={(props) => (
                  <div {...props} className={styleClasses.Scrollbar_thumb} />
                )}
                renderView={(props) => (
                  <div {...props} className={styleClasses.Scrollbar_view} />
                )}
              >
                {data?.map((item, index: number) => (
                  <>
                    <UpsellItemCard
                      key={item.id}
                      upSellItem={item}
                      btnText={containsObject(item) ? 'Added' : 'Add'}
                      fetchUpsell={fetchUpsell}
                      syncCart={syncCart}                     
                    />
                  </>
                ))}
              </Scrollbars>
            </Row>
          )}
        </div>
        <hr className="shadow-divider mb-4" />
      </React.Fragment>
    )
  );
};

export default UpsellItemsLists;
