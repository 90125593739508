import React from "react";
import { Link, useHistory } from "react-router-dom";
import styleClasses from "./NavbarComponent.module.scss";
import { NavLink } from "react-router-dom";
import * as CONSTANT from "../../constants";
import useProfile from "../../react-query-hooks/useProfile";
import { logout, useAuthState } from "../../context/UserAuthentication";
import { useDispatch } from "react-redux";
import { resetRedeemedItemContext, resetRedeemedOrderContex } from "../../redux/slices/itemSlice";
import { useAppDispatch } from "../../redux/store/store";
import { useQueryClient } from "react-query";
import { shouldRelease } from "../../helpers/helperMethods";
import { customerBusinessService } from "../../services";
import { LoyaltyIcon, RewardFaqIcon } from "../../images/svgIcons/svg";

type IUserNavComponentProps = {
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  userId: string;
  userType: string
};

export default function UserNavComponent({ setExpanded, userId, userType }: IUserNavComponentProps) {
  let history = useHistory();
  const { data: userProfileData } = useProfile(userId, userType);
  const { dispatch } = useAuthState();
  const cartDispatch = useDispatch();
  const redeemedItemDispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const handleLogout = async () => {
    redeemedItemDispatch(resetRedeemedItemContext())
    redeemedItemDispatch(resetRedeemedOrderContex())
    await customerBusinessService.logout();
    logout(dispatch, cartDispatch);
    queryClient.invalidateQueries();
    history.push("/")
    setExpanded(false);
  }

  return (
    <div>
      <h6 className="f-s18 text-start lh-normal ls-normal font-Cls f-bold mt-5">{` Welcome, ${userProfileData?.first_name}`}</h6>
      <ul className={`${styleClasses.sub_menu_list} ${styleClasses.user_menu_listing} list-inline mb-1 ps-0`}>
        <li>
          <NavLink
            to={CONSTANT.ROUTE_PROFILE_DETAILS}
            onClick={() => setExpanded(false)}
            className={styleClasses.sub_menu_item}
          >
            <img src={require("../../images/navigation-icon-1.svg")} className="img-fluid" alt="navigation icon" /> Profile &
            Preferences
          </NavLink>
        </li>
        {shouldRelease('Features', 'redeem') &&
          <>
            <li>
              <NavLink
                to={CONSTANT.ROUTE_REWARDS_REDEEM}
                onClick={() => setExpanded(false)}
                className={styleClasses.sub_menu_item}
              >
                <span className="me-2"> <LoyaltyIcon /> </span>  Loyalty &
                Rewards
              </NavLink>
            </li>
            <li>
              <NavLink to={CONSTANT.ROUTE_FAQ} onClick={() => setExpanded(false)} className={styleClasses.sub_menu_item}>
                <span className="me-2"> <RewardFaqIcon /> </span> Rewards FAQ
              </NavLink>
            </li>
          </>
        }
      </ul>
      <button type="button" className="btn btn-large dark-grey f-s14 lh-normal mt-5 mb-4" onClick={handleLogout}>
        SIGN OUT
      </button>
      <p className="f-s14 pt-8 mb-5">
        <Link
          to={CONSTANT.TERMS_ROUTE}
          onClick={() => setExpanded(false)}
          className={`${styleClasses.login_btn} font-Vcf clr-dark-blue text-decoration-none`}
        >
          Terms and Conditions
        </Link>{" "}
        and{" "}
        <Link
          to={CONSTANT.PRIVACY_POLICY_ROUTE}
          onClick={() => setExpanded(false)}
          className={`${styleClasses.login_btn} font-Vcf clr-dark-blue text-decoration-none`}
        >
          Privacy Policy
        </Link>
      </p>
    </div>
  );
}
