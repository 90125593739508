import { useQuery } from "react-query";
import axios from "axios";
import { ORDER_MICRO_SERIVCE } from "../constants";

interface IQueryParams {
  userId: number;
}

const getCustomerPlacedOrders = async ({ userId }: IQueryParams) => {
  const response = await axios.get(
    `${ORDER_MICRO_SERIVCE}/orders/track-here/${userId}?page=1&size=25`
  );
  return response?.data?.data;
};

export default function useGetCustomerPlacedOrders({ userId }: IQueryParams) {
  return (
    useQuery(["placedOrders", userId], () =>
      getCustomerPlacedOrders({ userId }), 
      {
        enabled: !!userId,
      }
    ) 
  )
}
