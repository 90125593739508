import { Formik } from 'formik'
import React, {useEffect, useState} from 'react'
import {
    DeliveryAddressFromFields,
    ISelectedAddress,
    ISelectedVehicle,
    VehicleInfoFormFields
} from '../../../../../models/forms.model';
import { FormField } from '../../../../FormFields/FormField'
import VehicleInfoForm from '../../../../Forms/VehicleInfoForm';
import AddNewVehicle from './AddNewVehicle';
import useProfile from "../../../../../react-query-hooks/useProfile";
import {useAuthState} from "../../../../../context/UserAuthentication";
import {useAppDispatch, useAppSelector} from "../../../../../redux/store/store";
import {updateOrderDetails} from "../../../../../redux/slices/cartSlice";
import {isGuestUser, validateCount} from "../../../../../helpers/helperMethods";
import {MAX_COUNT_FOR_PROFILE_INFO} from "../../../../../constants";
import {Toast_Func} from "../../../../../helpers/toast.helper";

interface ICurbSideVehicleDetailsProps {
    isShownVehicleDetails: (isShown) => void;
    closeShowVehicleModal?:(boolean) => void;
    editGuestVehicle?: VehicleInfoFormFields;
}

const CurbSideVehicleDetails = (props: ICurbSideVehicleDetailsProps) => {
    const {
        isShownVehicleDetails ,
        closeShowVehicleModal, editGuestVehicle
    } = props
    const { authInfo } = useAuthState();
    const [userVehicles, setUserVehicles] = useState([])
    const [newAddedVehicle, setNewAddedVehicle] = useState(null)
    const [showAddNewVehicle, setShowAddNewVehicle] = useState(isGuestUser(authInfo))
    let orderDetails = useAppSelector(
        (state) => state.cart.orderDetails
    );
    const orderTypeDispatch = useAppDispatch()

    const {
        isLoading,
        isFetching,
        data: userProfileData,
        error,
    } = useProfile(authInfo.userId, authInfo.type)

    const handleNewAddedUserVehicle = (vehicle) => {
        setNewAddedVehicle(vehicle)
    }
    const handleFormSubmission = async (
        values: ISelectedVehicle
    ) => {
        const selectedVehicle = userProfileData.vehicles.find((vehicle) => vehicle.id == values.selected_vehicle.id)
        const vehicleDetails =
            {
                "make": selectedVehicle.make,
                "color": selectedVehicle.color,
                "model": selectedVehicle.model,
                "id": selectedVehicle.id,
                "isDefault": selectedVehicle.isDefault
            }
        orderDetails = {
            ...orderDetails,
            vehicleDetails: vehicleDetails
        }
        orderTypeDispatch(updateOrderDetails(orderDetails))
        closeShowVehicleModal(true)
    };

    const showAddNewVehicleDetails = (isShow) => {
        setShowAddNewVehicle(isShow);
    }

    useEffect(() => {
        if(!isGuestUser(authInfo) ) {
            if(!isFetching && userProfileData.vehicles?.length > 0) {
                const allVehicles = userProfileData.vehicles
                    .map((vehicle) => ({value: vehicle.make, label: `${vehicle.make} ${vehicle.model} ${vehicle.color}`, id: vehicle.id, isDefault: vehicle.is_default}))
                setUserVehicles(allVehicles)
            }
        }
    }, [userProfileData]);

    const initialFormState: ISelectedVehicle = {
        selected_vehicle: newAddedVehicle ||
            userVehicles.find(vehicle => vehicle.isDefault == 1) ||
            {}
    };
    return (
        <div>
            {
                !showAddNewVehicle && initialFormState.selected_vehicle.hasOwnProperty("isDefault") ?
                <>
                    <h5 className="f-s22 font-Cls text-center fw-normal pb-3 text-capitalize">
                        Vehicle Details
                    </h5>
                    <Formik
                        initialValues={initialFormState}
                        onSubmit={handleFormSubmission}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            submitForm,
                            setFieldValue
                        }) =>{
                        return(
                                <form className="new_form_design  w-75 my-0 mx-auto delivery_location_select full_width_mob" onSubmit={handleSubmit}>
                                    <FormField
                                        isSearchable={true}
                                        options={userVehicles}
                                        name="selected_vehicle"
                                        type="select"
                                        value={values?.selected_vehicle || userVehicles.find(vehicle => vehicle.isDefault == 1)}
                                        labelText={"Stored Vehicles"}
                                        placeholder={"Select a vehicle"}
                                        onChange={(vehicle) => { setFieldValue("selected_vehicle", vehicle) }}
                                        errors={errors}
                                        touched={touched}
                                        inputFieldClass={"custom_select mb-0"}
                                    />

                                    <div className="d-flex justify-start-center my-3">
                                        <button
                                            type="button"
                                            className={`btn btn-custom p-0 f-w6 p-16 color-sky-blue-light font-Vcf`}
                                            onClick={() =>
                                            {if(validateCount(userVehicles)) showAddNewVehicleDetails(true)}}
                                        >
                                            Add a New Vehicle
                                        </button>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-large py-2 f-s16 w-100 mb-5 mt-2"
                                        onClick={submitForm}
                                        disabled={isSubmitting || isFetching}
                                    >
                                        Continue
                                    </button>
                                </form>
                            )}}
                    </Formik>

                </> :
            <AddNewVehicle showAddNewVehicleDetails={showAddNewVehicleDetails} isShownVehicleDetails={isShownVehicleDetails}
                           handleNewAddedUserVehicle={handleNewAddedUserVehicle} isNewVehicle={userVehicles.length > 0}
                           closeShowVehicleModal={closeShowVehicleModal} editGuestVehicle={editGuestVehicle} />
            }
        </div>
    )
}

export default CurbSideVehicleDetails