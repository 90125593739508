import {useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { SELECTED_STORE } from "../constants";
import { setShowCart } from "../redux/slices/cartSlice";

const useDeepLinkRedirection = () => {
    const location = useLocation();
    const cartDispatch = useDispatch();
    const params = new URLSearchParams(location.search);
    const displayCart = params.get("display_cart");
    const branchReferrer = params.get("_branch_referrer");
    const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
    const [showCartModal, setShowCartModal] = useState<boolean>(false);

    const locationInfo: any = JSON.parse(localStorage.getItem(SELECTED_STORE));
    const locationId: any = locationInfo?.id;

    const displayCartOnRedirect = () => {
        let showCart = false;
        if(branchReferrer)
        {
            showCart = Boolean(displayCart && displayCart.toLowerCase().trim() === "true");
            if(locationId)
                 {
                    setShowCartModal(showCart);
                 }
            else 
                {
                    setShowLocationModal(true);
                }
        }
    }
    
    useEffect(() => {
        displayCartOnRedirect();
    }, [])

    return {displayCartOnRedirect, showLocationModal, setShowLocationModal, showCartModal, setShowCartModal};
}

export default useDeepLinkRedirection;