import { setShowCart, updateCartRedeemedOfferItem } from "../redux/slices/cartSlice";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import { cartItem, IEligibleItem, IOfferItem, IOfferItemDetails } from "../models/cart.model";
import * as CONSTANT from "../constants";
import { Toast_Func } from "../helpers/toast.helper";
import _clonedeep from 'lodash.clonedeep'
import { getSelectedStore } from "../helpers/helperMethods";
import { inRange } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { resetContext } from "../redux/slices/itemCustomizationSlice";
import { itemBuckets } from "../priceCalculation/buckets";

interface IRedeemOffer {
  offerItem: IOfferItem;
  cartItems: cartItem[];
  selectedOfferItem: IOfferItem;
  rewardsDiscountedSubTotal?: number;
  deletedItemIndex?: number;
  isOfferClicked?: boolean;
  isRewardsPage?: boolean;
}

const useRedeemOffer = () => {  
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const categoryIdInUrl = queryParams.get("category_id");
  const cart = useAppSelector((state) => state.cart);
  
  let itemIdInUrl = null;
  if (pathname.includes(CONSTANT.ROUTE_ORDER)) itemIdInUrl = pathname.split("/")?.[2];
  
  
  const removeOffer = (selectedOfferItem: IOfferItem, showToast: boolean = false) => {
    dispatch(
      updateCartRedeemedOfferItem({
        is_redeemed_by_offer: false,
      })
    );
    selectedOfferItem.in_cart = false;
    selectedOfferItem.redeemed_by_offer_discount = 0;
    selectedOfferItem.cart_item_index = null;
    if (showToast)
      Toast_Func({
        status: false,
        message: "Applied Offer is no longer valid",
      });
  }

  const reVerifyOfferEligibility = (
    selectedOfferItem: IOfferItem,
    cartItem: cartItem,
    rewardsDiscountedSubTotal: number,
  ) => {
    if (
      !!selectedOfferItem &&
      cartItem.is_redeemed_by_offer &&
      !isCartItemRedeemableByOffer(
        cartItem,
        selectedOfferItem.data,
        rewardsDiscountedSubTotal,
      ).discount
    ) {
      removeOffer(selectedOfferItem, true)
    }
  };

  const redeemOffer = ({
    offerItem,
    cartItems,
    selectedOfferItem,
    rewardsDiscountedSubTotal = 0,
    deletedItemIndex = -1,
    isOfferClicked = false,
    isRewardsPage = false,
  }: IRedeemOffer): {
    shouldShowOfferEligibilityModal: boolean;
    failedOfferCriteria?: string;
    redeemedItemNames?: string[];
  } => {
    if (offerItem.in_cart) {
      removeOffer(selectedOfferItem);
      return { shouldShowOfferEligibilityModal: false };
    }
    const itemsInCart = _clonedeep(cartItems);
    let discountAmount = 0;
    let failedOfferCriteria = "";
    let redeemedItemNames = [];
    let appliedEligibleItemOfferId = null;
    let appliedDiscountType: string = null;
    let eligibleItemExistInCart = false;

    const redeemeableCartItemIndex = itemsInCart.findIndex((item: cartItem, index: number) => {
      const { discount, failedCriteria, eligibleItemOfferId, discountType, isEligibleItemExistInCart } =
        isCartItemRedeemableByOffer(
          item,
          offerItem.data,
          rewardsDiscountedSubTotal,
          isRewardsPage,
        );
      discountAmount = discount;
      if (isEligibleItemExistInCart) eligibleItemExistInCart = isEligibleItemExistInCart
      if (failedCriteria && failedOfferCriteria !== CONSTANT.OFFER_REWARD_REDEEMED)
        failedOfferCriteria = failedCriteria;
      if (
        failedCriteria === CONSTANT.OFFER_REWARD_REDEEMED &&
        !redeemedItemNames.includes(item.name)
      )
        redeemedItemNames.push(item.name);
      appliedEligibleItemOfferId = eligibleItemOfferId;
      appliedDiscountType = discountType;
      return deletedItemIndex !== index && discount;
    });

    const isRedeemableItemAvailableInCart = redeemeableCartItemIndex >= 0;
    // Offer is applicable flow
    if (!isRewardsPage && isRedeemableItemAvailableInCart) {
      if (!!selectedOfferItem) {
        removeOffer(selectedOfferItem);
      }
      if (discountAmount !== 0) {
        dispatch(
          updateCartRedeemedOfferItem({
            index: redeemeableCartItemIndex,
            is_redeemed_by_offer: true,
            redeemed_by_offer_discount: discountAmount,
            user_offer_id: offerItem.id,
            root_offer_id: offerItem.loyalty_root_offer_id,
            eligible_item_offer_id: appliedEligibleItemOfferId,
            offer_discount_type: appliedDiscountType,
          })
        );
        offerItem.in_cart = true;
        offerItem.redeemed_by_offer_discount = discountAmount;
        offerItem.cart_item_index = redeemeableCartItemIndex;
      }
      return { shouldShowOfferEligibilityModal: false };
    } 

    // Redirection Flow Control
    const isEligbleItemOfferClicked = isOfferClicked && !!offerItem.data.eligible_items_ids.length;
    const shouldRedirect =
      isEligbleItemOfferClicked &&
      !eligibleItemExistInCart &&
      failedOfferCriteria !== CONSTANT.OFFER_REWARD_REDEEMED;
    if (shouldRedirect) {
      const shouldRedirectToItem = offerItem.redirect_to_item;
      const redirectItemId = offerItem.redirect_item_id;
      const redirectCategoryId = offerItem.redirect_category_id;
      const isSameItemRedirectUrl =
        shouldRedirectToItem &&
        redirectItemId === Number(itemIdInUrl) &&
        redirectCategoryId === Number(categoryIdInUrl);

      if (!isSameItemRedirectUrl) { 
        // Reset Single Item Customization
        if (shouldRedirectToItem) {
          itemBuckets.createComboBucket(
            {
              keys: [CONSTANT.REQUIRED_MODIFIERS, CONSTANT.CORE_MODIFIERS, CONSTANT.ADD_ONS, CONSTANT.COMPLIMENTARY_MODIFIER],
              items: CONSTANT.SINGLE_ITEM_SELECTED_SECTION
            }
          );
          dispatch(resetContext());
        }

        // Redirecting ...
        history.push({
          pathname: shouldRedirectToItem
            ? `/order/${offerItem?.data.redirect_item_id}`
            : `/menu/${offerItem?.data.redirect_category_name}`,
          ...(shouldRedirectToItem && {
            search: `category_id=${offerItem?.data.redirect_category_id}`,
            state: { reward_size: offerItem?.data?.redirect_item_size}
          }),
        });
      }
      
      dispatch(setShowCart(false));
      return { shouldShowOfferEligibilityModal: false };
    }

    // Non-Redirection Flow Control
    
    // Deleting item from cart flow
    if (deletedItemIndex >= 0)
      failedOfferCriteria = "Failed";

    return {
      shouldShowOfferEligibilityModal: true,
      failedOfferCriteria,
      redeemedItemNames,
    };
  };

  const isCartItemRedeemableByOffer = (
    cartItem: cartItem,
    offerDetails: IOfferItemDetails,
    rewardsDiscountedSubTotal: number,
    isRewardsPage: boolean = false
  ): {
    discount: number;
    failedCriteria: string;
    eligibleItemOfferId: string;
    discountType: string;
    isEligibleItemExistInCart?: boolean;
  } => {
    const {
      offer_redemption_restrictions,
      eligible_locations,
      eligible_items,
      eligible_items_ids,
      offer_type,
      discount_amount,
      percent_discount
    } = offerDetails;

    const eligibleItemKey = `${
      cartItem.item_id
    }-${cartItem.item_size?.toLocaleLowerCase()}`;
    const foundEligibleItemMatch: IEligibleItem = eligible_items[eligibleItemKey];
    const isEligibleItemExistInCart = !!foundEligibleItemMatch;
    
    const baseResponse = {
      discount: 0,
      failedCriteria: "",
      eligibleItemOfferId: null,
      discountType: null,
      isEligibleItemExistInCart
    };

    // Eligible Items
    if (eligible_items_ids.length && !isEligibleItemExistInCart) {
      return baseResponse;
    }

    // note: this would need a change when items are redeemable even when quantity > 1
    // Eligible Item(s) is redeemed by rewards
    if (
      foundEligibleItemMatch &&
      (cartItem?.reward_redeemed || cartItem.redemption_by_points)
    ) {
      return {
      ...baseResponse,
      failedCriteria: CONSTANT.OFFER_REWARD_REDEEMED
      };
    }

    // Eligible Locations
    const selectedStore = getSelectedStore();
    if (
      eligible_locations.length &&
      !eligible_locations.includes(selectedStore.id)
    )
      return {
        ...baseResponse,
        failedCriteria: CONSTANT.OFFER_ELIGIBLE_LOCATIONS
      };

    // Eligible Min Max Range, Check Amount 
    const { maximum_check_amount, minimum_check_amount } =
    offer_redemption_restrictions?.check_amount_restriction || {
      maximum_check_amount: 0,
      minimum_check_amount: 0,
    };
    if (
      (maximum_check_amount || minimum_check_amount) &&
      !(
        inRange(
          rewardsDiscountedSubTotal,
          minimum_check_amount || 0,
          maximum_check_amount || Number.MAX_SAFE_INTEGER
        ) || rewardsDiscountedSubTotal === maximum_check_amount
      )
    )
      return {
        ...baseResponse,
        failedCriteria: CONSTANT.OFFER_ELIGIBLE_SUBTOTAL_RANGE
      };
        
    // Cart is already free
    if (!rewardsDiscountedSubTotal && !isRewardsPage)
      return {
        ...baseResponse,
        failedCriteria: CONSTANT.OFFER_DISCOUNTED_SUBTOTAL_ZERO
      };

    const discount =
      offer_type === CONSTANT.OFFER_TYPE_FIXED_DISCOUNT
        ? discount_amount
        : foundEligibleItemMatch?.discount_amount || percent_discount || 0;

    const discountType = foundEligibleItemMatch?.discount_amount
      ? CONSTANT.OFFER_TYPE_FIXED_DISCOUNT
      : offer_type;

    return {
      discount,
      failedCriteria: null,
      eligibleItemOfferId: foundEligibleItemMatch?.id,
      discountType,
      isEligibleItemExistInCart
    };
  };

  return { redeemOffer, reVerifyOfferEligibility, removeOffer };
};

export default useRedeemOffer;
