import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { IOfferItem } from "../../../models/cart.model";
import styleClasses from "../../Header/Header.module.scss";
import OfferItemCard from "./OfferItemCard";

interface IOfferItemListProps {
  offers: IOfferItem[];
  selectedOfferItem: IOfferItem;
  isRewardList: boolean;
  closeModal: () => void;
  title: string;
  addToOrder: () => void;
  rewardsDiscountedSubTotal: number;
}

const OfferItemsList: React.FC<IOfferItemListProps> = (props) => {
  const { offers, selectedOfferItem, closeModal, title, addToOrder, rewardsDiscountedSubTotal } =
    props;
  const getBtnText = (reward) => {
    return reward.in_cart ? "Redeemed" : "Redeem";
  };
  if (!offers?.length) return null;

  return (
    <>
      <div className={styleClasses.other_products_wrap}>
        <h4 className="f-s22 f-sm-s16 font-Cls lh-normal ls-normal mb-3">
          {title}
        </h4>
        <Row className={styleClasses.suggestion_row + " flex-nowrap pt-1 "}>
          <Scrollbars
            className={styleClasses.offers_scrolbar_height}
            renderThumbHorizontal={(props) => (
              <div {...props} className={styleClasses.Scrollbar_thumb} />
            )}
            renderView={(props) => (
              <div {...props} className={styleClasses.Scrollbar_view} />
            )}
          >


            {offers.map((offerItem: IOfferItem, index: number) => {
              return (
                <OfferItemCard
                  key={index}
                  handleRedeemClick={() => { }}
                  offerItem={offerItem}
                  selectedOfferItem={selectedOfferItem}
                  rewardsDiscountedSubTotal={rewardsDiscountedSubTotal}
                  btnText={getBtnText(offerItem)}
                />
              );
            })}
          </Scrollbars>
        </Row>
      </div>
      <hr className="shadow-divider mb-4" />
    </>

  );
};

export default OfferItemsList;
