import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Nav, Tab, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styleClasses from "./accounts.module.scss";
import UserSelections from "../../components/UserSelections/UserSelections";
import { CustomModal } from "../../components/Modal/CustomModal";
import { DeliveryAddressForm } from "../../components/Forms/DeliveryAddressForm";
import PersonalInfoTab from "../../components/Tabs/PersonalInfo";
import DeliveryAddressesTab from "../../components/Tabs/DeliveryAdress/DeliveryAddresses";
import { PersonalInfoForm } from "../../components/Forms/PersonalInfoForm";
import StoredPayments from "../../components/Tabs/StoredPayments";
import VehicleDetails from "../../components/Tabs/VehicleInfo/VehicleDetails";
import VehicleInfoForm from "../../components/Forms/VehicleInfoForm";
import ScrollspyNav from "react-scrollspy-nav";
import { Scrollbars } from "react-custom-scrollbars";
import { RecentOrder } from "../../components/Tabs/RecentOrder/RecentOrder";
import FavoriteItems from "../../components/Tabs/FavoriteItems/FavoriteItems";
import * as CONSTANT from "../../../src/constants";
import NewFooter from "../../components/Footer/NewFooter";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import useProfile from "../../react-query-hooks/useProfile";
import { useAuthState } from "../../context/UserAuthentication";
import Spinner from "../../components/Spinner/Spinner";
import { scrollToElementByRef } from "../../helpers/helperMethods";
import _debounce from "lodash/debounce";
import useOrderHistory from "../../react-query-hooks/useOrderHistory";
import usePaymentMethods from "../../react-query-hooks/usePaymentMethods";
import useFavorites from "../../react-query-hooks/useFavorites";
import { useStoreLocation } from "../../context/StoreLocation";
import DeleteAccount from "../../components/Tabs/DeactivateAccount/DeleteAccount";
import StoredGiftCards from "../../components/Tabs/GiftCard";
import useGetGiftCards from "../../react-query-hooks/useGetGiftCards";
import useRemoveFavoriteOrder from "../../react-query-hooks/Favorites/useRemoveFavoriteOrder";

import useAddFavoritesOrder from "../../react-query-hooks/Favorites/useAddFavoriteOrder";
import { FormField } from "../../components/FormFields/FormField";
import { Formik } from "formik";
import _clonedeep from "lodash.clonedeep";
import * as Yup from "yup";
import { useQueryClient } from "react-query";
import useGetCustomerPlacedOrders from "../../react-query-hooks/useGetCustomerPlacedOrders";

interface VehicleInfo {
  show: boolean;
  title: string;
  id: number | null;
  forOneTimeOnly?: boolean;
}

export default function ProfileDetails() {
  const [activeTabkey, setActiveTabkey] = useState("first");
  const queryClient = useQueryClient();
  useEffect(() => {
    const getActiveTab = JSON.parse(sessionStorage.getItem("activeTab"));
    if (getActiveTab) {
      setActiveTabkey(getActiveTab);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("activeTab", JSON.stringify(activeTabkey));
  }, [activeTabkey]);

  const { authInfo } = useAuthState();
  const [favoriteModal, setFavoriteModal] = useState(false);

  const [show3, setShow3] = useState(false);
  const scrollToFavOrders = useRef();
  const [vehicleInfoModal, setvehicleInfoModal] = useState<VehicleInfo>({
    show: false,
    title: "",
    id: null,
    forOneTimeOnly: false,
  });
  const { isFetching, data, error } = useProfile(authInfo.userId);
  const [addressFormState, setAddressFormState] = useState<{
    show: boolean;
    title: string;
    id: number | null;
  }>({ show: false, title: "", id: null });
  const isItMobile = useCheckMobileScreen();

  const { data: { data: placedOrders = [] } = [] } = useGetCustomerPlacedOrders(
    { userId: authInfo?.userId }
  );

  const isCheckTrackBar =
    placedOrders?.length && authInfo.type !== CONSTANT.GUEST_USER;
  const [cardIncrement, setcardIncrement] = useState(false);
  const [vehicleInfoForOneTime, setVehicleInfoForOneTime] = useState([]);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const vehicleEditInfoModal = (
    title?: string,
    id?: number,
    forOneTimeOnly?: boolean
  ) =>
    setvehicleInfoModal({
      show: true,
      title: title,
      id: id,
      forOneTimeOnly: forOneTimeOnly,
    });
  const closeEditInfoModal = () =>
    setvehicleInfoModal({ show: false, title: "", id: null });

  const hideAddressForm = () => {
    setAddressFormState({ show: false, title: "", id: null });
  };
  const showAddressForm = (title?: string, id?: number) => {
    setAddressFormState({ show: true, title: title, id: id });
  };

  const addVehicleInfoForOneTime = (data) => {
    setVehicleInfoForOneTime([data, ...vehicleInfoForOneTime]);
  };
  const editVehicleInfoForOneTime = (data) => {
    vehicleInfoForOneTime[data.id] = data;
  };
  const removeVehicleInfoForOneTime = (id) => {
    let vehicleInfo = vehicleInfoForOneTime.filter((item) => item.id != id);
    setVehicleInfoForOneTime([...vehicleInfo]);
  };

  useEffect(() => {
    if (cardIncrement) {
      setTimeout(function () {
        setcardIncrement(false);
      }, 1500);
    }
  }, [cardIncrement]);
  const history = useHistory();
  const location = useLocation();
  const goToRoute = (route: string) => history.push(route);

  useEffect(() => {
    // var navigationSelect = document.querySelector(".select-wrapper");
    function initSelect(elem) {
      var selectHolder = elem.querySelector(".holder");
      var selectOptions = elem.querySelectorAll(".dropdownOption li");
      var dropHolder = elem.querySelector(".dropdown");
      var selectedOption = selectOptions[0];

      selectedOption.classList.add("current");

      selectHolder.addEventListener("click", function () {
        dropHolder.classList.toggle("active");
      });

      selectOptions.forEach(function (currentElement) {
        currentElement.addEventListener("click", function () {
          selectedOption.classList.remove("current");
          selectedOption = currentElement;
          currentElement.classList.add("current");
          selectHolder.innerText = currentElement.textContent;
          dropHolder.classList.toggle("active");
        });
      });
    }

    // initSelect(navigationSelect);
  }, []);
  const {
    data: orderHistoryData,
    isLoading: isFetchingOrderHistory,
    refetch: refetchOrderHistoryData,
  } = useOrderHistory(authInfo.userId);
  const {
    data: FavoriteorderHistoryData,
    isLoading: isFetchingFavoriteOrderHistory,
    isRefetching: isReFetchingFavoriteOrderHistory,
    refetch: refetchFavoriteOrderHistoryData,
  } = useOrderHistory(authInfo.userId, { page: 1, is_favorite: 1 });

  const { locationInfo } = useStoreLocation();
  const location_id = locationInfo?.selectedStore?.id ?? "";

  const { data: paymentRecords = [], isFetching: isFetchingPaymentRecords } =
    usePaymentMethods(authInfo.userId);

  const {
    isFetching: isFetchingFavourites,
    data: favouritesData = [],
    refetch: refetchFavourites,
  } = useFavorites(authInfo.userId, authInfo.type, location_id);

  const [favoriteItems, setFavoriteItems] = useState([]);

  useEffect(() => {
    refetchOrderHistoryData();
  }, []);

  useEffect(() => {
    if (orderHistoryData?.pages?.length > 0)
      setFavoriteItems(orderHistoryData?.pages);
  }, [orderHistoryData]);

  const { data: giftCardRecords = [], isFetching: isFetchingGiftCards } =
    useGetGiftCards(authInfo.userId);
  let scrollToTab = "first";
  const state = location.state as { scrollTo: string };
  if (
    state?.scrollTo === CONSTANT.SCROLL_TO_PROFILE_FAV_ITEMS &&
    isItMobile &&
    !isFetching &&
    !isFetchingOrderHistory &&
    !isFetchingPaymentRecords &&
    !isFetchingFavourites
  ) {
    scrollToTab = CONSTANT.SCROLL_TO_PROFILE_FAV_ITEMS;
    delete state.scrollTo;
    history.replace({ ...history.location, state });
  }
  const scrollToFavItemsDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    refetchFavourites();
  }, [location_id]);

  useEffect(() => {
    if (state?.scrollTo === CONSTANT.SCROLL_TO_PROFILE_FAV_ITEMS) {
      scrollToTab = CONSTANT.SCROLL_TO_PROFILE_FAV_ITEMS;
      if (
        !isItMobile &&
        !isFetching &&
        !isFetchingOrderHistory &&
        !isFetchingPaymentRecords &&
        !isFetchingFavourites
      ) {
        setTimeout(() => {
          scrollToElementByRef(scrollToFavItemsDivRef);
        }, 250);
        delete state.scrollTo;
        history.replace({ ...history.location, state });
      }
    }
  }, [
    isFetching,
    isFetchingOrderHistory,
    isFetchingPaymentRecords,
    isFetchingFavourites,
  ]);

  if (
    isFetching ||
    isFetchingOrderHistory ||
    isFetchingPaymentRecords ||
    isFetchingFavourites
  )
    return <Spinner />;

  return (
    <div className="pt-117">
      <div className="container-fluid">
        <button
          onClick={(event: any) => {
            event.preventDefault();
            goToRoute(CONSTANT.ROUTE_MENU);
          }}
          className="btn btn-custom f-s14  ms-3 p-0 mt-4 align-items-center gap-2 d-none d-md-flex position-fixed"
        >
          <img
            src={require("../../../src/images/arrow-back.svg")}
            alt="arrow back"
          />
          Back
        </button>
      </div>
      <div className={`${styleClasses.profile_wrapper} profile-wrapper`}>
        <div
          className={`${styleClasses.profile_tabs_wrapper} text-center pt-2 pb-4 py-md-4 custom_w px-3`}
        >
          {isItMobile && (
            <div
              className={`${styleClasses.nav} d-flex select-wrapper d-md-none ${
                isCheckTrackBar && "pt-cz-20"
              }`}
            >
              <Tab.Container
                defaultActiveKey={activeTabkey}
                activeKey={activeTabkey}
                onSelect={(k) => {
                  setActiveTabkey(k);
                }}
              >
                <Nav variant="pills">
                  <Scrollbars
                    className={`${styleClasses.menu_items_scrollbar} ${styleClasses.profile_items_scrollbar}`}
                    renderThumbHorizontal={(props) => (
                      <div
                        {...props}
                        className={styleClasses.Scrollbar_thumb}
                      />
                    )}
                    renderView={(props) => (
                      <div
                        {...props}
                        className={`${styleClasses.Scrollbar_view} Scrollbar_view`}
                      />
                    )}
                  >
                    <Nav.Item className={`${styleClasses.nav_items}`}>
                      <Nav.Link eventKey="first">Personal Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styleClasses.nav_items}`}>
                      <Nav.Link eventKey="second">Payments</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styleClasses.nav_items}`}>
                      <Nav.Link eventKey="third">Gift cards</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styleClasses.nav_items}`}>
                      <Nav.Link eventKey="fourth">Delivery Addresses</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styleClasses.nav_items}`}>
                      <Nav.Link eventKey="fifth">Vehicle Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styleClasses.nav_items}`}>
                      <Nav.Link eventKey="sixth">Recent Orders</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className={`${styleClasses.nav_items}`}>
                      <Nav.Link eventKey="seventh">Favorite Orders</Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item className={`${styleClasses.nav_items}` }>
                      <Nav.Link eventKey="seventh">Favorite Items</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item className={`${styleClasses.nav_items} mb-0`}>
                      <Nav.Link eventKey="eight">Delete Account</Nav.Link>
                    </Nav.Item>
                  </Scrollbars>
                </Nav>
                <Tab.Content className="w-100">
                  <Tab.Pane eventKey="first" title="Profile Info">
                    <button
                      onClick={(event: any) => {
                        event.preventDefault();
                        goToRoute(CONSTANT.ROUTE_MENU);
                      }}
                      className="btn btn-custom f-s16  p-0 my-3 align-items-center gap-2  d-flex"
                    >
                      <img
                        src={require("../../../src/images/arrow-back.svg")}
                        alt="arrow back"
                      />
                      Back
                    </button>
                    <PersonalInfoTab
                      data={data}
                      eventKey={"first"}
                      showEditInfoModal={handleShow3}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second" title="Payment">
                    <button
                      onClick={(event: any) => {
                        event.preventDefault();
                        goToRoute(CONSTANT.ROUTE_MENU);
                      }}
                      className="btn btn-custom f-s16  p-0 my-3 align-items-center gap-2  d-flex"
                    >
                      <img
                        src={require("../../../src/images/arrow-back.svg")}
                        alt="arrow back"
                      />
                      Back
                    </button>
                    <StoredPayments
                      data={data}
                      paymentRecords={paymentRecords}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third" title="Gift Cards">
                    <button
                      onClick={(event: any) => {
                        event.preventDefault();
                        goToRoute(CONSTANT.ROUTE_MENU);
                      }}
                      className="btn btn-custom f-s16  p-0 my-3 align-items-center gap-2  d-flex"
                    >
                      <img
                        src={require("../../../src/images/arrow-back.svg")}
                        alt="arrow back"
                      />
                      Back
                    </button>
                    <StoredGiftCards giftCardRecords={giftCardRecords} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth" title="Delivery Address">
                    <button
                      onClick={(event: any) => {
                        event.preventDefault();
                        goToRoute(CONSTANT.ROUTE_MENU);
                      }}
                      className="btn btn-custom f-s16  p-0 my-3 align-items-center gap-2  d-flex"
                    >
                      <img
                        src={require("../../../src/images/arrow-back.svg")}
                        alt="arrow back"
                      />
                      Back
                    </button>
                    <DeliveryAddressesTab
                      data={data}
                      eventKey={"fourth"}
                      showModal={showAddressForm}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth" title="Vehicle Info">
                    <button
                      onClick={(event: any) => {
                        event.preventDefault();
                        goToRoute(CONSTANT.ROUTE_MENU);
                      }}
                      className="btn btn-custom f-s16  p-0 my-3 align-items-center gap-2  d-flex"
                    >
                      <img
                        src={require("../../../src/images/arrow-back.svg")}
                        alt="arrow back"
                      />
                      Back
                    </button>
                    <VehicleDetails
                      data={data}
                      eventKey={"fifth"}
                      showVehicleInfoModal={vehicleEditInfoModal}
                      vehicleInfoForOneTime={vehicleInfoForOneTime}
                      removeVehicleInfoForOneTime={removeVehicleInfoForOneTime}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth" title="Recent Orders">
                    <button
                      onClick={(event: any) => {
                        event.preventDefault();
                        goToRoute(CONSTANT.ROUTE_MENU);
                      }}
                      className="btn btn-custom f-s16  p-0 my-3 align-items-center gap-2  d-flex"
                    >
                      <img
                        src={require("../../../src/images/arrow-back.svg")}
                        alt="arrow back"
                      />
                      Back
                    </button>
                    <RecentOrder
                      order={orderHistoryData}
                      refetchFavoriteAndOrderHistoryData={[
                        refetchOrderHistoryData,
                        refetchFavoriteOrderHistoryData,
                      ]}
                      setActiveTabkey={setActiveTabkey}
                      // showFavoriteModal={decideShowLabelOrDelectionModal}
                    />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="seventh" title="Favorite Orders">
                    <button
                      onClick={(event: any) => {
                        event.preventDefault();
                        goToRoute(CONSTANT.ROUTE_MENU);
                      }}
                      className="btn btn-custom f-s16  p-0 my-3 align-items-center gap-2  d-flex"
                    >
                      <img
                        src={require("../../../src/images/arrow-back.svg")}
                        alt="arrow back"
                      />
                      Back
                    </button>
                    <RecentOrder
                      order={FavoriteorderHistoryData}
                      isFavoriteLoading={
                        isReFetchingFavoriteOrderHistory ||
                        isFetchingFavoriteOrderHistory
                      }
                      refetchFavoriteAndOrderHistoryData={[
                        refetchOrderHistoryData,
                        refetchFavoriteOrderHistoryData,
                      ]}
                      // showFavoriteModal={decideShowLabelOrDelectionModal}
                      is_favorite_order={true}
                      // setActiveTabkey={setActiveTabkey}
                    />
                    <FavoriteItems
                      data={favouritesData}
                      isFetching={isFetchingFavourites}
                      refetch={refetchFavourites}
                    />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="eight" title="Deactivate Account">
                    <button
                      onClick={(event: any) => {
                        event.preventDefault();
                        goToRoute(CONSTANT.ROUTE_MENU);
                      }}
                      className="btn btn-custom f-s16  p-0 my-3 align-items-center gap-2  d-flex"
                    >
                      <img
                        src={require("../../../src/images/arrow-back.svg")}
                        alt="arrow back"
                      />
                      Back
                    </button>
                    <DeleteAccount />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          )}
          <UserSelections scrollToDivRef={scrollToFavItemsDivRef} />
          {!isItMobile && (
            <div
              className={`${styleClasses.profile_tabs_wrapper} text-center pt-2 pb-4 py-md-4 custom_w px-3 d-none d-md-block`}
            >
              <Row className="mt-md-2">
                <Col md={3} className="position-relative">
                  <ScrollspyNav
                    scrollTargetIds={[
                      "Personal_Info",
                      "Payment",
                      "Gift_Cards",
                      "Delivery_Address",
                      "Vehicle_Info",
                      "Recent_Orders",
                      // "Favorite_Orders",
                      "Delete_Account",
                    ]}
                    scrollDuration="500"
                    activeNavClass="is-active"
                    offset={-230}
                    headerBackground="true"
                  >
                    <Nav
                      variant="pills"
                      className={`${styleClasses.nav} flex-column d-none d-md-flex`}
                    >
                      <Nav.Item>
                        <Nav.Link
                          href="#Personal_Info"
                          className={` is-active`}
                        >
                          Profile Info
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link href="#Payment"> Payment</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="#Gift_Cards"> Gift Cards</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="#Delivery_Address">
                          Delivery Address
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="#Vehicle_Info">Vehicle Info</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="#Recent_Orders">Recent Orders</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link href="#Favorite_Orders">
                          Favorite Orders
                        </Nav.Link>
                      </Nav.Item> */}
                      {/* <Nav.Item>
                        <Nav.Link href="#Favorite_Items">
                          Favorite Items
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link href="#Delete_Account">
                          Delete Account
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </ScrollspyNav>
                </Col>
                <Col md={9} className={styleClasses.scroll_spacing}>
                  <div>
                    <div id="Personal_Info">
                      <PersonalInfoTab
                        data={data}
                        eventKey={"first"}
                        showEditInfoModal={handleShow3}
                      />
                    </div>
                    <div id="Payment">
                      <StoredPayments
                        data={data}
                        paymentRecords={paymentRecords}
                      />
                    </div>
                    <div id="Gift_Cards">
                      <StoredGiftCards giftCardRecords={giftCardRecords} />
                    </div>
                    <div id="Delivery_Address">
                      <DeliveryAddressesTab
                        data={data}
                        eventKey={"third"}
                        showModal={showAddressForm}
                      />
                    </div>
                    <div id="Vehicle_Info">
                      <VehicleDetails
                        data={data}
                        eventKey={"fourth"}
                        showVehicleInfoModal={vehicleEditInfoModal}
                        vehicleInfoForOneTime={vehicleInfoForOneTime}
                        removeVehicleInfoForOneTime={
                          removeVehicleInfoForOneTime
                        }
                      />
                    </div>
                    <div id="Recent_Orders">
                      <RecentOrder
                        setActiveTabkey={setActiveTabkey}
                        order={orderHistoryData}
                        refetchFavoriteAndOrderHistoryData={[
                          refetchOrderHistoryData,
                          refetchFavoriteOrderHistoryData,
                        ]}
                        // showFavoriteModal={decideShowLabelOrDelectionModal}
                        // scrollToFavOrders={scrollToFavOrders}
                      />
                    </div>
                    {/* <div id="Favorite_Orders" ref={scrollToFavOrders}>
                      <RecentOrder
                        order={FavoriteorderHistoryData}
                        isFavoriteLoading={
                          isReFetchingFavoriteOrderHistory ||
                          isFetchingFavoriteOrderHistory
                        }
                        refetchFavoriteAndOrderHistoryData={[
                          refetchOrderHistoryData,
                          refetchFavoriteOrderHistoryData,
                        ]}
                        // showFavoriteModal={decideShowLabelOrDelectionModal}
                        is_favorite_order={true}
                        // setActiveTabkey={setActiveTabkey}
                      />
                    </div> */}
                    {/* <div id="Favorite_Items" ref={scrollToFavItemsDivRef}>
                      <FavoriteItems
                        data={favouritesData}
                        isFetching={isFetchingFavourites}
                        refetch={refetchFavourites}
                      />
                    </div> */}
                    <div id="Delete_Account" ref={scrollToFavItemsDivRef}>
                      <DeleteAccount />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div className="d-md-none">
          <NewFooter />
        </div>

        <div className={`position-relative d-none d-md-block pt-5`}>
          <NewFooter footer_img_controller="d-none"></NewFooter>
        </div>
        {/* Edit Information Modal */}
        <CustomModal
          showModal={show3}
          closeModal={handleClose3}
          title={" EDIT INFORMATION"}
        >
          <PersonalInfoForm closeModal={handleClose3} />
        </CustomModal>
        {/* New Payment Method Modal */}

        {/* Add/Edit Delivery Address Modal */}
        <CustomModal
          showModal={addressFormState.show}
          closeModal={hideAddressForm}
          title={addressFormState.title}
        >
          <DeliveryAddressForm
            closeModal={hideAddressForm}
            addressId={addressFormState.id}
          />
        </CustomModal>
        {/* Add/Edit Delivery Address Modal */}
        <CustomModal
          showModal={vehicleInfoModal.show}
          closeModal={closeEditInfoModal}
          title={vehicleInfoModal.title}
        >
          <VehicleInfoForm
            closeModal={closeEditInfoModal}
            vehicleId={vehicleInfoModal.id}
            addVehicleInfoForOneTime={addVehicleInfoForOneTime}
            vehicleInfoForOneTime={vehicleInfoForOneTime}
            editVehicleInfoForOneTime={editVehicleInfoForOneTime}
            vehicleInfo={vehicleInfoModal}
          />
        </CustomModal>
      </div>
    </div>
  );
}

const OrderFavoriteSchema = Yup.object().shape({
  orderFavouriteLabel: Yup.string().trim().required("Must Enter Name").max(30,"Length Should Be Less Than 30 Character"),

});

export const LabelFavoriteOrder = ({
  showAddLabelModal,
  hideLabelModal,
  onAddLabelOrderHandler,
}) => {
  const initialFormState = {
    orderFavouriteLabel: "",
  };

  return (
    <Modal
      show={showAddLabelModal}
      onHide={hideLabelModal}
      centered
      className="theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-8 "
    >
      <Modal.Header className="pt-0" id="close_button2">
        <button
          type="button"
          className="btn modal-close-button pe-0 blue-icon"
          onClick={hideLabelModal}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body className="text-center py-5 px-0 px-md-3">
        <h6 className="font-Cls ls-normal mb-sm-3 mb-3 f-s22 text-capitalize">
          Name your favorite order 
        </h6>
        <div className="new_form_design">
        <Formik
          initialValues={initialFormState}
          validationSchema={OrderFavoriteSchema}
          onSubmit={onAddLabelOrderHandler}
          validateOnMount
        >
          {({ errors, touched, handleChange, isSubmitting, handleSubmit,values }) => {
            return (
              <>
                <FormField
                  labelText={""}
                  name="orderFavouriteLabel"
                  type="text"
                  onChange={(e)=> {
                    e.target.value=e.target.value.trimStart()
                    handleChange(e)}}
                  errors={errors}
                  touched={touched}
                  maxLength={30}
                  placeholder="Enter Name"
                />
                <button
                  // type="submit"
                  disabled={ errors?.orderFavouriteLabel ? true : false}
                  className="btn btn-large mt-4 mb-3 d-block mx-auto"
                  onClick={()=>handleSubmit()}
                >
                  Save
                </button>
              </>
            );
          }}
        </Formik>
        </div>
      

        {/* <h6 className="font-Cls ls-normal mb-sm-4 mb-3 f-s22">
          Edit or Remove
        </h6>
        <p className="f-s14 mb-4">
          Would you like to edit or remove
          <br />
          this selected item?
        </p>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-large mb-sm-4 mb-3 d-block mx-auto"
            onClick={hideLabelModal}
            // disabled={isCustomizeable}
          >
            No
          </button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export const AddToFavoriteModalConfirmation = ({
  showAddAndRemoveModal,
  hideAddAndRemoveModal,
  id,
  name,
  onRemoveClickHandler,
}) => {
  return (
    <Modal
      show={showAddAndRemoveModal}
      onHide={hideAddAndRemoveModal}
      centered
      className="theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-8 "
    >
      <Modal.Header className="pt-0" id="close_button2">
        <button
          type="button"
          className="btn modal-close-button pe-0 blue-icon"
          onClick={hideAddAndRemoveModal}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body className="text-center py-5 px-0 px-md-3">
        <h6 className="font-Cls ls-normal mb-sm-4 mb-3 f-s22 text-capitalize">
          Are you sure?
        </h6>
        <p className="f-s14 mb-4">
          Your favorite order will be deleted
          {/* <br />
          this selected item? */}
        </p>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-large mb-sm-4 mb-3 d-block mx-auto"
            onClick={onRemoveClickHandler}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-custom f-s16 font-Vcf clr-dark-blue p-0 ls-05"
            onClick={hideAddAndRemoveModal}
          >
            No, Keep The Order
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
