import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Image from "../../components/Image/Image";
import NavbarComponent from "../../components/NavbarComponent/NavbarComponent";
import * as CONSTANT from "../../constants";
import { useStoreLocation } from "../../context/StoreLocation";
import SignUpButton from "../AccountStatus/SignUpButton";
import UserName from "../AccountStatus/UserName";
import CartModal from "../Cart/CartModal/CartModal";
import { LocationInfoModal } from "../LocationInfo/LocationInfoModal";
import styleClasses from "./Header.module.scss";
import { useAppSelector, useAppDispatch } from "../../redux/store/store";
import { setShowCart } from "../../redux/slices/cartSlice";
import { useAuthState } from "../../context/UserAuthentication";
import OrderType from "../ItemGrid/OrderType";
import Livetracking from "../LiveTracking/Livetracking";
import {
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  ROUTE_CHECKOUT,
  ROUTE_HOME,
} from "../../constants";
import useAddToOrder from "../../hooks/useAddToOrder";
import useGetCustomerPlacedOrders from "../../react-query-hooks/useGetCustomerPlacedOrders";
import { guestLogin } from "../../context/UserAuthentication/actions";
import { getUser, getVisitorId } from "../../helpers/helperMethods";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import OrderDetailsMobile from "../../containers/order/OrderDetails/OrderDetailsMobile";
import { Toast_Func } from "../../helpers/toast.helper";
import AddressConfirmationForUser from "../Forms/AddressConfimationForUser";
import useAddAddress from "../../react-query-hooks/useAddAddress";
import PopUpModel from "../../components/Footer/PopUpModel";
import useKeypress from "../../hooks/useKeypress";
import { ROUTE_REWARDS_REDEEM } from "../../constants";
import { NavlinkModel } from "../../models/navlink.model";
import { Helmet } from "react-helmet";
import { getStructuredItemList } from "../../helpers/scripts/seoScript";
import { cartSubTotal } from "../../helpers/cartHelperMethods";
import HeaderTop from "./HeaderTop";
import {
  CartBagIcon,
  ChevronIcon,
  DeliveryIcon,
  GeoLocationWhiteIcon,
  LocationFilledIcon,
  LocationOutlineIcon,
  DeliveryIconMobile,
  CartBagItemAdded,
  CZLogo,
} from "../../images/svgIcons/svg";
import MegaMenuDropDown from "../MegaMenuDropDown/MegaMenuDropDown";
import useGetUsersLoyaltyPoints from "../../react-query-hooks/useGetUsersLoyaltyPoints";
import { updateLoyalityPoints } from "../../redux/slices/itemSlice";
import useKioskLogin from "../../hooks/useKioskLogin";
import useDeepLinkRedirection from "../../hooks/useDeepLinkRedirection";

const Header: React.FunctionComponent = () => {
  const isItMobile = useCheckMobileScreen();
  const { locationInfo } = useStoreLocation();
  const {removeKioskLoginSession} = useKioskLogin();

  const history = useHistory();
  const { authInfo, dispatch: authDispatch } = useAuthState();
  const Cart = useAppSelector((state) => state.cart);
  const { showLocationModal, setShowLocationModal, setShowCartModal, showCartModal} = useDeepLinkRedirection();
  const dispatch = useAppDispatch();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  
  useEffect(() => {
    if (showCartModal) {
      setShow(true);
    }
    setOpenOrderModal(showLocationModal);
  },[showCartModal, showLocationModal]);

  let show = Cart.showCart;
  const setShow = (state) => {
    dispatch(setShowCart(state));
  };
  const user = getUser();
  const userId = user.id ?? "";
  const userType = user.type ?? "";

  const { addToOrder } = useAddToOrder();
  const location = useLocation();
  const [stickyClass, setStickyClass] = React.useState("remove-sticky");
  const navLinksArray: NavlinkModel[] = [
    {
      text: "REWARDS",
      link: CONSTANT.ROUTE_FAQ,
      alternateURL: { exist: true, link: ROUTE_REWARDS_REDEEM },
    },
    // {
    //   text: "CAREERS",
    //   link: CONSTANT.ROUTE_CAREERS,
    //   alternateURL: { exist: false, link: null },
    // },
    {
      text: "FUNDRAISERS",
      link: CONSTANT.ROUTE_FUNDRAISING,
      alternateURL: { exist: false, link: null },
    },
  ];
  const [showLocationPopup, setLocationPopup] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showLiveTracker, setShowLiveTracker] = useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const [addressConfirmationModal, setAddressConfirmationModal] = useState<{
    showModal: boolean;
    showCart: boolean;
  }>({ showModal: false, showCart: false });
  const { mutateAsync: addAddress, isLoading: isAddingAddress } =
    useAddAddress();
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [isActiveOrders, setIsActiveOrders] = useState<boolean>(true);
  const [openMegaMenu, setOpenMegaMenu] = useState<boolean>(false);
  const [megaMenuAnimateClass, setMegaMenuAnimateClass] = useState<string>("");
  const [onScrollNavHide, setOnScrollNavHide] = useState(false);
  const [onScrollNavPrevPos, setOnScrollNavPrevPos] = useState(100);

  const handleClose = () => {
    setShow(false)
    setShowCartModal(false);
  };
  const handleShow = async () => {
    if (location.pathname.includes(ROUTE_CHECKOUT)) {
      history.push(CONSTANT.ROUTE_MENU);
      setShow(true);
    } else {
      if (authLoading) return;
      const user = getUser();
      if (!user.id) {
        const resp = await authenticateGuest();
        if (resp) {
          if (!Cart.isOrderTypeSelected) {
            setOpenOrderModal(true);
          } else {
            if (verifyAddress())
              setAddressConfirmationModal({ showModal: true, showCart: true });
            else setShow(true);
          }
        }
      } else {
        if (!Cart.isOrderTypeSelected) {
          setOpenOrderModal(true);
        } else {
          if (verifyAddress())
            setAddressConfirmationModal({ showModal: true, showCart: true });
          else setShow(true);
        }
      }
    }
  };
  const { data: userLoyaltyPoints } = useGetUsersLoyaltyPoints(
    authInfo.userId as string,
    authInfo.type
  );

  useEffect(() => {
    dispatch(updateLoyalityPoints(userLoyaltyPoints?.points));
  }, [userLoyaltyPoints]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = currentScrollPos > onScrollNavPrevPos;
      setOnScrollNavHide(isScrollingDown);
      const isAtTop = currentScrollPos === 10;
      setOnScrollNavPrevPos(currentScrollPos);
      if (isAtTop) {
        setOnScrollNavHide(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onScrollNavPrevPos]);

  const authenticateGuest = async () => {
    try {
      setAuthLoading(true);
      const payload = {
        visitor_id: getVisitorId(),
      };
      await guestLogin(authDispatch, dispatch, payload);
      setAuthLoading(false);
      return true;
    } catch (error) {
      setAuthLoading(false);
      return false;
    }
  };
  useEffect(() => {
    if (!user?.id && !authLoading) authenticateGuest();
  }, [user.id]);

  const closeOpenOrderModal = () => {
    setOpenOrderModal(false);
    setShowLocationModal(false);
  };

  const closeLocationPopup = () => setLocationPopup(false);

  const handleClose3 = () => setShow3(false);

  const handleLocationButton = () => {
    if (verifyAddress()) {
      setAddressConfirmationModal({ showModal: true, showCart: false });
      return;
    }
    setOpenOrderModal(true);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 1) {
      setStickyClass("add-sticky");
    } else {
      setStickyClass("remove-sticky");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const verifyAddress = () => {
    return (
      Cart.orderType === DELIVERY_ORDER_TYPE &&
      authInfo.type !== GUEST_USER &&
      Cart?.orderDetails?.delivery_address?.isGuestAddress
    );
  };

  const closeAddressConfirmationModal = () => {
    if (addressConfirmationModal.showCart) dispatch(setShowCart(true));
    setAddressConfirmationModal({ showCart: false, showModal: false });
  };
  const {
    refetch: refetchGetCustomerPlacedOrders,
    data: { data: placedOrders = [] } = [],
    isFetching: isFetchingPlacedOrders,
  } = useGetCustomerPlacedOrders({ userId: authInfo?.userId });
  useEffect(() => {
    if (authInfo && authInfo?.userId && authInfo?.access_token) {
      refetchGetCustomerPlacedOrders();
    }
    
  }, [authInfo?.userId]);
  const [orderNo, setOrderNo] = useState<number>(null);
  const orderIds = placedOrders?.map((order) => order.id);
  const state = location.state as { from: string };
  if (state?.from === CONSTANT.HISTORY_STATE_FROM_PLACED_ORDER)
    refetchGetCustomerPlacedOrders();
  const isPublicTrackingRoute = location.pathname.includes(
    CONSTANT.ROUTE_ORDER_TRACKING
  );
  const shouldShowLiveTracker =
    !isFetchingPlacedOrders &&
    placedOrders?.length &&
    !isPublicTrackingRoute &&
    isActiveOrders;

  const handleCloseLiveTracker = () => {
    setShowLiveTracker(false);
    setOrderNo(null);
  };
  const handleShowLiveTracker = () => {
    refetchGetCustomerPlacedOrders();
    setShowLiveTracker(true);
  };
  const handleMobileOrderBackward = () => {
    setOrderNo(null);
    refetchGetCustomerPlacedOrders();
  };

  if (!placedOrders?.length && showLiveTracker) {
    Toast_Func({ status: false, message: "No Order Found" });
    handleCloseLiveTracker();
  }

  const locationLabel = () => {
    if (Cart.isOrderTypeSelected) {
      return Cart.orderType === DELIVERY_ORDER_TYPE ? (
        <>
          {" "}
          {isItMobile ? <DeliveryIconMobile /> : <DeliveryIcon />}{" "}
          <span className={`${styleClasses.borderBottomLocation}`}>
            {" "}
            Delivery
          </span>{" "}
        </>
      ) : (
        (
          <>
            {" "}
            {isItMobile ? <GeoLocationWhiteIcon /> : <LocationFilledIcon />}
            <span className={`${styleClasses.borderBottomLocation}`}>
              {" "}
              {locationInfo?.selectedStore?.name}{" "}
            </span>
          </>
        ) ?? <> </>
      );
    } else
      return (
        <div>
          {isItMobile ? <GeoLocationWhiteIcon /> : <LocationOutlineIcon />}{" "}
          <span className={`${styleClasses.borderBottomLocation}`}>
            {" "}
            find a cafe zupas{" "}
          </span>{" "}
        </div>
      );
  };

  useKeypress("Escape", () => {
    setShowPopupModal(false);
  });

  const loyaltyRedirectionLink = (item) => {
    return userType === CONSTANT.GUEST_USER || user?.logged_out
      ? item.link
      : item.alternateURL.exist
      ? item.alternateURL.link
      : item.link;
  };

  const HandleShowMegaMenu = () => {
    setOpenMegaMenu(true);
    setTimeout(() => {
      setMegaMenuAnimateClass("opacity-100");
    }, 0);
  };

  const HandleHideMegaMenu = () => {
    setMegaMenuAnimateClass("opacity-0");
    setTimeout(() => {
      setOpenMegaMenu(false);
    }, 300);
  };
  const [showPopupModal, setShowPopupModal] = useState(false);
  const handleClosePopupModal = () => setShowPopupModal(false);
  const showLoyaltyBanner =
    process.env.REACT_APP_SHOW_LOYALTY_BANNER === "true" ? true : false;

  return (
    <>
      <Helmet>
        <script>
          {`
              gtag('set', { 'user_id': ${authInfo?.userId} } );
              document.getElementById("cart_button").addEventListener("click", function () {
                      gtag("event", "view_cart", {
                        user_id: ${authInfo?.userId},
                        currency: "USD",
                        value: ${cartSubTotal(Cart?.items)},
                        items: ${JSON.stringify(
                          getStructuredItemList(
                            Cart?.items,
                            locationInfo?.selectedStore?.id
                          )
                        )}
                    });
              });   
              `}
        </script>
      </Helmet>

      <nav
        className={`${styleClasses.headerScrollAnimation}  ${
          onScrollNavHide ? "onScrollHeaderHide" : "onScrollHeaderVisible"
        }`}
      >
        <HeaderTop
          locationLabel={locationLabel}
          handleLocationButton={handleLocationButton}
          userType={userType}
          shouldShowLiveTracker={shouldShowLiveTracker}
          handleShowLiveTracker={handleShowLiveTracker}
        />
        <div
          className={`${styleClasses.wrapper_tracking_bar} wrapper_tracking_bar_app nav-menu-container-wrap `}
          id="header_wrap"
        >
          <Container
            id="header_container"
            fluid
            className={`${styleClasses.nav_wrapper_main_menu} ${stickyClass} ${styleClasses.fixed} nav-wrapper-mob`}
          >
            <Row className={styleClasses.header_row}>
              <Col
                xs="12"
                className={`${styleClasses.header_logo_wrap} d-flex justify-content-between flex-md-column`}
              >
                {/* Mene Links Wrap */}

                {!isItMobile ? (
                  <div className={`${styleClasses.mainHeaderWrapper} px-4 `}>
                    <a className={styleClasses.logo} href={ROUTE_HOME}>
                      <Image
                        className="sticky-logo"
                        source={`${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Header/logo.png`}
                        alt="sticky logo"
                      />
                    </a>
                    <div className={styleClasses.headerInnerDiv}>
                      <ul className="mb-0 d-flex align-items-center">
                        <div
                          onMouseOver={HandleShowMegaMenu}
                          onMouseLeave={HandleHideMegaMenu}
                        >
                          <NavLink
                            className={`${styleClasses.headerNavLinks} header-menu-font`}
                            to={CONSTANT.ROUTE_MENU}
                          >
                            {"Menu"}{" "}
                            <span className={styleClasses.chevIconDiv}>
                              {" "}
                              <ChevronIcon />{" "}
                            </span>
                          </NavLink>

                          {openMegaMenu && (
                            <MegaMenuDropDown
                              HandleHideMegaMenu={HandleHideMegaMenu}
                              megaMenuAnimateClass={megaMenuAnimateClass}
                            />
                          )}
                        </div>
                  
                        {navLinksArray.map((item, i) => {
                          return (
                            <NavLink
                              className={`${styleClasses.headerNavLinks} header-menu-font`}
                              to={"" + loyaltyRedirectionLink(item)}
                              key={i}
                            >
                              {item.text}
                            </NavLink>
                          );
                        })}
                        <a
                          className={`${styleClasses.headerNavLinks} header-menu-font`}
                          href={CONSTANT.CATERING_CAFE_ZUPAS_URL}
                        >
                          {CONSTANT.CATERING_CAFE_ZUPAS}
                        </a>
                        <a
                          className={`${styleClasses.headerNavLinks} header-menu-font`}
                          onClick={() => {
                            history.push(CONSTANT.ROUTE_OUR_KITCHEN);
                          }}
                        >
                          OUR KITCHEN
                        </a>
                        <NavLink
                          to={CONSTANT.ROUTE_CAREERS}
                          className={`${styleClasses.headerNavLinks} header-menu-font`}
                        >
                          Careers
                        </NavLink>
                      </ul>

                      <div
                        className={`d-flex align-items-center ms-auto ${styleClasses.headerRight}`}
                      >
                        <button
                          type="button"
                          className={`${
                            styleClasses.locationNavSection +
                            " " +
                            (Cart.orderType == DELIVERY_ORDER_TYPE
                              ? styleClasses.online_location_icon
                              : "")
                          } d-flex justify-content-center align-items-center`}
                          onClick={handleLocationButton}
                        >
                          {locationLabel()}
                        </button>
                        {userType !== CONSTANT.GUEST_USER ? (
                          <UserName />
                        ) : (
                          <SignUpButton />
                        )}
                        <button
                          type="button"
                          className={`${styleClasses.addToCartBtn} font-Vcf d-flex align-items-end justify-content-center clr-dark-blue`}
                          onClick={handleShow}
                          id={"cart_button"}
                        >
                          {Cart.items?.length ? (
                            <CartBagItemAdded />
                          ) : (
                            <CartBagIcon />
                          )}

                          <span
                            className={Cart.items?.length && "clr-white-clr "}
                          >
                            {Cart.items?.length}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${styleClasses.header_menu_Wrapper} ${styleClasses.mainHeaderWrapper} d-flex justify-content-between align-items-center w-100  `}
                  >
                    <NavbarComponent
                      isHeader={true}
                      Navlinks={navLinksArray}
                      classes="clr-lt-black header-menu-font"
                      expand="md"
                      userId={userId}
                      shouldShowLiveTracker={shouldShowLiveTracker}
                      showLoyaltyBanner={showLoyaltyBanner}
                      ignorePopUpIdCheck={true}
                    ></NavbarComponent>
                    <a className={styleClasses.logo} href={ROUTE_HOME} aria-label="CZ Logo">
                      <CZLogo className="sticky-logo" />
                    </a>
                    <div
                      className={`d-flex align-items-center  ${styleClasses.headerRight}`}
                    >
                      <button
                        type="button"
                        className={`${styleClasses.addToCartBtn} font-Vcf d-flex align-items-end justify-content-center clr-dark-blue`}
                        onClick={handleShow}
                        id={"cart_button"}
                      >
                        {Cart.items?.length ? (
                          <CartBagItemAdded />
                        ) : (
                          <CartBagIcon />
                        )}

                        <span className={Cart.items?.length && "clr-white-clr"}>
                          {Cart.items?.length}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          {isItMobile ? (
            shouldShowLiveTracker ? (
              <div
                className={`${styleClasses.orderTrackBar} bg-clr-dark-blue w-100  d-flex justify-content-center  justify-content-md-end align-items-center ms-auto`}
                id="tracker-bar-exist"
              >
                <p className="pe-1 ps-2 fw-bold f-s14 mb-0 text-uppercase font-Visby-cf ls-1  clr-white-clr ">
                  Your Order Is In Progress
                </p>
                <button
                  className="f-s14  font-Visby-cf border-0 text-decoration-underline px-0 bg-transparent fw-bold lh-lg clr-white-clr"
                  onClick={handleShowLiveTracker}
                >
                  Track Here
                </button>
              </div>
            ) : null
          ) : null}
          {show ? (
            <CartModal
              addToOrder={addToOrder}
              showModal={show}
              closeModal={handleClose}
            />
          ) : null}
          {/* New Payment Method Modal */}
          <LocationInfoModal
            showModal={showLocationPopup}
            closeModal={closeLocationPopup}
          />
          {/* Redeems Rewards Modal */}
          <Modal
            show={show3}
            backdropClassName="rewards_redeem_modal_backdrop"
            centered
            className="theme-modal-style app_container_wrap rewards_redeem_modal"
          >
            <Modal.Body className="text-center">
              <h3 className="f-s36 font-Cls mb-3">Hang on!</h3>
              <p className="f-s14 font-rale mb-3">
                Make selections for your reward.
              </p>
              <div className={`${styleClasses.redeem_item_box} text-center`}>
                <img
                  src={require("../../images/redeem-item-img.png")}
                  className="img-fluid mb-3"
                  alt="redeem item"
                />
                <h4 className="f-s18 font-rale reset-text mb-3">Half Salad</h4>
                <h5 className="overline_text text-uppercase">
                  Use this reward by 9/17/21
                </h5>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-large dark-grey w-100 mb-2"
                >
                  CUSTOMIZE
                </button>
                <button
                  type="button"
                  className="btn btn-large dark-grey w-100 outline bg-white"
                  onClick={handleClose3}
                >
                  Save reward for later
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={openOrderModal}
            centered
            className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
            onHide={closeOpenOrderModal}
            size="sm"
          >
            <Modal.Header className="x-4 justify-content-center">
              <button
                type="button"
                className="btn modal-close-button pe-0 sm-blue-icon"
                onClick={closeOpenOrderModal}
              >
                Close
              </button>
            </Modal.Header>
            <Modal.Body>
              <OrderType
                itemPath={null}
                handleOrderTypeModal={closeOpenOrderModal}
              />
            </Modal.Body>
          </Modal>
          <Modal
            show={addressConfirmationModal.showModal}
            centered
            className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
            onHide={() => {
              !isAddingAddress &&
                setAddressConfirmationModal({
                  showCart: false,
                  showModal: false,
                });
            }}
            size="sm"
          >
            <Modal.Header className="x-4 justify-content-center">
              <button
                type="button"
                className="btn modal-close-button pe-0 sm-blue-icon"
                onClick={() => {
                  !isAddingAddress &&
                    setAddressConfirmationModal({
                      showCart: false,
                      showModal: false,
                    });
                }}
              >
                Close
              </button>
            </Modal.Header>
            <Modal.Body>
              <AddressConfirmationForUser
                closeModal={closeAddressConfirmationModal}
                addAddress={addAddress}
                isAddingAddress={isAddingAddress}
              />
            </Modal.Body>
          </Modal>

          <div className="LiveTracking">
            <Modal
              show={showLiveTracker}
              centered
              className="live_tracking_modal theme-modal-style app_container_wrap border-radius-15 px-5 full-modal-ipad"
              onHide={handleCloseLiveTracker}
              size="xl"
            >
              <Modal.Header className="x-4 justify-content-center">
                <button
                  type="button"
                  className="btn modal-close-button pe-0"
                  onClick={handleCloseLiveTracker}
                >
                  Close
                </button>
              </Modal.Header>
              <Modal.Body>
                {isItMobile && orderNo === null ? (
                  <>
                    <OrderDetailsMobile
                      setOrderNo={setOrderNo}
                      orders={placedOrders}
                      isFetchingOrders={isFetchingPlacedOrders}
                    />
                  </>
                ) : (
                  <Livetracking
                    selectedOrderNo={orderNo}
                    handleMobileOrderBackward={handleMobileOrderBackward}
                    orderIds={orderIds}
                    handleClose={handleCloseLiveTracker}
                    isFetchingOrders={isFetchingPlacedOrders}
                    titlespace={styleClasses.order_detail_live}
                    setIsActiveOrders={setIsActiveOrders}
                  />
                )}
              </Modal.Body>
            </Modal>
          </div>

          <Row>
            <Col xs="12" sm="12" md="6">
              <PopUpModel
                showPopup={showPopupModal}
                closePopup={handleClosePopupModal}
              />
            </Col>
          </Row>
        </div>
      </nav>
    </>
  );
};

export default Header;
