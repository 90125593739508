import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import {
    ASAP_TYPE,
    DELIVERY_ORDER_TYPE,
    NO_TIME_SLOTS_PRESENT, PICK_UP_METHOD_CURBSIDE,
    ROUTE_CHECKOUT,
    SELECTED_STORE
} from '../../../../constants';
import useAddPickupSlotMethod from '../../../../react-query-hooks/usePickupSlotMethod';
import {setShowCart, updateOrderDetails} from '../../../../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/store';
import { FormField } from '../../../FormFields/FormField';
import {useStoreLocation} from "../../../../context/StoreLocation";
import {timeSlotPayload} from "../../../../helpers/checkout/PlaceOrderHelper";
import styleClasses from '../../../../../src/components/ItemGrid/ItemGrid.module.scss';
import {selectPickupSlotSchema} from '../../../../validationSchemas/selectPickUpSlotSchema';
import {formatTime} from "../../../utils";
import Button from "../../../Buttons/Button";
import { ISelectInputState } from '../../../../models/forms.model';
import useTimeZone from '../../../../react-query-hooks/useTimeZone';
import {useAuthState} from "../../../../context/UserAuthentication";
import { brazeLogCustomEventCheckoutStarted } from '../../../../helpers/brazeHelper';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';

interface PickUpSlotsProps {
    subTotal?: number;
    tax?: number;
    total?: number;
    isShownVehicleDetails?: (boolean) => void;
    closePickUpMethodModal?: (boolean) => void;
    closeDeliveryMethodModal?: (boolean) => void;
    pickupMethod?: string;
    isEditSlot?: boolean
}

const PickUpSlots = (props: PickUpSlotsProps) => {
    const {tax, subTotal, total, isShownVehicleDetails , closePickUpMethodModal , closeDeliveryMethodModal, pickupMethod, isEditSlot} = props
    
    let queryClient = useQueryClient();
    let { mutateAsync: addPickupSlotMethod, isLoading: isFetchingSlots } = useAddPickupSlotMethod();
    const isItMobile = useCheckMobileScreen()
    const [timeSlotValidation , setTimeSlotValidation] = useState<string>('');
    const [disableContinue, setDisableContinue] = useState<boolean>(true);
    const [asapMinutes , setAsapMinutes] = useState<string>(null);
    const { authInfo } = useAuthState();
    const [dates, setDates] = useState({
      startDate: '',
      lastDate: ''
    });
    const [availablePickUpSlots, setAvailablePickUpSlots] = useState({
      availableTimeSlot: [],
      autoSlectedTimeSlot: ''
    });
    const cart = useAppSelector((state) => state.cart);
    const selectedPickupSlots = cart?.orderDetails?.PickupSlotDetails || "";
    const [timeSlot, setTimeSlot] = useState(isEditSlot ? selectedPickupSlots?.time : "" );
    const [ stateOfSlotsFetching, setStateOfSlotsFetching ] = useState<ISelectInputState>({state:false,text:null});

    const history = useHistory();
    const storeLocation = useStoreLocation();
    const orderTypeDispatch = useAppDispatch();
    const orderDetailsDeliveryAddress = cart?.orderDetails?.delivery_address || "";
    const vehicleDetails = cart?.orderDetails?.vehicleDetails || "";
    const orderType = cart?.orderType || "";
    const alreadySelectedDate = new Date(selectedPickupSlots?.date)
    const selectedLocationTimezone = JSON.parse(localStorage.getItem(SELECTED_STORE))?.time_zone
    const {data: dateTime, isFetching: isFetchingDateTime} = useTimeZone({timeZone: selectedLocationTimezone || ''});
    const currentDate = dateTime?.split(' ')?.[0]
    const currentDateTime = new Date(currentDate)
    const isSameDate = alreadySelectedDate?.getDate() === currentDateTime.getDate()
    const isApiFetching = isFetchingDateTime || isFetchingSlots
    useEffect(() => {
        if(!isEditSlot || !selectedPickupSlots?.time?.length)
        {
            addPickUpSlotsToCart()
        }
    }, [availablePickUpSlots])
    
    const [calenderDate, setCalenderDate] = useState(selectedPickupSlots ? selectedPickupSlots?.date : currentDate);
    const pickUpSlotsInit = {
      date : currentDate,
      time: '',
      pickupMethod: ( orderType === DELIVERY_ORDER_TYPE ? DELIVERY_ORDER_TYPE : pickupMethod ),
      isAsapOrder: false,
      asapMinutes: null
    }

    useEffect(() => {
      if ((!isSameDate && !isEditSlot) || !calenderDate ) {
        setCalenderDate(currentDate ?? "")
        orderTypeDispatch(updateOrderDetails({ 
          delivery_address: orderDetailsDeliveryAddress, 
          vehicleDetails : vehicleDetails,
          PickupSlotDetails: pickUpSlotsInit,
        }))
      }
    }, [dateTime])

    useEffect(() => {
      if (availablePickUpSlots.autoSlectedTimeSlot === NO_TIME_SLOTS_PRESENT) {
        orderTypeDispatch(
          updateOrderDetails({
              delivery_address: orderDetailsDeliveryAddress,
              vehicleDetails : vehicleDetails,
              PickupSlotDetails: pickUpSlotsInit,
          })
        );
        setDisableContinue(true);
        setAvailablePickUpSlots({
          ...availablePickUpSlots,
          autoSlectedTimeSlot: "",
        });
      }
    }, [availablePickUpSlots.autoSlectedTimeSlot, pickUpSlotsInit]);

    let initialFormState;
    if(isEditSlot)
    {
         initialFormState = { selected_slot: selectedPickupSlots?.time ? { label: formatTime(selectedPickupSlots.time, {time: selectedPickupSlots.time}), value: selectedPickupSlots.time }
                : availablePickUpSlots?.availableTimeSlot }
    }
    else {
        initialFormState = { selected_slot: availablePickUpSlots?.availableTimeSlot };
    }
    useEffect(() => {
      let startDate = currentDate;
      let lastDate = getLastDate();
      if (startDate && lastDate) {
        setDates({
          startDate: startDate,
          lastDate: lastDate,
        });

        if (!selectedPickupSlots && dates) {
          addPickUpSlotsToCart();
        }
      }
    }, [dates.startDate, dates.lastDate, dateTime]);
  

    useEffect(() => {
      if (dates.startDate) {
        handlePickUpSlots(selectedPickupSlots ? selectedPickupSlots.date : dates.startDate);
      }
    }, [dates.startDate, dateTime]);
  
    const getLastDate = () => {
      const week = currentDateTime
      const updated_date = week.setDate(week.getDate() + 6);
      const dateAfterWeek = new Date(updated_date);
      const day = dateAfterWeek.getUTCDate();
      const month = dateAfterWeek.getUTCMonth() + 1;
      const year = dateAfterWeek.getUTCFullYear();
      const lastdateStr = year + "-" + ( (('0' + month).slice(-2)) ) + "-" + ('0' + day).slice(-2);
  
      return lastdateStr;
    }
  
    const handleCalenderChange = (value: any) =>{
      setCalenderDate(value);
      handlePickUpSlots(value);
    }
    const addPickUpSlotsToCart = () => {
      let pickupSlotObj = {
            date: calenderDate,
            time: timeSlot || availablePickUpSlots.autoSlectedTimeSlot,
            pickupMethod: ( orderType == DELIVERY_ORDER_TYPE ? DELIVERY_ORDER_TYPE : pickupMethod ),
            isAsapOrder: timeSlot ? timeSlot?.includes(ASAP_TYPE) : availablePickUpSlots?.autoSlectedTimeSlot?.includes(ASAP_TYPE),
            asapMinutes: asapMinutes
          }
          orderTypeDispatch(updateOrderDetails(  { "delivery_address": orderDetailsDeliveryAddress , "PickupSlotDetails": pickupSlotObj , "vehicleDetails" : vehicleDetails }))
      return pickupSlotObj;
    }

    const handleFormSubmission = async () => {
        return addPickUpSlotsToCart();
    };

    const toggleContinueButton = (value) => {
      if(value){
        setTimeSlotValidation('No Time Slots Available');
        setDisableContinue(true);
      } else {
        setTimeSlotValidation('');
        setDisableContinue(false);
      }
    }

    const byDefaultSelectInputState = () => {
      setStateOfSlotsFetching({state:true,text:'No Option'})
    }

    const modifyAvailableSlots = (asapTime, slots) => {
      let availableSlots = []
      const today = new Date().toLocaleDateString("en-US", { timeZone: selectedLocationTimezone })
      let currentDate = new Date(today);
      currentDate.setSeconds(0)
      currentDate.setMinutes(currentDate.getMinutes()+ asapTime);
      slots.map((item, i)=>{
        if( i === 0 )  availableSlots.push(item)
        else {
          const launchDate = new Date(today +' ' + item);
          if(new Date(currentDate) <= new Date(launchDate)){
            availableSlots.push(item)
          }
        }
      })
      setAvailablePickUpSlots({
        availableTimeSlot: availableSlots?.map((item) => {
          return { label: formatTime(item, {time: item}), value: item };
        }),
        autoSlectedTimeSlot: availableSlots[0]
    });
    }
  
    const handlePickUpSlots = async (value: any) => {
      setStateOfSlotsFetching({state:true,text:'Loading...'});
      setDisableContinue(true)
      let modifiedValues = {
        location_id: storeLocation.locationInfo.selectedStore.id.toString(),
        date: value || dates?.startDate,
          customer_id: authInfo.userId,
        ...timeSlotPayload(cart)
      };
      let mutate = addPickupSlotMethod;
      return mutate(
        {
          newPickupSlotMethod: modifiedValues,
        },
        {
          onSuccess: (data, variables, context) => {
            if((data?.available_slots != "" && data?.available_slots != NO_TIME_SLOTS_PRESENT) || data?.asap_time){
              byDefaultSelectInputState()
              if(data?.asap_time){
                setAsapMinutes(data.asap_time)
                data?.available_slots.unshift('ASAP: ' + data?.asap_time + ' minutes')
              }
              if( data?.asap_time && orderType == DELIVERY_ORDER_TYPE){
                modifyAvailableSlots(data?.asap_time, data?.available_slots)
              } else {
                setAvailablePickUpSlots({
                  availableTimeSlot: data?.available_slots?.map((item) => {
                    return { label: formatTime(item, {time: item}), value: item };
                  }),
                  autoSlectedTimeSlot: data?.available_slots[0]
              });
              }
              queryClient.invalidateQueries("pickupSlotMethod");
              toggleContinueButton(false)
        
            } else {
              setAvailablePickUpSlots({
                availableTimeSlot: [],
                autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT
              });
              setStateOfSlotsFetching({state:true,text:'No Time Slots Available!'})
              toggleContinueButton(true)
          
            }
            
          },
          onError: (error: any, variables, context) => {
            setAvailablePickUpSlots({
              availableTimeSlot: [],
              autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT
            });
            setStateOfSlotsFetching({state:true,text:'No Time Slots Available!'})
            toggleContinueButton(true)
          },
        }
      );
    };
  
    const handleNextButton = async () => {
        // queryClient.refetchQueries(["get-cart", selectedLocation.id]);
      if (timeSlotValidation == "") {
        if (orderType == DELIVERY_ORDER_TYPE) {
          //Case: Delievery Method
          const slotDetails = await handleFormSubmission();
          closeDeliveryMethodModal(true);
          orderTypeDispatch(setShowCart(false));
          brazeLogCustomEventCheckoutStarted(cart, {tax, subTotal, total}, isItMobile, slotDetails)
          history.push(ROUTE_CHECKOUT);
        } else {
          if (pickupMethod === PICK_UP_METHOD_CURBSIDE) {
            // Case: CurbSide Pickup Method
            isShownVehicleDetails(true);
            handleFormSubmission();
          } else {
            //Case: InStore Pickup Method
            const slotDetails = await handleFormSubmission();
            closePickUpMethodModal(true);
            orderTypeDispatch(setShowCart(false));
            brazeLogCustomEventCheckoutStarted(cart, {tax, subTotal, total}, isItMobile, slotDetails)
            history.push(ROUTE_CHECKOUT);
          }
        }
      } else {
        setAvailablePickUpSlots({
          availableTimeSlot: [],
          autoSlectedTimeSlot: NO_TIME_SLOTS_PRESENT,
        });
        toggleContinueButton(true)
      }
    };

  return (
    <div className='mt-4 pt-3'>
    <Formik
          initialValues={initialFormState}
          onSubmit={handleFormSubmission}
          validationSchema={selectPickupSlotSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitForm,
          }) => (
            <>

              <form
                className="new_form_design w-75 my-0 mx-auto delivery_location_select full_width_mob show_datepick_calender"
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <label className="d-flex justify-content-between">
                    Select Order Date
                  </label>
                  
                  <div className="position-relative">
                    <input
                      name="date"
                      type="date"
                      required
                      disabled={isApiFetching}
                      min={dates?.startDate}
                      max={dates?.lastDate}
                      value={calenderDate}
                      className={`${styleClasses.input_order_date} form-control no-style type-data position-relative`}
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(e) => {
                        setFieldValue("selected_slot", NO_TIME_SLOTS_PRESENT)
                        setTimeSlot("")
                        handleCalenderChange(e.target.value);
                      }}
                    />
                    <span
                      className={styleClasses.date_icon_container}
                      data-testid="icon-container"
                    >
                      <img
                        src={require("../../../../../src/images/icn_calendar.svg")}
                        alt="calender icon"
                       
                      />
                    </span>
                  </div>
                </div>
                
                <FormField
                
                  options={availablePickUpSlots.availableTimeSlot}
                  type={"select"}
                  name="selected_slot"
                  labelText={orderType == DELIVERY_ORDER_TYPE ? "Select a Delivery Time" : "Select a Pick-Up Time"}
                  placeholder={ timeSlotValidation ? NO_TIME_SLOTS_PRESENT : (availablePickUpSlots?.autoSlectedTimeSlot ? formatTime(availablePickUpSlots?.autoSlectedTimeSlot, {time: availablePickUpSlots?.autoSlectedTimeSlot}) :  NO_TIME_SLOTS_PRESENT )}
                  errors={errors}
                  touched={touched}
                  value= { timeSlotValidation ? "00:00:00" :  values.selected_slot }
                  onChange={(slot) => {
                    setFieldValue("selected_slot", slot)
                    if(slot)
                    {
                      setTimeSlot(slot.value)
                    }
                  }}
                  inputFieldClass={"custom_select mb-0"}
                  handleLoading={{enable: stateOfSlotsFetching.state, inputValue: stateOfSlotsFetching.text}}
                />
                <div className="justify-start-center mt-4">
                  <span className="d-block f-s14 font-Visby-cf f-w5">{storeLocation.locationInfo.selectedStore.name}</span>
                  <span className="d-block f-s14 font-Visby-cf f-w5">{storeLocation.locationInfo.selectedStore.address}</span>
                  <span className="d-block f-s14 font-Visby-cf f-w5">{storeLocation.locationInfo.selectedStore.city}, {storeLocation.locationInfo.selectedStore.state} {storeLocation.locationInfo.selectedStore.zip}</span>
                </div>
                {
                timeSlotValidation && <p className='text-center my-3 f-s14 w-75 mx-auto full_width_mob px-4 clr-dark-red'>{timeSlotValidation}</p>
                }
                <Button
                  disabled={disableContinue || isApiFetching || !!timeSlotValidation}
                  loading={isApiFetching}
                  type="button"
                  id="continue-checkout"
                  className="btn btn-large py-2 f-s16 w-100 mb-3 mt-4 lh-lg"
                  onClick={() => {
                    handleNextButton();
                  }}
                >
                  Continue
                </Button>
              </form>
           
            </>
          )}
        </Formik>
    </div>
  )
}

export default PickUpSlots