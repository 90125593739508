import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CANCELLED, FAILED, PRINT_RECEIPT } from "../../../constants";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { useDispatch } from "react-redux";
import { setShowCart, updateCartOrder } from "../../../redux/slices/cartSlice";
import { Toast_Func } from "../../../helpers/toast.helper";
import { IGetCartPayload } from "../../../models/order.model";
import { useAuthState } from "../../../context/UserAuthentication";
import { orderMicroService } from "../../../services";
import {
  capitalizeFirstLetter,
  capitalizeSentence,
  getVisitorId,
} from "../../../helpers/helperMethods";
import { getCart } from "../../../react-query-hooks/Cart/useGetCartItems";
import { useStoreLocation } from "../../../context/StoreLocation";
import ConfirmReorderModal from "./ConfirmReorderModal";
import useReorder from "../../../react-query-hooks/useReorder";
import { Badge, Modal } from "react-bootstrap";
import OrderType from "../../ItemGrid/OrderType";
import { useAppSelector } from "../../../redux/store/store";
import { AddIcon, MinusIcon } from "../../../images/svgIcons/svg";
import FavoriteButton from "../../../containers/try2/Customization/FavoriteButton";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { brazeLogCustomEventReOrder } from "../../../helpers/brazeHelper";

export const OrderList = ({
  recentOrders,
  showFavoriteModal,
  is_favorite_order,
}) => {
  let { mutate: reorderCart, isLoading: reorderLoader,data } = useReorder();
  const { authInfo } = useAuthState();

  const [order, setOrder] = useState(null);
  const [showMore, setShowMore] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemToOrder, setItemToOrder] = useState(null);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const { locationInfo } = useStoreLocation();
  const closeOpenOrderModal = () => {
    setOpenOrderModal(false);
  };
  const isOrderTypeSelected = useAppSelector(
    (state) => state.cart.isOrderTypeSelected
  );

  const isItMobile = useCheckMobileScreen();
  const location_id = locationInfo.selectedStore?.id;
  const dispatch = useDispatch();

  const checkIfCartExists = async () => {
    const cartInfo = await orderMicroService.getCartInfo(authInfo.userId);
    const cartInfoData = cartInfo?.data?.data;
    if (!cartInfoData || cartInfoData.length === 0) return false;
    return cartInfoData[0].items_count > 0;
  };

  const reorder = async (order: any) => {
    reorderCart(order?.id, {
      onSuccess: (response) => {
        brazeLogCustomEventReOrder({...response,favorite_order_name:order.favorite_order_name },is_favorite_order,isItMobile)
        dispatch(setShowCart(true));
        setOrder(null);
        setShowConfirmModal(false);
        setLoading(false);
      },
      onError: (error) => {
        setOrder(null);
        setShowConfirmModal(false);
        setLoading(false);
      },
    });
  };

  const handleOrderAgain = async (order) => {

    if (loading) return;

    if (isOrderTypeSelected) {
      setLoading(true);
      setOrder(order);
      const doesCartExist = await checkIfCartExists();
      if (doesCartExist) {
        setShowConfirmModal(true);
      } else {
        reorder(order);
      }
    } else {
      setOpenOrderModal(true);
    }

  };

  const closeModal = () => {
    setShowConfirmModal(false);
    setLoading(false);
    setOrder(false);
  };

  const handleShowMoreBtn = (itemId) => {
    setShowMore((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const convertBusinessDate = (businessDate: string) => {
    const splitBusinessDate = businessDate.split("-");
    return `${splitBusinessDate[1]}/${splitBusinessDate[2]}/${splitBusinessDate[0]}`;
  };

  const badge = (text: string) => {
    return (
      <Badge
        className="f-w5 f-s12 ms-md-2 font-rale mb-1 mt-2"
        pill
        bg="danger"
      >
        {text}
      </Badge>
    );
  };

  return (
    <ul className="list-inline text-start mb-3 ps-0 pe-0">
      {recentOrders?.map((page) =>
        page?.data?.map((order) =>
          is_favorite_order ? (
            order?.is_favorite ? (
              <li
                key={order.id}
                className={`${styleClasses.recent_order_list} w-100`}
              >
                <div className={`d-flex justify-content-between w-100`}>
                  <div className={styleClasses.recent_order_content}>
                    { order.favorite_order_name && (
                      <h4 className="font-Cls f-s18 lh-normal fw-5 clr-dark-blue text-nowrap">
                        {order.favorite_order_name}
                      </h4>
                    )}
                    <span className="font-Visby-cf h-17 f-s14 mb-0 d-block">
                      {convertBusinessDate(order.business_date)} -{" "}
                      {order.platform}
                    </span>
                    <div className="font-Cls f-s18 h-22 fw-light">
                      {order.items
                        ? order.items
                            ?.slice(
                              0,
                              showMore[order.id] ? order.items.length : 3
                            )
                            .map((item) => (
                              <>
                                <p
                                  key={item.id}
                                  className={`${styleClasses.account_order_title} font-Cls f-s20 f-sm-s18 h-28 fw-light mb-0 mt-3`}
                                >
                                  {capitalizeSentence(item.name)}{" "}
                                  {item.size ? `(${item.size})` : ""}
                                </p>

                                <ul className="ps-2 pt-0">
                                  {item.modifiers?.map((modifier) => {
                                    return (
                                      <li
                                        className="font-rale f-s14 fw-light clr-text-grey lh-24"
                                        key={modifier.id}
                                      >
                                        {modifier.quantity ===
                                        1 ? null : modifier?.quantity === 0 ? (
                                          "No"
                                        ) : (
                                          <span>{`${modifier.quantity} x`}</span>
                                        )}{" "}
                                        {capitalizeSentence(
                                          modifier?.modifier_name
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </>
                            ))
                        : order.item_name}
                    </div>
                    {order?.items?.length > 3 && (
                      <button
                        className={styleClasses.viewLessBtn}
                        onClick={() => {
                          handleShowMoreBtn(order.id);
                        }}
                      >
                        {!showMore[order.id] ? (
                          <>
                            {" "}
                            <AddIcon /> View More
                          </>
                        ) : (
                          <>
                            {" "}
                            <MinusIcon /> View Less{" "}
                          </>
                        )}
                      </button>
                    )}
                  </div>
                  <div
                    className={`d-flex align-items-start gap-10 ${styleClasses.recent_order_btns}`}
                  >
                    <FavoriteButton
                      favIcon={true}
                      buttonHandler={(e, item) => showFavoriteModal(item)}
                      className="d-block d-lg-block position-static ms-auto bg-transparent p-0 favorite-icon-blue"
                      item={order}
                      favourite={order.is_favorite}
                      fromOrder={true}
                      isIconblueFill = {true}
                    />
                    {order.show_receipt ? (
                      <Link
                        to={{
                          pathname: PRINT_RECEIPT,
                          hash: order.id.toString(),
                        }}
                        target="_blank"
                        className="btn btn-custom f-s14 f-w6 flex-md-row flex-row-reverse font-Visby-cf color-sky-blue-light ms-3 p-0 d-flex align-items-center gap-md-2 gap-0 me-2 me-md-0"
                      >
                        <img
                          src={require("../../../../src/images/Receipt.svg")}
                          alt="receipt"
                        />{" "}
                        {isItMobile ? null : "Receipt"}
                      </Link>
                    ) : null}
                    {order.reorder ? (
                      <button
                        className="  font-Visby-cf f-s14  ms-3   gap-0 f-w6 d-none d-md-block "
                        onClick={() => handleOrderAgain(order)}
                      >
                        Order Again
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className={`d-flex ${styleClasses.recent_order_btns}`}>
                  {order?.is_cancelled
                    ? badge(capitalizeFirstLetter(CANCELLED))
                    : null}{" "}
                  {order?.is_failed
                    ? badge(capitalizeFirstLetter(FAILED))
                    : null}
                  {order.points && !order.is_cancelled && !order.is_failed ? (
                    <div className="f-s18 font-Visby-cf color-sky-blue-light d-flex align-items-center gap-1">
                      <img
                        src={require("../../../../src/images/starVector.svg")}
                        alt="star vector"
                      />
                      {`${order.points} points`}
                    </div>
                  ) : null}
                  {order.reorder ? (
                    <button
                      className="  font-Visby-cf f-s14  ms-auto    f-w6 d-md-none"
                      onClick={() => handleOrderAgain(order)}
                    >
                      Order Again
                    </button>
                  ) : null}
                </div>
              </li>
            ) : null
          ) : (
            <li
              key={order.id}
              className={`${styleClasses.recent_order_list} w-100`}
            >
              <div className={`d-flex justify-content-between w-100`}>
                <div className={styleClasses.recent_order_content}>
                  { order.favorite_order_name && (
                    <h2 className="font-Cls f-s18 lh-normal fw-5 clr-dark-blue text-nowrap">{order.favorite_order_name}</h2>
                  )}
                  <span className="font-Visby-cf h-17 f-s14 mb-0 d-block">
                    {convertBusinessDate(order.business_date)} -{" "}
                    {order.platform}
                  </span>
                  <div className="font-Cls f-s18 h-22 fw-light">
                    {order.items
                      ? order.items
                          ?.slice(
                            0,
                            showMore[order.id] ? order.items.length : 3
                          )
                          .map((item) => (
                            <>
                              <p
                                key={item.id}
                                className={`${styleClasses.account_order_title} font-Cls f-s20 f-sm-s18 h-28 fw-light mb-0 mt-3`}
                              >
                                {capitalizeSentence(item.name)}{" "}
                                {item.size ? `(${item.size})` : ""}
                              </p>

                              <ul className="ps-2 pt-0">
                                {item.modifiers.map((modifier) => {
                                  return (
                                    <li
                                      className="font-rale f-s14 fw-light clr-text-grey lh-24"
                                      key={modifier.id}
                                    >
                                      {modifier.quantity ===
                                      1 ? null : modifier?.quantity === 0 ? (
                                        "No"
                                      ) : (
                                        <span>{`${modifier.quantity} x`}</span>
                                      )}{" "}
                                      {capitalizeSentence(
                                        modifier?.modifier_name
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ))
                      : order.item_name}
                  </div>
                  {order.items.length > 3 && (
                    <button
                      className={styleClasses.viewLessBtn}
                      onClick={() => {
                        handleShowMoreBtn(order.id);
                      }}
                    >
                      {!showMore[order.id] ? (
                        <>
                          {" "}
                          <AddIcon /> View More
                        </>
                      ) : (
                        <>
                          {" "}
                          <MinusIcon /> View Less{" "}
                        </>
                      )}
                    </button>
                  )}
                </div>
                <div
                  className={`d-flex align-items-start gap-10 ${styleClasses.recent_order_btns}`}
                >
                  <FavoriteButton
                      favIcon={true}
                      buttonHandler={(e, item) => showFavoriteModal(item)}
                      className="d-block d-lg-block position-static ms-auto bg-transparent p-0 favorite-icon-blue"
                      item={order}
                      favourite={order.is_favorite}
                      fromOrder={true} isIconblueFill={true}                  />
                  {order.show_receipt ? (
                    <Link
                      to={{
                        pathname: PRINT_RECEIPT,
                        hash: order.id.toString(),
                      }}
                      target="_blank"
                      className="btn btn-custom f-s14 f-w6 flex-md-row flex-row-reverse font-Visby-cf color-sky-blue-light ms-3 p-0 d-flex align-items-center gap-md-2 gap-0 me-2 me-md-0"
                    >
                      <img
                        src={require("../../../../src/images/Receipt.svg")}
                        alt="receipt"
                      />{" "}
                      {isItMobile ? null : "Receipt"}
                    </Link>
                  ) : null}
                  {order.reorder ? (
                    <button
                      className="  font-Visby-cf f-s14  ms-3   gap-0 f-w6 d-none d-md-block "
                      onClick={() => handleOrderAgain(order)}
                    >
                      Order Again
                    </button>
                  ) : null}
                </div>
              
              </div>
              <div className={`d-flex ${styleClasses.recent_order_btns}`}>
                {order?.is_cancelled
                  ? badge(capitalizeFirstLetter(CANCELLED))
                  : null}{" "}
                {order?.is_failed ? badge(capitalizeFirstLetter(FAILED)) : null}
                {order.points && !order.is_cancelled && !order.is_failed ? (
                  <div className="f-s18 font-Visby-cf color-sky-blue-light d-flex align-items-center gap-1">
                    <img
                      src={require("../../../../src/images/starVector.svg")}
                      alt="star vector"
                    />
                    {`${order.points} points`}
                  </div>
                ) : null}
                {order.reorder ? (
                  <button
                    className="  font-Visby-cf f-s14  ms-auto    f-w6 d-md-none"
                    onClick={() => handleOrderAgain(order)}
                  >
                    Order Again
                  </button>
                ) : null}
              </div>
            </li>
          )
        )
      )}
      <ConfirmReorderModal
        showModal={showConfirmModal}
        handleClose={closeModal}
        loading={reorderLoader}
        confirmChange={() => reorder(order)}
      />
      <Modal
        show={openOrderModal}
        centered
        className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
        onHide={closeOpenOrderModal}
        size="sm"
      >
        <Modal.Header className="x-4 justify-content-center">
          <button
            type="button"
            className="btn modal-close-button pe-0 sm-blue-icon"
            onClick={closeOpenOrderModal}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body>
          <OrderType
            itemPath={itemToOrder}
            handleOrderTypeModal={closeOpenOrderModal}
          />
        </Modal.Body>
      </Modal>
    </ul>
  );
};
