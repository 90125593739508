import { createSlice } from '@reduxjs/toolkit';
import {
  categoryObject,
  complementaryObject,
  coreIngredientSelectionHelper,
  ingredientSelectionHelper,
  ingredientSubstitutionHelper,
  itemExtraPrice,
  itemSize,
  price,
  itemIngredientSelectionHelper,
  itemExtraPriceWhileAdding
} from '../utility';
import { Codes } from '../../models/item.model';
import { COMPLEMENTARY_ITEM } from '../../constants';
import { toFixedNumber } from '../../priceCalculation/helper';
const initialState = {
  size: null,
  modifiers: [],
  item_id: null,
  id: null,
  name: null,
  display_price: null,
  original_price: null,
  item_calories: null,
  reRender: false,
  makeAnItemTry2Combo:{
    bucket: null,
    item: null,
    order: null
  },
  required_modifiers: [],
  other_modifiers: [],
  complimentary_modifier: [],
  core_modifiers: []
};

export const itemCustomizationSlice = createSlice({
  name: 'itemCustomization',
  initialState,
  reducers: {
    updatePriceForItem: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let calories = 0;
      const { itemId, activeBucket } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === itemId.toString()) {
          toFixedNumber(itemModifiers[i].price -= activeBucket.originalPrice);
          toFixedNumber(itemModifiers[i].price += activeBucket.additionalPrice);
        }
      }      
      state.display_price = toFixedNumber(state.display_price - activeBucket.originalPrice);
      state.display_price = toFixedNumber(state.display_price + activeBucket.additionalPrice);
      activeBucket.originalPrice = activeBucket.additionalPrice;
    },
    updatePriceForAllItem: (state, action) => {
      let itemModifiers = [...state.modifiers];
      action.payload.forEach(element => {
      const { itemId, activeBucket } = element;
      if( activeBucket === undefined ) return;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === itemId.toString()) {
          toFixedNumber(itemModifiers[i].price -= activeBucket.originalPrice);
          toFixedNumber(itemModifiers[i].price += activeBucket.additionalPrice);
        }
      }
      if( element.isCustomize ) state.display_price = toFixedNumber(state.display_price - activeBucket.originalPrice);
      state.display_price = toFixedNumber(state.display_price + activeBucket.additionalPrice);
      activeBucket.originalPrice = activeBucket.additionalPrice;
    });

    },
    makeAnItemToTry2Combo : (state,action) => {
      const {bucket, item, order} = action.payload;
      state.makeAnItemTry2Combo = {
        bucket,item, order
      }
    },
    updateTry2ComboSelectedModifiers: (state, action) => {
      const {itemModifiers, itemId} = action.payload;
      const items = state.modifiers;
      for (let i = 0; i < items.length; i++) {
        if(items[i]?.item === itemId.toString()){
          items[i].modifiers = itemModifiers;
        }
      }
    },
    updateModifiers: (state, action) => {
      const items = [...state.modifiers];
      action.payload.forEach(item => {
        const {itemModifiers, itemId} = item;
        for (let i = 0; i < items.length; i++) {
          if(items[i]?.item === itemId.toString()){
            items[i].modifiers = itemModifiers;
          }
        }
      });
      state.modifiers = [...items]
    },
    resetComboItemModifiersByItemId: (state, action) => {
      const { itemId } = action.payload;
      state.modifiers[itemId].modifiers = []
    },
    removeTemporarilyAddedPriceForItem: (state, action) => {
      const { price, item } = action.payload;
      // 
      let itemModifiers = [...state.modifiers];
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === item.toString()) {
          itemModifiers[i].price = toFixedNumber(itemModifiers[i].price - price);
        }
      }
      state.display_price = toFixedNumber(state.display_price - price);
      state.reRender = !state.reRender
    },
    addItem: (state, action) => {
      const { id, name, price, calories, half_brink_item_id } = action.payload;
      return {
        ...state,
        modifiers: [
          categoryObject('1'),
          complementaryObject(COMPLEMENTARY_ITEM),
        ],
        item_id: id,
        name: name,
        display_price: 0,
        original_price:0,
        brink_id: half_brink_item_id,
        item_calories: calories,
      };
    },
    editItem: (state, action) => {
      return {
        ...state,
        ...action.payload,
        size: action.payload.item_size,  
        modifiers: state.modifiers,
        display_price: 0,
        original_price:0,
      };
    },
    sizeSelection: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    ingredientSelection: (state, action) => {
      const selectedModifiers = [...state.modifiers];
      const modifiers = ingredientSelectionHelper({
        selectedModifiers,
        payload: action.payload,
      });
      state.modifiers = modifiers;
    },
    substituteSelection: (state, action) => {
      const obj = ingredientSubstitutionHelper({
        initialState: state,
        payload: { ...action.payload },
      });
      //Update All State One By One
      let keys = Object.keys(obj);
      keys.map((key) => {
        state[key] = obj[key];
      });
    },
    syncIngredientSelection: (state, action) => {
      const { modifiers } = action.payload;
      return { ...state, modifiers: [...modifiers] };
    },
     addTry2ComboItem: (state, action) => {
      const { id, name, price, half_brink_item_id } = action.payload;
      return {
        ...state,
        modifiers: [
          categoryObject('1'),
          categoryObject('2'),
          complementaryObject(COMPLEMENTARY_ITEM),
        ],
        item_id: id,
        name: name,
        display_price: price,
        original_price:price,
        code: Codes.ADD,
        brink_id: half_brink_item_id,
        id: null
      };
    },
    addKidsComboItem: (state, action) => {
      const { id, name, price, half_brink_item_id } = action.payload;
      return {
        ...state,
        modifiers: [
          categoryObject('1'),
          categoryObject('2'),
          categoryObject('3'),
          complementaryObject(COMPLEMENTARY_ITEM),
        ],
        item_id: id,
        name: name,
        display_price: price,
        original_price:price,
        code: Codes.ADD,
        brink_id: half_brink_item_id,
      };
    },
    editTry2ComboItem: (state, action) => {
      const { id, modifiers } = action.payload;
      state.id = id
      modifiers.forEach((modifier, i: number) => {
        if(modifier.is_item){
          state.modifiers[i] = {
            item: (i+1).toString(),
            id: modifier.self_category_id,
            modifier_id: modifier.modifier_id,
            item_id: modifier.self_category_id,
            modifier_name: modifier.self_category_name,
            name: modifier.self_category_name,
            modifier_size: modifier.modifier_size,
            quantity: modifier.quantity,
            category_id: modifier.category_id,
            is_selected: true,
            code: "Add",
            size: modifier.modifier_size,
          }
        }
      });
    },
    makeAnItemTry2Combo: (state) => {
      let itemModifiers = [...state.modifiers];
      const {bucket:items, order, item } = state.makeAnItemTry2Combo;
      itemModifiers[0].modifiers = order?.modifiers[0]?.modifiers
      itemModifiers[0].price = order?.modifiers[0]?.price
      itemModifiers[0].basePrice = item?.basePrice
      itemModifiers[0].category_id = order?.modifiers[0]?.category_id
      state.display_price = toFixedNumber(state.display_price + order?.modifiers[0]?.price); 
      state.modifiers = itemModifiers;
    },
    try2ComboIngredientSelection: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let modifiers = null;
      let selectedModifiers = null;
      const { data, sectionSelected } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedModifiers = itemModifiers[i].modifiers;
          modifiers = itemIngredientSelectionHelper({
            selectedModifiers,
            payload: data,
          });
          selectedModifiers = modifiers;
        }
      }
      state.modifiers = itemModifiers;
    },
    try2ComboCoreIngredientSelection: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let modifiers = null;
      let selectedModifiers = null;
      const { data, sectionSelected } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedModifiers = itemModifiers[i].modifiers;
          modifiers = coreIngredientSelectionHelper({
            selectedModifiers,
            payload: data,
          });
          selectedModifiers = modifiers;
        }
      }
      state.modifiers = itemModifiers;
    },
    try2ComboComplementaryItemsSelection: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let modifiers = null;
      let selectedModifiers = null;
      const { data } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === COMPLEMENTARY_ITEM) {
          selectedModifiers = itemModifiers[i].modifiers;
          modifiers = itemIngredientSelectionHelper({
            selectedModifiers,
            payload: data,
          });
          selectedModifiers = modifiers;
        }
      }
      state.modifiers = itemModifiers;
    },
    addTry2ComboSectionItem: (state, action) => {
      const selectedItemIndex = state.modifiers.findIndex(
        (mod) => mod.section == action.payload.section
      );
      selectedItemIndex !== -1 && state.modifiers.splice(selectedItemIndex, 1);
      const selectedModifiers = state.modifiers.filter(
        (mod) => mod.parent_id != action.payload.parent_id
      );

      selectedModifiers.push({
        ...action.payload,
      });
      return {
        ...state,
        modifiers: selectedModifiers,
      };
    },
    try2ComboCategorySelection: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let selectedItem = null;
      const { selected, sectionSelected } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.name = selected.name;
          selectedItem.basePrice = selected.base;
          selectedItem.category_id = selected.id;
          selectedItem.size = itemSize(selected.half_loyalty_label)
        }
      }
      state.modifiers = itemModifiers;
    },
    editTry2ComboItemSelection: (state, action) => {
      state.id = action.payload?.id ?? null
      let itemModifiers = [...state.modifiers];
      let selectedItem = null;
      let display_price = state.display_price;
      if(itemModifiers[2] && itemModifiers[2].price){
        display_price = state.display_price - itemModifiers[2].price
      }
      const { sectionSelected, item, category, itemName } = action.payload;
      const itemPrice = item?.half_brink_item_price ? item?.half_brink_item_price : item?.price
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.category_id = item.category_id
          selectedItem.is_selected = true;
          selectedItem.id = item?.id;
          selectedItem.item_id = item?.id;
          selectedItem.modifier_id = item?.id;
          selectedItem.name = item?.name;
          selectedItem.modifier_name = item?.name;
          selectedItem.size = itemSize(item?.half_brink_item_id);
          selectedItem.code="Add"
          selectedItem.price = itemModifiers[i]?.item === COMPLEMENTARY_ITEM ? 0 : itemExtraPriceWhileAdding ({basePrice:category?.base, selectedItemPrice:itemPrice, item:itemModifiers[i]?.item, itemName});
          state.display_price = toFixedNumber(state.display_price + itemExtraPrice(category?.base, itemPrice ))
        }
      }
      state.modifiers = itemModifiers;
    },
    try2ComboItemSelection: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let selectedItem = null;
      let display_price = state.display_price;
      if(itemModifiers[2] && itemModifiers[2].price){
        display_price = state.display_price - itemModifiers[2].price
      }
      const { sectionSelected, item, itemName } = action.payload;
      const itemPrice = item?.half_brink_item_price ? item?.half_brink_item_price : item?.price
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.is_selected = true;
          selectedItem.id = item?.id;
          selectedItem.item_id = item?.id;
          selectedItem.modifier_id = item?.id;
          selectedItem.name = item?.name;
          selectedItem.modifier_name = item?.name;
          selectedItem.size = itemSize(item?.half_brink_item_id);
          selectedItem.code="Add"
          selectedItem.price = itemModifiers[i]?.item === COMPLEMENTARY_ITEM ? 0 : itemExtraPriceWhileAdding ({basePrice:itemModifiers[i]?.basePrice, selectedItemPrice:itemPrice, item:itemModifiers[i]?.item, itemName});
          state.display_price = toFixedNumber(state.display_price + itemExtraPrice(itemModifiers[i]?.basePrice, itemPrice ))
        }
      }
      state.modifiers = itemModifiers;
    },
    kidsComboItemSelection: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let selectedItem = null;
      let display_price = state.display_price;
      if(itemModifiers[3] && itemModifiers[3].price){
        display_price = state.display_price - itemModifiers[3].price
      }
      const { selectedItemId, item, itemName } = action.payload;
      const itemPrice = item?.half_brink_item_price ? item?.half_brink_item_price : item?.price
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === selectedItemId) {
          selectedItem = itemModifiers[i];
          selectedItem.is_selected = true;
          selectedItem.id = item?.id;
          selectedItem.item_id = item?.id;
          selectedItem.modifier_id = item?.id;
          selectedItem.modifier_group_id = item?.modifier_group_id;
          selectedItem.name = item?.name;
          selectedItem.modifier_name = item?.name;
          selectedItem.code = Codes.ADD
          selectedItem.calories = item?.calories
          selectedItem.price = itemModifiers[i]?.item === COMPLEMENTARY_ITEM ? 0 : itemExtraPriceWhileAdding ({basePrice:itemModifiers[i]?.basePrice, selectedItemPrice:itemPrice, item:itemModifiers[i]?.item, itemName});
          state.display_price = toFixedNumber(state.display_price + itemExtraPrice(itemModifiers[i]?.basePrice, itemPrice))
        }
      }
      state.modifiers = itemModifiers;
      
    },
    editKidsComboItemSelection: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let selectedItem = null;
      let display_price = state.display_price;
      if(itemModifiers[3] && itemModifiers[3].price){
        display_price = state.display_price - itemModifiers[3].price
      }
      const { sectionSelected, item } = action.payload;
      state.id = action.payload?.id ?? null
      const itemPrice = item?.half_brink_item_price ? item?.half_brink_item_price : item?.price
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.is_selected = true;
          selectedItem.id = item?.modifier_id;
          selectedItem.item_id = item?.modifier_id;
          selectedItem.modifier_id = item?.modifier_id;
          selectedItem.modifier_group_id = item?.modifier_group_id;
          selectedItem.name = item?.modifier_name;
          selectedItem.modifier_name = item?.name;
          selectedItem.code = Codes.ADD
          selectedItem.calories = item?.calories
          selectedItem.price = price(item.group_base_price, itemPrice, state.original_price, display_price, sectionSelected === '1' ? itemModifiers[1] : itemModifiers[0]);
          state.display_price = toFixedNumber(state.display_price + itemExtraPrice(item.group_base_price, itemPrice))
        }
      }
      state.modifiers = itemModifiers;
      
    },
    try2ComboSwapItemSelection: (state, action) => {
      let itemModifiers = [...state.modifiers];
      let selectedItem = null;
      const { sectionSelected, item } = action.payload;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          selectedItem = itemModifiers[i];
          selectedItem.is_selected = true;
          selectedItem.id = item?.id;
          selectedItem.name = item?.name;
        }
      }
      state.modifiers = itemModifiers;
    },
    syncTry2IngredientSelection: (state, action) => {
      const { item_id, modifiers } = action.payload;
      const updatedItems = state.modifiers.map((mod) =>
        mod.modifier_id == item_id
          ? { ...mod, modifiers: [...modifiers] }
          : { ...mod }
      );
      return { ...state, modifiers: updatedItems };
    },
    try2IngredientSelection: (state, action) => {
      const { item_id } = action.payload;
      const selectedItem = state.modifiers.find((mod) => {
        return mod.modifier_id == item_id;
      });
      const selectedModifiers = ingredientSelectionHelper({
        selectedModifiers: [...selectedItem.modifiers],
        payload: action.payload,
      });
      const updatedItems = state.modifiers.map((mod) =>
        mod.modifier_id == item_id
          ? { ...mod, modifiers: selectedModifiers }
          : { ...mod }
      );
      return { ...state, modifiers: updatedItems };
    },
    try2SubstituteSelection: (state, action) => {
      const { item_id } = action.payload;

      const selectedItem = state.modifiers.find(
        (mod) => mod.modifier_id == item_id
      );
      const cloneItemCustomization = ingredientSubstitutionHelper({
        initialState: selectedItem,
        payload: action.payload,
      });
      const updatedItems = state.modifiers.map((mod) =>
        mod.modifier_id == item_id ? { ...cloneItemCustomization } : { ...mod }
      );
      return { ...state, modifiers: [...updatedItems] };
    },
    removeTry2ComboIngredients: (state, action) => {
      const { modifiers, sectionSelected, order } = action.payload;
      let itemModifiers = [...state.modifiers];
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          for (let j = 0; j < modifiers.length; j++) {
            if (modifiers[j].quantity) {
              const index = itemModifiers[i].modifiers.findIndex((item) => {
                return item.modifier_id === modifiers[j].modifier_id;
              });
              if (index === -1) {
                itemModifiers[i].modifiers.push(modifiers[j]);
              } else {
                itemModifiers[i].modifiers[index].quantity =
                  modifiers[j].oldQuantity;
              }
            } else {
              const index = itemModifiers[i].modifiers.findIndex((item) => {
                return item.modifier_id === modifiers[j].modifier_id;
              });
              if (index === -1) {
                itemModifiers[i].modifiers.push(modifiers[j]);
              } else {
                itemModifiers[i].modifiers.splice(index, 1);
              }
            }
          }
        }
      }
      state.modifiers = itemModifiers;
    },
    removeTry2ComboCustomizeItems: (state, action) => {
      const { modifiers, sectionSelected } = action.payload;
      let itemModifiers = [...state.modifiers];
      let selectedModifiers = null;
      for (let i = 0; i < itemModifiers.length; i++) {
        if (itemModifiers[i].item === sectionSelected) {
          for (let j = 0; j < modifiers.length; j++) {
            const index = itemModifiers[i].modifiers.findIndex(
              (item) => item.modifier_id === modifiers[j].modifier_id
            );
            itemModifiers[i].modifiers.splice(index, 1);
          }
          selectedModifiers = itemModifiers;
        }
      }
      state.modifiers = itemModifiers;
    },
    removeTry2ComboCategory: (state, action) => {
      const { id } = action.payload;
      const items = [...state.modifiers];
      let price = 0;
      for (let i = 0; i < items.length; i++) {
        if (items[i].item === id) {
          // for (let j = 0; j < items[i].modifiers.length; j++) {
          //  price+=items[i].modifiers[j].additionalPrice
          // }
          price = items[i].price;
          items[i].category_id = null;
          items[i].id = null;
          delete items[i]['is_selected'];
          items[i].modifiers = [];
          items[i].name = null;
          items[i].price = null;
        }
      }
      state.modifiers = items;
      state.display_price = toFixedNumber(state.display_price - price)
    },
    resetTry2ComboContext: (state) => {
      const items = [...state.modifiers];
      let price = 0;
      for (let i = 0; i < 2; i++) {
        price += items[i].price
        items[i].category_id = null;
        items[i].id = null;
        delete items[i]['is_selected'];
        items[i].modifiers = [];
        items[i].name = null;
        items[i].price = null;
      }
      state.modifiers = items;
      state.display_price = toFixedNumber(state.display_price - price);
    },
    resetTry2ComboItemContext: (state, action) => {
      const items:any = [...state.modifiers];
      const {category_id} = action.payload;
      for (let i = 0; i < items.length - 1; i++) {
        if(items[i].category_id === category_id){
          items[i].category_id = null;
          items[i].id = null;
          items[i].modifiers = [];
          items[i].name = null;
          items[i].price = null;
        }
      }
      state.modifiers = items;
    },
    swapTry2ComboContext: (state) => {
      const swapKeysValue = ['category_id', 'name'];
      const items = [...state.modifiers];
      const firstItem = { ...items[0] };
      for (let i = 0; i < swapKeysValue.length; i++) {
        items[0][swapKeysValue[i]] = items[1][swapKeysValue[i]];
        items[1][swapKeysValue[i]] = firstItem[swapKeysValue[i]];
      }
      state.modifiers = items;
    },
    removeCategory: (state, action) => {
      const { item_id } = action.payload;
      const selectedItemIndex = state.modifiers.findIndex(
        (mod) => mod.modifier_id == item_id
      );
      state.modifiers.splice(selectedItemIndex, 1);
      return { ...state };
    },
    resetMadedTryTwoComboItemData: (state) => {
      state.makeAnItemTry2Combo = {
        bucket: null,
        item: null,
        order: null
      }
    },
    resetContext: (state) => {
      state.size= null;
      state.modifiers= [];
      state.item_id= null;
      state.name= null;
      state.display_price= null;
      state.original_price= null;
      state.item_calories= null;
      state.reRender=false;
      state.makeAnItemTry2Combo={
        bucket:null,
        item:null,
        order:null
      }
      state.id = null
      state.required_modifiers = []
      state.other_modifiers = []
      state.complimentary_modifier = []
      state.core_modifiers = []
    },
    resetOrderInBucket: (state, action) => {
      const {itemNo} = action.payload;
      const itemIndex = state.modifiers.findIndex(currentItem => currentItem.item === itemNo)
      state.size= null;
      if(itemIndex !== -1){
        state.modifiers[itemIndex].modifiers = [];
      }
    },

    resetModifiers: (state, action) => {
      const {modifiers} = action.payload;
      state.modifiers = modifiers
      // const itemIndex = state.modifiers.findIndex(currentItem => currentItem.item === itemNo)
      // state.size= null;
      // if(itemIndex !== -1){
      //   state.modifiers[itemIndex].modifiers = [];
      // }
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  addItem,
  editItem,
  sizeSelection,
  ingredientSelection,
  substituteSelection,
  syncIngredientSelection,
  addTry2ComboItem,
  addKidsComboItem,
  kidsComboItemSelection,
  addTry2ComboSectionItem,
  syncTry2IngredientSelection,
  try2IngredientSelection,
  try2SubstituteSelection,
  try2ComboIngredientSelection,
  try2ComboItemSelection,
  try2ComboCategorySelection,
  removeTry2ComboIngredients,
  removeCategory,
  resetContext,
  try2ComboComplementaryItemsSelection,
  try2ComboCoreIngredientSelection,
  removeTry2ComboCustomizeItems,
  removeTry2ComboCategory,
  resetTry2ComboContext,
  resetTry2ComboItemContext,
  swapTry2ComboContext,
  updatePriceForItem,
  updateTry2ComboSelectedModifiers,
  removeTemporarilyAddedPriceForItem,
  makeAnItemToTry2Combo,
  makeAnItemTry2Combo,
  resetMadedTryTwoComboItemData,
  resetOrderInBucket,
  resetModifiers,
  updateModifiers,
  resetComboItemModifiersByItemId,
  updatePriceForAllItem,
  editTry2ComboItem,
  editTry2ComboItemSelection,
  editKidsComboItemSelection
} = itemCustomizationSlice.actions;

export const itemCustomizationReducer = itemCustomizationSlice.reducer;



/***before unknown save 19july 17:15 */
