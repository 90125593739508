import React, { MouseEvent } from "react";
import * as Constants from "../../../constants";
import {
  availableForSelectedOrderType,
  verifyStatus,
} from "../../../helpers/customizedItem/customizedItem.helper";
import { ICustomizedItem } from "../../../models/item.model";
import { useAppSelector } from "../../../redux/store/store";
import styleClasses from "../try2.module.scss";
import { Helmet } from "react-helmet";
import { getStructuredItem } from "../../../helpers/scripts/seoScript";
import { useStoreLocation } from "../../../context/StoreLocation";
import { isItCreateYourOwnItem } from "../../../helpers/helperMethods";
import { useAuthState } from "../../../context/UserAuthentication";

interface FavoriteButtonProps {
  buttonHandler: (e: MouseEvent<HTMLButtonElement>, item) => void;
  favIcon: boolean;
  className: string;
  item?: ICustomizedItem;
  favourite?: any;
  fromOrder?: boolean;
  isIconblueFill: boolean;
}
const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  favIcon,
  className,
  buttonHandler,
  item,
  favourite,
  fromOrder,
  isIconblueFill,
}) => {
  const isOrderTypeSelected = useAppSelector(
    (state) => state.cart.isOrderTypeSelected
  );
  const { authInfo } = useAuthState();
  const savedOrderType = useAppSelector((state) => state.cart.orderType);
  const { locationInfo } = useStoreLocation();
  const orderType = isOrderTypeSelected ? savedOrderType : "";

  const isItemActive = () => {
    // ? This is hardcoded right now because have no constant to distiguise CREATE YOUR OWN SALAD.
    if (
      isItCreateYourOwnItem(item?.name)
    ) {
      return false;
    }
    if (item.is_active && verifyStatus(item, "item_location")) {
      if (availableForSelectedOrderType(item, orderType)) {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <Helmet>
        <script>
          {`
                          document.getElementById("favorite_button_${
                            item?.id
                          }").addEventListener("click", function () {                      
                          gtag("event", "add_to_wishlist", {
                            user_id: ${authInfo?.userId},
                            currency: "USD",
                            value: ${item?.price},
                            items: [
                                ${JSON.stringify(
                                  getStructuredItem(
                                    item as any,
                                    locationInfo?.selectedStore?.id
                                  )
                                )}
                            ]
                          });
                        });            
                     `}
        </script>
      </Helmet>
      <button
        type="button"
        id={`favorite_button_${item?.id}`}
        className={`
        ${styleClasses.favourite_icon_wrap} btn btn-link favourite-icon-wrap ${className}
      `}
        disabled={fromOrder ? false : !isItemActive()}
        onClick={(e: MouseEvent<HTMLButtonElement>) => buttonHandler(e, item)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="28"
          viewBox="0 0 31 28"
          fill="none"
        >
          <path
          style={{
            ...(favourite && {
              fill: isIconblueFill ? "#0956A8" : "#aa182c",
              stroke: isIconblueFill ? "#0956A8" : "#aa182c",
            }),
          }}          
            d="M15.3643 26.2812C15.3643 26.2812 1 18.2372 1 8.46947C1.00029 6.74307 1.59849 5.07005 2.6929 3.73486C3.78731 2.39967 5.31037 1.48473 7.00313 1.14558C8.69589 0.806437 10.4539 1.06403 11.9782 1.87456C13.5025 2.68509 14.699 3.99853 15.3644 5.59157L15.3643 5.59158C16.0297 3.99854 17.2262 2.6851 18.7505 1.87457C20.2748 1.06403 22.0328 0.806438 23.7255 1.14558C25.4183 1.48472 26.9414 2.39967 28.0358 3.73486C29.1302 5.07005 29.7284 6.74307 29.7287 8.46947C29.7287 18.2372 15.3643 26.2812 15.3643 26.2812Z"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};
export default FavoriteButton;
