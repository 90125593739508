import React, { useEffect, useState } from "react";
import { CustomModal } from "../../Modal/CustomModal";
import { Toast_Func } from "../../../helpers/toast.helper";
import StoredGiftCardsListing from "./StoredGiftCardsListing";
import { useQueryClient } from "react-query";
import GIftCardForm from "../../Forms/GIftCardForm";
import useUpdateGiftCard from "../../../react-query-hooks/useUpdateGiftCard";
import {IGiftCard} from "../../../models/giftCard.model";

interface StoredPaymentsProps {giftCardRecords: IGiftCard[]}

const StoredGiftCards: React.FunctionComponent<StoredPaymentsProps> = ({giftCardRecords}) => {

  const { mutateAsync: updateGiftCard } = useUpdateGiftCard();
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [editRecord, setEditRecord] = useState<IGiftCard>(null);
  let queryClient = useQueryClient();
  const [defaultPayment, setDefaultPayment] = useState<IGiftCard>(null);
  const handleEdit = (record: IGiftCard) => {
    setEditRecord(record);
    setOpenPaymentModal(true);
  };
  const closeModal = () => {
    setOpenPaymentModal(false);
  };
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
useEffect(()=>{
  if(!defaultPayment){
    setDefaultPayment(giftCardRecords.find(rec => rec.is_default))
  }
},[giftCardRecords])
  useEffect(() => {
    !openPaymentModal && editRecord && setEditRecord(null);
  }, [openPaymentModal]);
  const handleChangeDefault = async (id: string) => {
    const changedRecord = giftCardRecords.find((rec) => rec.id === id);
    setDefaultPayment(changedRecord);
    return updateGiftCard(
      {
        data: { is_default : true },
        recordID: id,
      },
      {
        onSuccess: async(data) => {
          setDefaultPayment({...changedRecord,is_default:true})
          queryClient.invalidateQueries(["giftCards"]);
          queryClient.invalidateQueries(["profile"]);
          setCloseConfirmationModal(true)
          Toast_Func({ status: true, message: "Default updated successfully" });
        },
        onError: (error) => {
          Toast_Func({ status: false, message: error });
          setDefaultPayment(giftCardRecords.find(rec => rec.is_default))
        },
      }
    );
  };
  return (
    <>
      <StoredGiftCardsListing
        closeConfirmationModal={closeConfirmationModal}
        giftCardRecords={giftCardRecords}
        defaultPayment={defaultPayment}
        handleChangeDefault={handleChangeDefault}
        handleEdit={handleEdit}
        setOpenPaymentModal={setOpenPaymentModal}
      />
      <CustomModal
        showModal={openPaymentModal}
        closeModal={() => setOpenPaymentModal(false)}
        title={`Gift Card`}
      >
          <GIftCardForm isCheckout={false} handleCloseGiftCard={() => setOpenPaymentModal(false)}
                        editGiftCard={editRecord}
          />
      </CustomModal>
    </>
  );
};

export default StoredGiftCards;
