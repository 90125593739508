import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { REACT_APP_CLOUD_FRONT } from "../../../constants";
import {
  cartItem,
  IOfferItem,
  IOfferItemDetails,
} from "../../../models/cart.model";
import styleClasses from "../../Header/Header.module.scss";
import { useAppSelector } from "../../../redux/store/store";
import useRedeemOffer from "../../../hooks/useRedeemOffer";
import { Toast_Func } from "../../../helpers/toast.helper";
import OfferEligibilityModal from "./OfferEligibilityModal";

interface IOfferItemCardProps {
  offerItem: IOfferItem;
  selectedOfferItem: IOfferItem;
  handleRedeemClick: () => void;
  btnText: string;
  rewardsDiscountedSubTotal: number;
}

const OfferItemCard: React.FC<IOfferItemCardProps> = ({
  offerItem,
  selectedOfferItem,
  btnText,
  rewardsDiscountedSubTotal,
}) => {
  const {
    name,
    image_url,
    in_cart,
    redirect_item_id,
  } = offerItem;
  const [offerEligibilityModal, setOfferEligibilityModal] = useState<{
    show: boolean;
    failedOfferCriteria: string;
    redeemedItemNames: string[];
  }>({
    show: false,
    failedOfferCriteria: "",
    redeemedItemNames: [],
  });
  const cartItems = useAppSelector((state) => state.cart.items);
  const { redeemOffer } = useRedeemOffer();
  const redeemOfferItem = () => {
    const { shouldShowOfferEligibilityModal, failedOfferCriteria, redeemedItemNames } =
      redeemOffer({
        offerItem,
        cartItems,
        selectedOfferItem,
        rewardsDiscountedSubTotal,
        isOfferClicked: true,
      });
    if (shouldShowOfferEligibilityModal) {
      setOfferEligibilityModal({show: true, failedOfferCriteria, redeemedItemNames});
    }
  };

  return (
    <>
      <OfferEligibilityModal 
        show={offerEligibilityModal.show}
        offerFailedCriteria={offerEligibilityModal.failedOfferCriteria}
        redeemedItemNames={offerEligibilityModal.redeemedItemNames}
        offerDetails={offerItem.data}
        closeModal={() => setOfferEligibilityModal({show: false, failedOfferCriteria: "", redeemedItemNames: []})}
      />
      <Col sm="4" xs="4" className={styleClasses.suggestion_column}>
        <div
          className={`${styleClasses.other_products_item} text-center ${
            btnText === "Redeemed" && styleClasses.active
          }`}
        >
           <div className={styleClasses.cart_img_wrap + ' mb-3'}>
            <img
              src={
                image_url
                  ? image_url
                  : `${REACT_APP_CLOUD_FRONT}/Items/${redirect_item_id}/item-${redirect_item_id}.webp`
              }
              className="img-fluid"
              alt={name}
            />
          </div>
          <h5
            className={`f-s14 f-sm-s11 lh-18 font-rale f-w5`}
          >
            {name}
          </h5>
          <button
            type="button"
            className={
              styleClasses.pro_item_btn +
              ` btn-large item-select-btn btn h-auto f-s12 mb-2 font-rale text-capitalize mw-auto w-100 py-1 px-2 lh-lg mx-md-2 ${
                !in_cart && "outline"
              }`
            }
            onClick={() => {
              redeemOfferItem();
            }}
          >
            {btnText}
          </button>
        </div>
      </Col>
    </>
  );
};

export default OfferItemCard;
