import React, { useEffect, useState } from "react";
import { updateOrderDetails } from "../../redux/slices/cartSlice";
import OrderType from "../ItemGrid/OrderType";
import {useAppDispatch, useAppSelector} from "../../redux/store/store";
import useProfile from "../../react-query-hooks/useProfile";
import { useAuthState } from "../../context/UserAuthentication";
import Spinner from "../Spinner/Spinner";
import { useQueryClient } from "react-query";
import { Toast_Func } from "../../helpers/toast.helper";
import { API_ERROR } from "../../models/error.model";
import Button from "../Buttons/Button";
interface IAddressConfirmationForUser {
  closeModal: () => void;
  addAddress: any;
  isAddingAddress: boolean;
}

const AddressConfirmationForUser: React.FC<IAddressConfirmationForUser> = ({closeModal, addAddress, isAddingAddress}) => {
    const dispatch = useAppDispatch()
    const queryClient = useQueryClient();
    const [changeAddress, setChangeAddress] = useState<boolean>(false);
    const { authInfo } = useAuthState();
    
    const {
      isFetching: isFetchingUserProfileData,
      data: userProfileData,
    } = useProfile(authInfo.userId, authInfo.type);

    const deliveryAddress = useAppSelector((state) => state.cart?.orderDetails?.delivery_address || "")


    useEffect(() => {
      if (
        !isFetchingUserProfileData &&
        !changeAddress &&
        userProfileData?.addresses?.length === 5
      ) {
        setChangeAddress(true);
        dispatch(updateOrderDetails({"delivery_address" : ""}))
      }
    }, [isFetchingUserProfileData]);
    

    const saveAddress = () => {
      let { address_name, street_name, city, zipcode, state } = deliveryAddress;
      address_name = address_name?.trim()
      let modifiedValues = {
        ...deliveryAddress,
        customer_id: authInfo.userId,
        full_address: `${street_name} ${city} ${zipcode} ${state}`,
        is_default: 0,
        ...(address_name ? { address_name } : { address_name: street_name }),
      };

      return addAddress(
        {
          deliveryAddress: modifiedValues,
          authentication_token: authInfo.authentication_token,
        },
        {
          onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries("profile");
            const newAddress = {
              value: data.address_name,
              label: data.address_name,
              id: data.id,
              isDefault: data.is_default,
              street: data.street_name,
              ...data,
            };
            dispatch(updateOrderDetails({"delivery_address" : newAddress}))
            Toast_Func({
              status: true,
              message: "Address successfully added",
            });
            closeModal();
          },
          onError: (error, variables, context) => {
            const {
              data: {
              data: { errors },
              },
          } = (error as API_ERROR).response;
            closeModal();
          Toast_Func({ status: false, message: errors[0] });
          },
        }
      );
    }

    if (isFetchingUserProfileData)
      return <Spinner modalSpinner={true} className={""} />;
    
    return (
      <>
        {!changeAddress ? (
          <>
             <h4 className="f-s22 font-Cls text-center fw-normal lh-22 mb-0 text-capitalize">Confirm delivery address</h4>
          <div className="w-50 mx-auto">
          <div className="justify-content-center mb-4 mt-2">
              <span className="d-block f-s14 font-Visby-cf f-w5">
                {deliveryAddress.address_name}
              </span>
              <span className="d-block f-s14 font-Visby-cf f-w5">
                {deliveryAddress.street} {deliveryAddress?.apartment_no ? `, ${deliveryAddress?.apartment_no} ` : ''}
              </span>
              <span className="d-block f-s14 font-Visby-cf f-w5">
                {deliveryAddress.city}, {deliveryAddress.state}{" "}
                {deliveryAddress.zipcode}
              </span>
            </div>
          </div>
           
            <div className="justify-content-center app_container_wrap px-1 py-2 text-center">
              <div className="text-center">
                <Button
                  type="button"
                  className="btn btn-large mb-sm-4 mb-3 d-block mx-auto"
                  onClick={() => saveAddress()}
                  disabled={isAddingAddress}
                  loading={isAddingAddress}
                >
                  Save Address and Proceed
                </Button>
                <button
                  type="button"
                  className="btn btn-custom p-0 f-w6 p-16 color-sky-blue-light text-center w-100 mb-5 font-Vcf"
                  onClick={() => {
                    setChangeAddress(true)
                    dispatch(updateOrderDetails({"delivery_address" : ""}))
                  }}
                  disabled={isAddingAddress}
                >
                  Change Address
                </button>
              </div>
            </div>
          </>
        ) : (
          <OrderType handleOrderTypeModal={closeModal}/>
        )}
      </>
    );
};

export default AddressConfirmationForUser;
