import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { GUEST_USER, REACT_APP_CLOUD_FRONT, ROUTE_MENU, ROUTE_PROFILE_DETAILS, VIEW_MORE_RECENT_ORDER } from "../../constants";
import { useAuthState } from "../../context/UserAuthentication";
import { ArrowRightIcon, NotesIcon } from "../../images/svgIcons/svg";
import useGetMenuCategories from "../../react-query-hooks/useGetCategories";
import styles from "./MegaMenuDropDown.module.scss";

interface MegaMenuProps {
    HandleHideMegaMenu: () => void
    megaMenuAnimateClass: string;
}
const MegaMenuDropDown: React.FC<MegaMenuProps> = ({ HandleHideMegaMenu, megaMenuAnimateClass }) => {
    const [isDeviceIos, setIsDeviceIos] = React.useState(false);
    const { authInfo } = useAuthState();
    const { data: menus = [] } = useGetMenuCategories();
    const history = useHistory();
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor;
        if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
            setIsDeviceIos(true);
        } else {
            setIsDeviceIos(false);
        }
    }, []);

    const handleMenuRoute = (item) => {
        history.push(`${ROUTE_MENU}/${item.name.replace(/\s/g, "%20")}`);
        HandleHideMegaMenu()
    }
    return (
        <>
            <div className={`${styles.megaMenuWrapper}  ${megaMenuAnimateClass}`}>
                <div className={styles.innerDiv}>
                    <Row>
                        <Col md={authInfo.type !== GUEST_USER ? 9: 12} sm={6} className="px-4">
                            <Row>
                                {menus.map((menu) => {
                                    return (
                                        <Col md={4} key={menu.id}>
                                            <div className={styles.menuCategory}
                                                onClick={() => {
                                                    handleMenuRoute(menu)
                                                }}>
                                                <img
                                                    src={
                                                        isDeviceIos
                                                            ? `${REACT_APP_CLOUD_FRONT}/images/Menu/${menu.name
                                                                .replace(/'/g, "")
                                                                .toLowerCase()}.png`
                                                            : `${REACT_APP_CLOUD_FRONT}/images/Menu/${menu.name
                                                                .replace(/'/g, "")
                                                                .toLowerCase()}.png`
                                                    }
                                                    className="img-fluid"
                                                    alt={menu.name}
                                                />
                                                <div className="w-100 ms-2">
                                                    {" "}
                                                    <h4> {menu.name}</h4>
                                                </div>

                                                <div className={styles.iconDiv}>
                                                    <ArrowRightIcon />
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>
                         {authInfo.type !== GUEST_USER  && 
                          <Col md={3} className="border-start px-4">
                          <div className={styles.menuCategory} onClick={(event: any) => {
                              event.preventDefault();
                              history.push(VIEW_MORE_RECENT_ORDER)
                              HandleHideMegaMenu();
                          }}>
                              <div className={styles.notesIcon}>  <NotesIcon /></div>

                              <div className="w-100 ms-2">
                                  {" "}
                                  <h4>Recent Orders</h4>
                              </div>

                              <div className={styles.iconDiv}>
                                  <ArrowRightIcon />
                              </div>
                          </div>
                          {/* <div className={styles.menuCategory}
                          onClick={(event: any) => {
                            event.preventDefault();
                            history.push(ROUTE_PROFILE_DETAILS)
                            HandleHideMegaMenu();
                        }}>
                              <div className={styles.notesIcon}>  <NotesIcon /></div>
                              <div className="w-100 ms-2">
                                  {" "}
                                  <h4>Favorite Order</h4>
                              </div>

                              <div className={styles.iconDiv}>
                                  <ArrowRightIcon />
                              </div>
                          </div> */}
                      </Col>
                         }
                       
                        <div className={`d-flex justify-content-center mt-3 w-100 ${styles.btnDiv}`}>
                            <button className="btn btn-large" onClick={() => {
                                history.push(ROUTE_MENU);
                                HandleHideMegaMenu()
                            }}>View full menu </button>
                        </div>
                    </Row>

                </div>

            </div>

        </>

    );
};

export default MegaMenuDropDown;
