import React, { useState, useEffect } from "react";
import styleClasses from "./NewFooter.module.scss";
import Image from "../Image/Image";
import { Container, Row, Col, Navbar, NavLink } from "react-bootstrap";
import NavbarComponent from "../NavbarComponent/NavbarComponent";
import * as CONSTANT from "../../constants";
import useKeypress from "../../hooks/useKeypress";
import PopUpModel from "../../components/Footer/PopUpModel";
import { NavlinkModel } from "../../models/navlink.model";
import { Link } from "react-router-dom";
import GlutenSensitiveModal from "./GlutenSensitiveModal";
import { FacebookIcon, InstagramIcon, LinkedInIcon, TiktokIcon } from "../../images/svgIcons/svg";

interface footerProps {
  bgColor?: string;
  textColor?: string;
  mt?: boolean;
  footer_img_controller?: string;
  sectionController?: string;
  hidemurals?: string;
  stickybtn?: string
}
interface UserFormData {
  first_name: string;
  last_name: string;
  email: string;
  location: string;
}

const NewFooter: React.FunctionComponent<footerProps> = ({
  bgColor,
  textColor,
  footer_img_controller,
  sectionController,
  hidemurals,
  stickybtn
}) => {
  useKeypress("Escape", () => {
    setShow(false);
  });

  const [footer_Nav] = useState<boolean>(true);
  const [isDeviceIos, setIsDeviceIos] = React.useState(false);
  const [show, setShow] = useState(false);
  const [openGlutenModal, setOpenGlutenModal] = useState(false);


  const navLinksArray: NavlinkModel[] = [
    // { link: "#popup", text: 'EMAIL SIGN UP' },
    { link: "/talk", text: "CONTACT US", alternateURL: { exist: false, link: null } },
    { link: CONSTANT.ROUTE_GOOD_LIFE_LANE, text: "GOOD LIFE LANE", alternateURL: { exist: false, link: null } },
    { link: CONSTANT.ROUTE_NUTRITION_MENU , text: "NUTRITIONAL INFO", alternateURL: { exist: false, link: null } },
    { link: CONSTANT.CATERING_PRINT_MENU, text: "CATERING PRINT MENU", alternateURL: { exist: false, link: null } },

  ];

  const FooterBottomLinks: NavlinkModel[] = [
    { link: "/privacyPolicy", text: "PRIVACY POLICY", alternateURL: { exist: false, link: null } },
    { link: "/terms", text: "TERMS & CONDITIONS", alternateURL: { exist: false, link: null } },
    { link: `${CONSTANT.TRANSPARENCY_IN_COVERAGE}`, text: "gluten sensitive*", alternateURL: { exist: false, link: null } },
  ]

  const socialIconsArray = [
    {
      link: `${CONSTANT.CAFEZUPAS_FACEBOOK_URL}cafezupas/`,
      icon: <FacebookIcon/>,
      socail_alt_text: `facebook`,
    },
    {
      link: `${CONSTANT.CAFEZUPAS_INSTAGRAM_URL}cafezupas/`,
      icon: <InstagramIcon/>,
      socail_alt_text: `instargram`,
    },
    {
      link: CONSTANT.CAFEZUPAS_TIKTOK_URL,
      icon: <TiktokIcon/>,
      socail_alt_text: `tiktok`,
    },
    {
      link: CONSTANT.CAFEZUPAS_LINKEDIN_URL,
      icon: <LinkedInIcon/>,
      socail_alt_text: `linkedIn`,
    },
  ];
  const [moboImages, setmoboImages] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleClick = () => setShow(true);

  useEffect(() => {
    let isMounted = true;
    var userAgent = navigator.userAgent || navigator.vendor;
    var x = window.matchMedia("(min-width: 768px)");
    if (x.matches) {
      setmoboImages(true);
    } else {
      setmoboImages(false);
    }
    if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
      setIsDeviceIos(true);
    } else {
      setIsDeviceIos(false);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className={sectionController}>
      {/* <div
        className={`${styleClasses.footer_img_wrap} ${footer_img_controller} ${hidemurals}`}
      >
        <Image
          className="w-100"
          source={
            moboImages
              ? isDeviceIos
                ? `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Footer/Footer.jpeg`
                : `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Footer/Footer.webp`
              : isDeviceIos
                ? `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Footer/footer-art-mobo.jpeg`
                : `${CONSTANT.REACT_APP_CLOUD_FRONT}/images/Footer/footer-art-mobo.webp`
          }
          alt="footer art"
        />
      </div> */}

      <div className={`${styleClasses.footer_div} ${bgColor} ${stickybtn}`}>
        <Container fluid className="px-4 px-lg-5">
          <Row className="align-items-start">
            <Col
              lg={4}
              className={`${styleClasses.footer_newsletter} clr-sec-white text-start  d-none d-lg-block`}
            >
              <h5 className="f-s24">ALWAYS KNOW WHAT’S NEW!</h5>
              <p className="font-Trade-regular f-s20 f-w4 clr-sec-white mb-3 mb-lg-5">
                Nourish the Good Life with exclusive Café Zupas offers, updates
                and seasonal menus sent straight to your inbox.
              </p>
              <button
                type="button"
                onClick={handleClick}
                className="btn btn-large btn-white"
              >
                JOIN OUR EMAIL LIST
              </button>
            </Col>
            <Col
              lg={8}
              className={`${styleClasses.footer_menu_links_wrap} `}
            >
              <Row className="">
                <Col
                  lg={6} className="order-2 order-lg-1">
                  <div className={`${styleClasses.nav_menu_items_wrapper} ps-lg-5`}>
                    {navLinksArray.map((item) => {
                      return (
                        <li className="text-start mb-3">
                          {item.link === CONSTANT.ROUTE_NUTRITION_MENU || item.link === CONSTANT.CATERING_PRINT_MENU ?
                            <a href={item.link} className="f-s24 f-sm-s18 font-Visby-cf f-w7 mb-0 clr-white-clr text-decoration-none text-start"
                              target={'_blank'}>{item.text}</a> :

                            <Link to={item.link} className="f-s24 f-sm-s18 font-Visby-cf f-w7 mb-0 clr-white-clr text-decoration-none text-start ">{item.text} </Link>
                          }

                        </li>
                      )
                    })}
                  </div></Col>
                <Col lg={6} className="order-lg-2 order-1 ">
                  <div
                    className={`${styleClasses.social_icon_container} ps-lg-5`}
                  >
                    <div className="d-flex flex-column align-items-start ">
                      <h6
                        className={` f-s24 font-Visby-cf f-w7 mb-0 clr-white-clr me-2`}
                      >
                        Stay Connected:
                      </h6>
                      <Navbar
                        expand="md"
                        className="footer_Nav_links_wrap w-auto align-items-center mt-1"
                      >
                        {socialIconsArray.map((item, j) => (
                          <NavLink href={item.link} key={j} className="p-0" target={'_blank'}>
                            {item.icon}
                          </NavLink>
                        ))}
                      </Navbar>
                    </div>
                    <div>
                      <h6
                        className={` f-s24 font-Visby-cf f-w7 mb-0 clr-white-clr me-2 mt-1`}
                      >
                        download the app:
                      </h6>
                      <div className='d-flex align-items-center  gap-2 justify-content-start mt-3'>
                        <a
                          href={'https://apps.apple.com/pk/app/caf%C3%A9-zupas-loyalty/id6448428217'}
                          target={'_blank'}>
                          <img src={require('../../images/appStore.png')} width={150} />
                        </a>
                        <a href={'https://play.google.com/store/apps/details?id=com.cafezupasres.co&hl=en'} target={'_blank'}>
                          <img src={require('../../images/playStore.png')} width={150} />
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


            </Col>
          </Row>
          <div className={styleClasses.FooterBottomLinks}>
            <ul className="ps-0">
              {FooterBottomLinks.map((item, i) => {
                return (
                  <li>
                    {item.link === CONSTANT.TRANSPARENCY_IN_COVERAGE ?
                      <a onClick={() => { setOpenGlutenModal(true) }} className="f-s16 font-rale text-uppercase f-w7"> gluten sensitive*</a>
                      : <Link to={item.link} className="f-s16 f-sm-s14 font-rale text-uppercase f-w7">{item.text} </Link>}

                  </li>
                )

              })}

            </ul>
          </div>
        </Container>
      </div>

      <Row>
        <Col xs="12" sm="12" md="6">
          <PopUpModel showPopup={show} closePopup={handleClose} />
        </Col>
        <GlutenSensitiveModal openGlutenModal={openGlutenModal} setOpenGlutenModal={setOpenGlutenModal} />
      </Row>
    </div>
  );
};

export default NewFooter;
