import { GUEST_USER } from "../../constants";
import { getUser } from "../../helpers/helperMethods";

const user = getUser()

const userId = user.id ?? ""
const authentication_token = user.authentication_token ?? ""
const access_token = user.access_token ?? ""
const visitor_id = user.visitor_id ?? ""
const type = user.type ?? ""

export const initialState = {
  userId: "" || userId,
  visitor_id: "" || visitor_id,
  authentication_token: "" || authentication_token,
  access_token: "" || access_token,
  loading: false,
  errorMessage: null,
  type: "" || type
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        userId: action.payload.id,
        authentication_token: action.payload.authentication_token,
        access_token: action.payload.access_token,
        loading: false,
        type: action.payload.type,
        visitor_id: action.payload.visitor_id ?? initialState.visitor_id,
        loyalty_user_id: action?.payload?.loyalty_user_id || ""
      };
    case "LOGOUT":
      return {
        ...initialState,
        userId: "",
        authentication_token: "",
        access_token: "",
        type: GUEST_USER,
        loyalty_user_id: ""
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        // errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
