import { GET_ALL_STATES, LOCATION_MENU_SERVICE } from "../constants";
import { ILocationMenuQueryParams, IMarketListingsQueryParams, INearestLocationQueryParams } from "../models/location.model";
import { HttpService } from "./base.service";

class LocationMenuService extends HttpService {
  baseURL = LOCATION_MENU_SERVICE

  getMarketListings = (data: IMarketListingsQueryParams): Promise<any> => this.get(`markets/listing`,data);
  getNearbyLocations = (data: INearestLocationQueryParams): Promise<any> => this.get('locations/nearest/list', data);
  getLocationMenu = (data: ILocationMenuQueryParams): Promise<any> => this.get('getLocationMenu', data);
  getLocationMenuItem = (data: ILocationMenuQueryParams): Promise<any> => this.get('getLocationMenuItem', data);
  getUpsellItems = (data: any): Promise<any> => this.get(`upsell/fetch-items?business_date=${data?.business_date}
  &hour=12&location_id=${data?.location_id}${data?.items}&customer_id=${data?.customer_id}&cart_id=${data?.cart_id}&destination_id=${data?.destination_id}`,);
  getAllStates = (): Promise<any> => this.get(GET_ALL_STATES);
  getRewardCatalog = (customer_id, data): Promise<any> => this.get(`loyalty/rewards`);
  getAllLocations = (): Promise<any> => this.get(`getAllLocations`);
  getGoodLifeLaneLocations = (data): Promise<any> => this.get(`getAllLocations?service_id=${data.service_id}`); 
  getTags = (): Promise<any> => this.get(`getTags`);
  getTimeZoneTime = (timeZone: string): Promise<any> => this.get(`getCurrentDateTime?timezone=${timeZone}`)
  getMenuCategories = (): Promise<any> => this.get(`categories`)
  getLocationDetails = (id: number): Promise<any> => this.get(`getLocation/${id}`);
  getFundRaiser = (data: any): Promise<any> => this.get(`get-fund-raiser?location_id=${data.location_id}&date=${data.business_date}`);
}

export const locationMenuService = new LocationMenuService();