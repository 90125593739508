import { BOWLS, DRINKS, FULL_SIZE, HALF_SIZE, LARGE_SIZE, NO_TIME_SLOTS_PRESENT, REGULAR_SIZE, SALADS } from "../constants";

export const capitalizeFirstLetter=(value)=> {
    return value.charAt(0)?.toUpperCase() + value.slice(1);
  }
export const isSizeEqual=(item,reward)=>{
  return (item.item_size?.toLowerCase()==reward.redirect_item_size?.toLowerCase())
}
export const isCartEmpty=(Cart)=> Cart.cart.itemTree && Object.keys(Cart.cart.itemTree).length == 0;
export const getExpiry_year = (values) => {
  if (Number(values.card.expiration.split("/")[1]) < 100) {
    return Number(new Date().getFullYear().toString().substring(0, 2) + values.card.expiration.split("/")[1]);
  } else {
    return values.card.expiration.split("/")[1];
  }
};
export const formatDate = (date) => {
  const currentDate = new Date();
  let dateSplit = date ? date.split('-') : ['2023', '01', '01']
  let selectedDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
  let updateDateFormat = "";
  if (
    currentDate.getDate() == selectedDate.getDate() &&
    currentDate.getDay() == selectedDate.getDay()
  ) {
    updateDateFormat =
    "Today, " +
    selectedDate.toLocaleDateString("en-US", {
      weekday: "long",
   // year: "numeric",
    month: "short",
    day: "numeric",
    });
} else {
  updateDateFormat = selectedDate.toLocaleDateString("en-US", {
    weekday: "long",
    // year: "numeric",
     month: "short",
     day: "numeric",
  });
  }
  return updateDateFormat;
};
export const formatTime = (time: string , orderDetails) => {
  if (orderDetails?.time == '' || orderDetails?.time == NO_TIME_SLOTS_PRESENT){
    return NO_TIME_SLOTS_PRESENT;
  }
  if (!orderDetails?.time?.includes("ASAP")) {
    const hoursMinsSec = orderDetails?.time?.split(":");
    time = formatAMPM(hoursMinsSec[0], hoursMinsSec[1]);
  } else {
    time = orderDetails?.time;
  }
  return time;
};
export const formatAMPM = (hours, mins) => {
        const ampm = hours >= 12 ? "pm" : "am";
        hours %= 12;
        hours = hours || 12;
        mins = mins < 10 ? `${mins}` : mins;
        hours = hours < 10 ? `0${hours}` : hours;
        const formatedTime = `${hours}:${mins} ${ampm}`;
    
        return formatedTime;
};

export const scrollOnSize = ( divRef, offset ) => {
  setTimeout(() => {
    const yOffset = offset 
    const ScrollYCordinates = divRef?.current?.getBoundingClientRect().top + window.pageYOffset+ yOffset;
    window.scrollTo({ top: ScrollYCordinates, behavior: "smooth" });
}, 250);
}
export const getSizeName = ( categoryName:string ) => {
  if( categoryName?.toLowerCase() === BOWLS || categoryName?.toLowerCase() === SALADS || categoryName?.toLowerCase() === DRINKS ) return LARGE_SIZE
  else return FULL_SIZE
}

export const getSizeNameInCaseOfHalf = ( categoryName:string, size:string ) => {
  if( categoryName?.toLowerCase() === DRINKS) return REGULAR_SIZE.upper_case
  else return size
}

export const itemSizeForMobile = (sizeTextFor: 'HALF' | 'FULL', categoryName:string) => {
  switch (sizeTextFor) {
    case HALF_SIZE.upper_case:
      return categoryName?.toLowerCase() === DRINKS ? REGULAR_SIZE.title_case : HALF_SIZE.title_case
    case FULL_SIZE.upper_case:
      return getSizeName(categoryName).title_case
    default:
      break;
  }
}

export const itemSizeForDesktop = (sizeTextFor: 'HALF' | 'FULL', categoryName:string) => {
  switch (sizeTextFor) {
    case HALF_SIZE.upper_case:
      return categoryName?.toLowerCase() === DRINKS ? REGULAR_SIZE.upper_case : HALF_SIZE.upper_case
    case FULL_SIZE.upper_case:
      return getSizeName(categoryName).upper_case
    default:
      break;
  }
}

export const isZeroOnStart = (value) => {
  const valueArray = value && value?.split('');
  return valueArray?.length > 0 && valueArray[0] <= 0
}

export const delayer = (func, time = 50) => {
  let isRun = false;
  return () => {
    if (isRun === false) {
      func();
      isRun = true;
      setTimeout(() => {
        isRun = false;
      }, time);
    }
  };
};
export const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};